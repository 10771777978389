import React, {useCallback} from 'react';
import {ActivityIndicator} from '../activity-indicator';
import {AnimatedBox, Box} from '../box';
import {Pressable, PressableProps} from '../pressable';
import {theme} from '../theme/theme';
import {AnimatedTypography} from '../typography';
import {ButtonStyleType, ButtonStyleDescription} from './types';
import {useButtonAnimation} from './use-button-animation';
import {InputState, useInteractionState} from './use-interaction-state';

export const DISABLED_BUTTON_OPACITY = 0.5;

const buttonStyles: {[key in ButtonStyleType]: ButtonStyleDescription} = {
  primary: {
    backgroundColor: 'brandPrimary',
    textColor: 'textInvert',
    activityIndicator: 'outlinesInvert',
  },
  secondary: {
    backgroundColor: 'surfaceDark',
    textColor: 'textDefault',
    activityIndicator: 'outlines',
  },
  tertiary: {
    backgroundColor: 'transparent',
    textColor: 'textLow',
    activityIndicator: 'outlines',
  },
};

const BUTTON_SIZE_STYLE = {
  L: {padding: '18px 32px 18px 32px'},
  M: {padding: '12px 24px 12px 24px'},
  S: {padding: '8px 12px 8px 12px'},
};

export interface ButtonProps extends PressableProps {
  type: ButtonStyleType;
  onPress?: () => any;
  disabled?: boolean;
  loading?: boolean;
  children?: string;
  leftAccessory?: React.ReactElement;
  rightAccessory?: React.ReactElement;
  hasBorder?: boolean;
  loaderColor?: 'brandPrimary' | 'outlines' | 'outlinesInvert';
  size?: 'L' | 'M' | 'S';
  buttonStyle?: any;
  hasButtonStyle?: any;
}

export const Button = React.memo(
  ({
    children,
    leftAccessory,
    rightAccessory,
    type,
    accessibilityRole = 'button',
    accessibilityValue = {text: children},
    onHoverIn,
    onHoverOut,
    onPressIn,
    onPressOut,
    hasBorder,
    loading,
    loaderColor,
    size = 'M',
    buttonStyle = {},
    hasButtonStyle,
    ...rest
  }: ButtonProps) => {
    const {backgroundColor, textColor, activityIndicator} = buttonStyles[type];

    const {state, events} = useInteractionState();

    const {animatedTypographyStyle, animatedAccessoryStyle} =
      useButtonAnimation(state);

    const handleHoverIn: NonNullable<PressableProps['onHoverIn']> = useCallback(
      (event) => {
        if (loading) return null;
        onHoverIn?.(event);
        return events.onHoverIn();
      },
      [loading, onHoverIn, events],
    );

    const handleHoverOut: NonNullable<PressableProps['onHoverOut']> =
      useCallback(
        (event) => {
          if (loading) return null;
          onHoverOut?.(event);
          return events.onHoverOut();
        },
        [onHoverOut, events, loading],
      );

    const handlePressIn: NonNullable<PressableProps['onPressIn']> = useCallback(
      (event) => {
        if (loading) return null;
        onPressIn?.(event);
        return events.onPressIn();
      },
      [onPressIn, events, loading],
    );

    const handlePressOut: NonNullable<PressableProps['onPressOut']> =
      useCallback(
        (event) => {
          if (loading) return null;
          onPressOut?.(event);
          return events.onPressOut();
        },
        [onPressOut, events, loading],
      );

    return children ? (
      <Pressable
        {...rest}
        {...events}
        hovered={state === InputState.Hovering}
        pressed={state === InputState.Pressed}
        accessibilityRole={accessibilityRole}
        accessibilityValue={accessibilityValue}
        accessibilityState={{
          disabled: !!rest.disabled,
          busy: loading,
          checked: false,
          expanded: false,
          selected: state === InputState.Pressed,
        }}
        onHoverIn={handleHoverIn}
        onHoverOut={handleHoverOut}
        onPressIn={handlePressIn}
        onPressOut={handlePressOut}
      >
        <Box
          backgroundColor={backgroundColor}
          borderWidth={hasBorder ? 'one' : undefined}
          borderStyle={hasBorder ? 'solid' : undefined}
          borderColor={hasBorder ? 'outlines' : undefined}
          opacity={rest.disabled ? DISABLED_BUTTON_OPACITY : undefined}
          borderRadius="two"
          style={{...BUTTON_SIZE_STYLE[size], ...buttonStyle}}
        >
          <Box
            flexDirection="row"
            alignItems="center"
            justifyContent="center"
            // paddingX={['six', 'md']}
            // paddingY={['md', 's']}
            userSelect="none"
            height="100%"
            cursor={rest.disabled || loading ? 'not-allowed' : 'pointer'}
          >
            {loading && (
              <Box style={{position: 'absolute'}}>
                <ActivityIndicator
                  color={activityIndicator}
                  loaderColor={loaderColor}
                />
              </Box>
            )}
            <Box
              opacity={loading ? 0 : 1}
              flexDirection="row"
              alignItems="center"
              justifyContent="center"
            >
              {leftAccessory ? (
                <AnimatedBox style={animatedAccessoryStyle}>
                  {leftAccessory}
                </AnimatedBox>
              ) : null}
              <AnimatedTypography
                style={
                  leftAccessory
                    ? {
                        ...animatedTypographyStyle,
                        color: hasButtonStyle
                          ? buttonStyle.textColor
                          : theme.colors[textColor],
                      }
                    : {
                        color: hasButtonStyle
                          ? buttonStyle.textColor
                          : theme.colors[textColor],
                      }
                }
                textStyle="s"
                textAlign="center"
                type="text"
              >
                {children}
              </AnimatedTypography>
              {rightAccessory ? <Box ml="three">{rightAccessory}</Box> : null}
            </Box>
          </Box>
        </Box>
      </Pressable>
    ) : null;
  },
);
