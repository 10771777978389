/* eslint-disable import/no-cycle */
import {WalletRegistrationSource} from './types';

export const VENLY_CHAINS = {
  ETHEREUM: 5,
  MATIC: 80002,
};

export const VENLY_CHAINS_LIVE = {
  ETHEREUM: 1,
  MATIC: 137,
};

export const VENLY_SECRET_TYPE = ['ETHEREUM', 'MATIC'];

export const registrationSources: WalletRegistrationSource[] = [
  'walletConnect',
  'metaMask',
  'venly',
  'paper',
  'zilPay',
  'magicLinkEmail',
  'magicLinkSMS',
];

export const AUTHENTICATED_WALLET_STATE_COOKIE_NAME =
  'authenticatedWalletState';

export const AUTHENTICATED_WALLET_ID_STATE = 'walletId';

export const AUTHENTICATED_WALLET_INFO_STATE = 'walletInfo';
