import {useCallback, useReducer, Reducer, useState, useMemo} from 'react';

// eslint-disable-next-line no-shadow
export enum InputState {
  Idle = 0,
  Hovering = 1,
  Pressed = 2,
  Focused = 3,
}

// eslint-disable-next-line no-shadow
export enum InputAction {
  PressIn = 'PressIn',
  PressOut = 'PressOut',
  HoverIn = 'HoverIn',
  HoverOut = 'HoverOut',
  Focus = 'Focus',
  Blur = 'Blur',
}

interface InteractionStateOptions {
  retainFocusAfterHoverOut?: boolean;
}

interface InputStateMachineOptions extends InteractionStateOptions {
  hoverState: [boolean, (newState: boolean) => any];
  pressState: [boolean, (newState: boolean) => any];
  focusState: [boolean, (newState: boolean) => any];
}

const inputStateMachine =
  ({
    hoverState: [hovering, setHovering],
    pressState: [pressing, setPressing],
    focusState: [focused, setFocused],
    retainFocusAfterHoverOut,
  }: InputStateMachineOptions): Reducer<InputState, InputAction> =>
  (state, interaction) => {
    switch (interaction) {
      case InputAction.HoverIn:
        setHovering(true);
        if (focused) {
          return InputState.Focused;
        }
        if (pressing) {
          return InputState.Pressed;
        }
        return InputState.Hovering;
      case InputAction.HoverOut:
        setHovering(false);
        if (focused && retainFocusAfterHoverOut) {
          return InputState.Focused;
        }
        return InputState.Idle;
      case InputAction.PressIn:
        setPressing(true);
        return InputState.Pressed;
      case InputAction.PressOut:
        setPressing(false);
        setFocused(false);
        if (hovering) {
          return InputState.Hovering;
        }
        return InputState.Idle;
      case InputAction.Focus:
        setFocused(true);
        return InputState.Focused;
      case InputAction.Blur:
        setFocused(false);
        setPressing(false);
        return InputState.Idle;
      default:
        return state;
    }
  };

const useInputStateMachine = (options: InteractionStateOptions) => {
  const hoverState = useState(false);
  const pressState = useState(false);
  const focusState = useState(false);
  return useReducer(
    inputStateMachine({
      ...options,
      hoverState,
      pressState,
      focusState,
    }),
    InputState.Idle,
  );
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useInteractionState = (options?: InteractionStateOptions) => {
  const {retainFocusAfterHoverOut = false} = options ?? {};
  const [state, dispatch] = useInputStateMachine({retainFocusAfterHoverOut});

  const onHoverIn = useCallback(() => {
    dispatch(InputAction.HoverIn);
  }, [dispatch]);

  const onHoverOut = useCallback(() => {
    dispatch(InputAction.HoverOut);
  }, [dispatch]);

  const onPressIn = useCallback(() => {
    dispatch(InputAction.PressIn);
  }, [dispatch]);

  const onPressOut = useCallback(() => {
    dispatch(InputAction.PressOut);
  }, [dispatch]);

  const onFocus = useCallback(() => {
    dispatch(InputAction.Focus);
  }, [dispatch]);

  const onBlur = useCallback(() => {
    dispatch(InputAction.Blur);
  }, [dispatch]);

  return useMemo(
    () => ({
      state,
      events: {
        onHoverIn,
        onHoverOut,
        onPressIn,
        onPressOut,
        onFocus,
        onBlur,
      },
    }),
    [onBlur, onFocus, onHoverIn, onHoverOut, onPressIn, onPressOut, state],
  );
};
