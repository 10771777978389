import React from 'react';
import {colors} from '../theme/tokens/colors';

export interface TiktokIconProps {
  color?: keyof typeof colors;
  size?: number;
}

export const TiktokIcon = React.memo<TiktokIconProps>(
  ({color = 'surfaceDark', size = 18}) => (
    <svg
      width={size}
      height={size}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.3523 0.125H7.69794V10.8532C7.69794 12.1315 6.67706 13.1815 5.40661 13.1815C4.13616 13.1815 3.11526 12.1315 3.11526 10.8532C3.11526 9.59784 4.11347 8.57063 5.33856 8.525V5.83153C2.63885 5.87716 0.460938 8.09131 0.460938 10.8532C0.460938 13.6381 2.68422 15.875 5.42931 15.875C8.17435 15.875 10.3976 13.6152 10.3976 10.8532V5.35216C11.3959 6.08261 12.6209 6.51631 13.9141 6.53914V3.84565C11.9177 3.77718 10.3523 2.13369 10.3523 0.125Z"
        fill={colors[color] || color}
        stroke={colors[color] || color}
      />
    </svg>
  ),
);
