import {useTransition} from '@ttx/design-system';
import {AnimatableToastData} from './types';

export interface UseToastAnimationOptions {
  onAnimationEnd: (toast: AnimatableToastData) => void;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useToastAnimation = (
  toasts: AnimatableToastData[],
  {onAnimationEnd}: UseToastAnimationOptions,
) =>
  useTransition(toasts, {
    keys: ({id}) => id,
    from: {
      opacity: 1,
      right: '-100%',
      height: 0,
    },
    enter: (item) => async (next) =>
      next({
        right: '0%',
        height: item.ref.current?.offsetHeight || 0,
      }),
    leave: {
      height: 0,
      opacity: 0,
    },
    onRest: (_result, _ctrl, toast) => onAnimationEnd(toast),
  });
