// eslint-disable-next-line max-classes-per-file
import {arrayify} from '@ethersproject/bytes';
import {keccak256} from '@ethersproject/keccak256';
import {ConnectorUpdate} from '@web3-react/types';
import {AbstractConnector} from '@web3-react/abstract-connector';
import {
  JsonRpcResponse,
  WindowWithMaybeZilPay,
  WindowZilPay,
  Zilliqa as ZilliqaType,
  ZilPayObserver,
} from './types';

class UserRejectedRequestError extends Error {
  public constructor() {
    super();
    this.name = this.constructor.name;
    this.message = 'The user rejected the request.';
  }
}

/**
 * Values from docs:
 *
 * @see https://dev.zilliqa.com/docs/apis/api-introduction
 */
export const ZILLIQA_MAIN_NET = 'https://api.zilliqa.com';
export const ZILLIQA_DEV_TEST_NET = 'https://dev-api.zilliqa.com';

/**
 * Values from docs:
 *
 * @see https://dev.zilliqa.com/docs/apis/api-blockchain-get-network-id/
 */
const supportedChainIds = [1, 333, 222];

export class ZilPayConnector extends AbstractConnector {
  private readonly net: string;

  private accountObserver: ZilPayObserver | null = null;

  private networkObserver: ZilPayObserver | null = null;

  public zilliqa: ZilliqaType | undefined;

  windowWithZilPay: WindowWithMaybeZilPay = window;

  constructor(net: string = ZILLIQA_DEV_TEST_NET) {
    super({supportedChainIds});

    this.net = net;
  }

  private handleChainChanged(chainId: number | string): void {
    this.emitUpdate({chainId});
  }

  private handleAccountChanged = (
    account:
      | {
          base16: string;
          bech32: string;
        }
      | undefined,
  ): void => {
    if (!account) {
      this.emitUpdate({account: undefined});
      return;
    }
    /**
     * For some reason, the web3-react library would not accept the address
     * unless it was mutated by the below function.
     */
    const acceptableAddress = this.toWeb3ReactAcceptableAddress(account.base16);

    this.emitUpdate({account: acceptableAddress});
  };

  private getZilPay(): WindowZilPay {
    if (typeof window === 'undefined' || !this.windowWithZilPay.zilPay) {
      throw new Error(
        'You will need to install the ZilPay browser extension to continue.',
      );
    }
    return this.windowWithZilPay.zilPay;
  }

  private toWeb3ReactAcceptableAddress = (_address: string): string => {
    const address =
      _address.substring(0, 2) === '0x' ? _address : `0x${_address}`;
    const chars = address.toLowerCase().substring(2).split('');

    const charsArray = new Uint8Array(40);
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < 40; i++) {
      charsArray[i] = chars[i].charCodeAt(0);
    }
    const hashed = arrayify(keccak256(charsArray));

    for (let i = 0; i < 40; i += 2) {
      // eslint-disable-next-line no-bitwise
      if (hashed[i >> 1] >> 4 >= 8) {
        chars[i] = chars[i].toUpperCase();
      }
      // eslint-disable-next-line no-bitwise
      if ((hashed[i >> 1] & 0x0f) >= 8) {
        chars[i + 1] = chars[i + 1].toUpperCase();
      }
    }

    return `0x${chars.join('')}`;
  };

  public async activate(): Promise<ConnectorUpdate> {
    if (!this.zilliqa) {
      const {Zilliqa} = await import('@zilliqa-js/zilliqa');
      this.zilliqa = new Zilliqa(this.net);
    }

    const isConnected = await this.getZilPay().wallet.connect();

    if (!isConnected) throw new UserRejectedRequestError();

    this.accountObserver = this.getZilPay()
      .wallet.observableAccount()
      .subscribe(this.handleAccountChanged);
    this.networkObserver = this.getZilPay()
      .wallet.observableNetwork()
      .subscribe(this.handleChainChanged);

    return {
      chainId: await this.getChainId(),
      account: await this.getAccount(),
      provider: await this.getProvider(),
    };
  }

  public async getProvider(): Promise<any> {
    return this.zilliqa;
  }

  public async getChainId(): Promise<number | string> {
    const networkIdResponse =
      (await this.zilliqa?.network.GetNetworkId()) as JsonRpcResponse;

    if (!networkIdResponse)
      throw new Error(
        'Did not get a response from Zilliqa for the network ID.',
      );

    const {result} = networkIdResponse;

    return Number(result);
  }

  public async getAccount(): Promise<null | string> {
    const address = this.getZilPay().wallet.defaultAccount?.base16;

    /**
     * For some reason, the web3-react library would not accept the address
     * unless it was mutated by the below function.
     */
    const acceptableAddress = this.toWeb3ReactAcceptableAddress(address);

    return acceptableAddress;
  }

  public deactivate(): void {
    this.accountObserver?.unsubscribe();
    this.networkObserver?.unsubscribe();
  }

  public async close(): Promise<void> {
    this.emitDeactivate();
  }
}
