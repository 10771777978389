import {WalletNetwork} from '../../contexts/authentication-context/types';

export const formatAddress = (str: string): string => {
  if (!str) return 'N/A';
  return `${str.substring(0, 6)}...${str.substring(str.length - 4)}`;
};

export const networkNameToDisplayName = (
  networkName: WalletNetwork,
): string => {
  switch (networkName) {
    case 'ethereum':
      return 'Ethereum';
    case 'polygon':
      return 'Polygon';
    case 'zilliqa':
      return 'Zilliqa';
    default:
      throw new Error(`Unknown network name: ${networkName}`);
  }
};
