import {useSpring} from '@ttx/design-system';

export interface UseRightSideDrawerAnimationOptions {
  showDrawer: boolean;
  onAnimationFinish?: () => any;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useRightSideDrawerAnimation = ({
  showDrawer,
  onAnimationFinish,
}: UseRightSideDrawerAnimationOptions) =>
  useSpring({
    transform: `translate(${showDrawer ? '0%' : '110%'})`,

    onRest: (state) => {
      if (!state.finished) return;
      if (!showDrawer) onAnimationFinish?.();
    },
  });

export interface UseRightSideBackgroundAnimationOptions {
  showDrawer: boolean;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useRightSideBackgroundAnimation = ({
  showDrawer,
}: UseRightSideBackgroundAnimationOptions) =>
  useSpring({
    background: showDrawer ? 'rgba(1, 2, 3, 0.6)' : 'rgba(1, 2, 3, 0)',
    backdropFilter: showDrawer ? 'blur(50px)' : 'blur(0px)',
  });
