import {ViewStyle} from 'react-native';
import {
  propertyToVariant,
  propertyShorthandToVariant,
  mapArrayToObject,
  VariantProps,
} from '../variant-utilities';
import {theme} from '../../theme';

export const layout = {
  ...propertyToVariant('width', theme.sizes),
  ...propertyToVariant('height', theme.sizes),

  ...propertyShorthandToVariant('w', ['width'], theme.sizes),
  ...propertyShorthandToVariant('h', ['height'], theme.sizes),

  ...propertyToVariant('minWidth', theme.sizes),
  ...propertyToVariant('maxWidth', theme.sizes),
  ...propertyToVariant('minHeight', theme.sizes),
  ...propertyToVariant('maxHeight', theme.sizes),

  ...propertyShorthandToVariant('size', ['width', 'height'], theme.sizes),

  ...propertyToVariant(
    'overflow',
    mapArrayToObject<NonNullable<ViewStyle['overflow']>>([
      'hidden',
      'scroll',
      'visible',
    ]),
  ),
  ...propertyToVariant(
    'overflowX',
    mapArrayToObject<NonNullable<ViewStyle['overflow']>>([
      'hidden',
      'scroll',
      'visible',
    ]),
  ),
  ...propertyToVariant(
    'overflowY',
    mapArrayToObject<NonNullable<ViewStyle['overflow']>>([
      'hidden',
      'scroll',
      'visible',
    ]),
  ),
};

export type LayoutProps = VariantProps<typeof layout>;
