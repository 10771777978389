export const colors = {
  brandPrimary: '#E6FF00',
  errorPrimary: '#E63280',
  warningPrimary: '#E74F00',
  surfaceDark: '#010203',
  surfaceBlack: '#000',
  surfaceLight: '#303030',
  surfaceInvert: '#F9FAF2',
  outlines: '#FFF',
  outlinesInvert: '#010203',
  textDefault: '#FFF',
  textLow: '#B0B1A9',
  textInvert: '#010203',
  gray0: '#53524A',
  grey12: '#1F2021', // Dark Grey
  grey18: '#2F3030',
  grey24: '#3E3F3F',
  grey32: '#525354', // Light Grey
  gray: '#EEEEEE',
  gray64: '#EEEEEE',
  gray65: '#DDDDDD',
  gray66: '#838383',
  textLowInvert: '#45463F',
  transparent: 'transparent',
  gradientPrimary:
    'linear-gradient(180deg, #010203 3.6%, rgba(48, 48, 48, 0) 86.76%)',
  gradientTop:
    'linear-gradient(180deg, rgba(1, 2, 3, 0.5) 0%, rgba(27, 28, 28, 0.21875) 48.81%, rgba(48, 48, 48, 0) 86.76%)',
  gradientBottom:
    'linear-gradient(180deg, rgb(255 255 255 / 80%), rgba(27, 28, 28, 0.21875) 48.81%, rgba(48, 48, 48, 0) 86.76%)',
  gradientViewMore:
    'linear-gradient(180deg, rgba(1, 2, 3, 0) 0%, #010203 100%)',
  gradientHalf: `linear-gradient(180deg, rgba(1, 2, 3, 0) 50%, rgba(207, 210, 195, 0.6) 100%)`,
  footerDesktop:
    'linear-gradient(180deg, rgba(207, 210, 195, 0) 0%, #CFD2C3 59.58%)',
  footerMobile:
    'linear-gradient(180deg, rgba(207, 210, 195, 0) 0%, #CFD2C3 29.29%)',
  greyPrimary: '#C4C4C4',
  backgroundBlur: 'rgba(1, 2, 3, 0.5)',
};

export const untypedColors = colors as Record<string, string>;
