import React from 'react';
import {Button} from '../../button/button';
import {ArrowRightIcon} from '../../icons';

interface PresetWalletButtonProps {
  type: 'primary' | 'secondary';
  onPress: () => void;
}

export interface WalletButtonProps extends PresetWalletButtonProps {
  children: string;
}

export const WalletButton: React.FC<WalletButtonProps> = ({
  type,
  onPress,
  children,
}) => (
  <Button
    hasBorder={type !== 'primary'}
    type={type}
    buttonStyle={{
      margin: '8px 6px 8px 6px',
      backgroundColor:
        type !== 'primary'
          ? 'var(--studio-secondaryButton)'
          : 'var(--studio-primaryButton)',
      textColor:
        type !== 'primary'
          ? 'var(--studio-secondaryButtonText)'
          : 'var(--studio-primaryButtonText)',
    }}
    hasButtonStyle
    onPress={onPress}
    leftAccessory={
      <ArrowRightIcon
        color={
          type === 'primary'
            ? ('var(--studio-primaryButtonText)' as any)
            : ('var(--studio-secondaryButtonText)' as any)
        }
      />
    }
  >
    {children}
  </Button>
);

export const LogoutWalletButton: React.FC<PresetWalletButtonProps> = (
  props,
) => <WalletButton {...props}>Disconnect wallet</WalletButton>;

export const RefreshFundsWalletButton: React.FC<PresetWalletButtonProps> = (
  props,
) => <WalletButton {...props}>Refresh funds</WalletButton>;

export const ReconnectWalletButton: React.FC<PresetWalletButtonProps> = (
  props,
) => <WalletButton {...props}>Connect wallet</WalletButton>;
