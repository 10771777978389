/* eslint-disable @typescript-eslint/ban-types */

import {mergeObjectArrayToObject} from './variant-utilities';

type ExtendableTheme<
  ThemeToken extends string = string,
  ThemeTokenKey extends string = string,
  ThemeTokenValue extends string | number = string | number,
> = {
  [themeToken in ThemeToken]: {
    [themeTokenKey in ThemeTokenKey]: ThemeTokenValue;
  };
};

type ExtendableMedia<MediaQuery extends string = string> = Record<
  MediaQuery,
  string
>;

export type ExtendableUtilsFunction<
  PropertyName extends string = string,
  Theme extends ExtendableTheme = ExtendableTheme,
  ThemeToken extends keyof Theme = keyof Theme,
  Media extends ExtendableMedia = ExtendableMedia,
  Value extends (string & {}) | number | (keyof Theme[ThemeToken] & string) =
    | (string & {})
    | number
    | (keyof Theme[ThemeToken] & string),
> = (
  value: Value | Value[],
) =>
  | Record<PropertyName, keyof Theme[ThemeToken]>
  | Partial<
      Record<
        Media[keyof Media],
        Record<PropertyName, keyof Theme[ThemeToken] & string>
      >
    >;

export type PropsFromUtils<
  Utils extends Record<string, ExtendableUtilsFunction> = Record<
    string,
    ExtendableUtilsFunction
  >,
> = Partial<{[utilName in keyof Utils]: Parameters<Utils[utilName]>[0]}>;

export const resolveValueToThemeOrArbitraryValue = <
  Theme extends ExtendableTheme = ExtendableTheme,
  ThemeTokenKey extends keyof Theme = keyof Theme,
  DimensionValue extends number | (string & {}) | keyof Theme[ThemeTokenKey] =
    | number
    | (string & {})
    | keyof Theme[ThemeTokenKey],
>(
  value: DimensionValue,
  theme?: Theme,
  tokenTypeName?: ThemeTokenKey,
): DimensionValue => {
  if (!theme) return value;
  if (!tokenTypeName) return value;

  const valueFromTheme = theme[tokenTypeName][value as any];

  if (valueFromTheme !== undefined) return valueFromTheme as any;

  return value;
};

export const transformArrayToResponsiveObject = <
  PropertyName extends string = string,
  Theme extends ExtendableTheme = ExtendableTheme,
  ThemeTokenKey extends keyof Theme & string = keyof Theme & string,
  DimensionValue extends
    | number
    | (string & {})
    | (keyof Theme[ThemeTokenKey] & string) =
    | number
    | (string & {})
    | (keyof Theme[ThemeTokenKey] & string),
  Media extends ExtendableMedia = ExtendableMedia,
  InitialStyle extends Record<PropertyName, DimensionValue> = Record<
    PropertyName,
    DimensionValue
  >,
  ResponsiveStyle extends Record<keyof Media, InitialStyle> = Record<
    keyof Media,
    InitialStyle
  >,
>(
  propertyNames: PropertyName[],
  dimensionValues: DimensionValue[],
  media: Media,
  theme?: Theme,
  themeTokenName?: keyof Theme,
): InitialStyle & ResponsiveStyle => {
  if (!dimensionValues.length) return {} as InitialStyle & ResponsiveStyle;

  const firstDimensionValue = dimensionValues.shift() as DimensionValue;

  const initialStyle = propertyNames
    .map((propertyName) => ({
      [propertyName]: resolveValueToThemeOrArbitraryValue(
        firstDimensionValue,
        theme,
        themeTokenName,
      ),
    }))
    .reduce(mergeObjectArrayToObject, {});

  const mediaValues = Object.values(media);

  const responsiveStyles = dimensionValues
    .map((_, breakpointIndex) => ({
      [`@media ${mediaValues[breakpointIndex]}`]: propertyNames
        .map((propertyName) => ({
          [propertyName]: resolveValueToThemeOrArbitraryValue(
            dimensionValues[breakpointIndex] ?? firstDimensionValue,
            theme,
            themeTokenName,
          ),
        }))
        .reduce(mergeObjectArrayToObject, {}),
    }))
    .concat(
      dimensionValues.length < mediaValues.length
        ? new Array(mediaValues.length - dimensionValues.length)
            .fill(null)
            .map((_, index) => ({
              [`@media ${mediaValues[dimensionValues.length + index]}`]:
                propertyNames
                  .map((propertyName) => ({
                    [propertyName]: resolveValueToThemeOrArbitraryValue(
                      dimensionValues[dimensionValues.length - 1] ??
                        firstDimensionValue,
                      theme,
                      themeTokenName,
                    ),
                  }))
                  .reduce(mergeObjectArrayToObject, {}),
            }))
        : [],
      {
        [`@media ${mediaValues[mediaValues.length - 1]}`]: propertyNames
          .map((propertyName) => ({
            [propertyName]: resolveValueToThemeOrArbitraryValue(
              dimensionValues[dimensionValues.length - 1] ??
                firstDimensionValue,
              theme,
              themeTokenName,
            ),
          }))
          .reduce(mergeObjectArrayToObject, {}),
      },
    )
    .reduce(mergeObjectArrayToObject, {});

  return {
    ...(initialStyle as InitialStyle),
    ...(responsiveStyles as ResponsiveStyle),
  };
};

export const createUtil =
  <
    PropertyName extends string = string,
    Theme extends ExtendableTheme = ExtendableTheme,
    ThemeToken extends keyof Theme & string = keyof Theme & string,
    Media extends ExtendableMedia = ExtendableMedia,
  >(
    propertyNames: PropertyName[],
    media?: Media,
    theme?: Theme,
    themeTokenName?: ThemeToken,
  ): ExtendableUtilsFunction<PropertyName, Theme, ThemeToken, Media> =>
  (value) => {
    if (!Array.isArray(value))
      return propertyNames
        .map((propertyName) => ({
          [propertyName]: resolveValueToThemeOrArbitraryValue(
            value,
            theme,
            themeTokenName,
          ),
        }))
        .reduce(mergeObjectArrayToObject, {}) as any;

    const resolvedValues = value.map((valueAtBreakpoint) =>
      resolveValueToThemeOrArbitraryValue(
        valueAtBreakpoint,
        theme,
        themeTokenName,
      ),
    );

    if (!media)
      return propertyNames
        .map((propertyName) => ({
          [propertyName]: resolveValueToThemeOrArbitraryValue(
            value[0],
            theme,
            themeTokenName,
          ),
        }))
        .reduce(mergeObjectArrayToObject, {});

    return transformArrayToResponsiveObject(
      propertyNames,
      resolvedValues,
      media,
      theme,
      themeTokenName,
    );
  };

export const transformUtilsProps = <
  VariantPropName extends string,
  Props extends Partial<Record<VariantPropName, unknown>>,
>(
  propNames: VariantPropName[],
  props: Props,
): Record<keyof Props & string, Props[keyof Props & string]> =>
  propNames
    .filter((propName) => props[propName] !== undefined)
    .map((propName) => ({
      [propName]: props[propName],
    }))
    .reduce(mergeObjectArrayToObject, {} as Record<keyof Props & string, any>);
