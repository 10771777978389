import React from 'react';
import {colors, untypedColors} from '../theme/tokens/colors';

export interface UsdIconProps {
  color?: keyof typeof colors | (string & Record<string, unknown>);
  size?: number;
}

export const UsdIcon = React.memo<UsdIconProps>(
  ({color = 'outlines', size = 32}) => (
    <svg
      width={size}
      height={size}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.38127 16.3127V14.514C7.32315 14.4962 6.22855 14.1757 5.59003 13.7225L6.02788 12.5353C6.81256 13.0326 7.72749 13.2985 8.66303 13.3011C9.95831 13.3011 10.834 12.5709 10.834 11.5559C10.834 10.5665 10.1225 9.97287 8.77248 9.43664C6.91165 8.7243 5.76232 7.90511 5.76232 6.35577C5.76232 4.87767 6.83867 3.75573 8.51707 3.4886V1.68994H9.64817V3.41737C10.4802 3.42477 11.2965 3.63929 12.0198 4.04066L11.5657 5.21208C10.8709 4.80828 10.0757 4.59882 9.26709 4.60659C7.86235 4.60659 7.33328 5.42578 7.33328 6.13812C7.33328 7.06416 8.00829 7.52322 9.59547 8.16829C11.4745 8.91624 12.4333 9.84228 12.4333 11.4272C12.4333 12.8341 11.4198 14.1519 9.55088 14.4547V16.3127H8.38127Z"
        fill={untypedColors[color] ?? color}
      />
    </svg>
  ),
);
