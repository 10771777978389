import {keyframes} from '@stitches/react';
import React, {useEffect, useRef, useState} from 'react';
import {createPortal} from 'react-dom';
import {Modal as ReactNativeModal} from 'react-native';
import {Box} from '../box';

const fadeIn = keyframes({
  '0%': {opacity: 0},
  '100%': {opacity: 1},
});

const fadeOut = keyframes({
  '0%': {opacity: 1},
  '100%': {opacity: 0},
});

const ReactModal = ({visible, ...rest}: any) => {
  const [show, setShow] = useState(visible);
  const [hide, setHide] = useState(false);

  const boxRef = useRef<any>(null);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (visible) {
      setHide(false);
      setShow(true);
    } else {
      setHide(true);
      const onEnd = () => setShow(false);
      boxRef.current?.addEventListener('animationend', onEnd);
      return () => boxRef.current?.removeEventListener('animationend', onEnd);
    }
  }, [visible]);

  return show
    ? createPortal(
        <Box
          {...rest}
          ref={boxRef}
          opacity={1}
          position="absolute"
          top={0}
          left={0}
          width="100%"
          minHeight="100%"
          css={{
            backdropFilter: 'blur(20px)',
            animation: `${hide ? fadeOut : fadeIn} 500ms`,
          }}
        >
          {rest.children}
        </Box>,
        document.querySelector('#portal') as HTMLElement,
      )
    : null;
};

export interface ModalContainerProps {
  useReactNativeModal?: boolean;
  visible: boolean;
}

export const ModalContainer: React.FC<ModalContainerProps> = ({
  useReactNativeModal = true,
  visible,
  ...rest
}) => {
  return useReactNativeModal ? (
    // @ts-expect-error react-native fix
    <ReactNativeModal
      {...rest}
      visible={visible}
      animationType="fade"
      transparent
    />
  ) : (
    <ReactModal {...rest} visible={visible} />
  );
};
