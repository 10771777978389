import React from 'react';
import {Button} from '../button/button';
import {ArrowRightIcon} from '../icons';

interface PresetWalletButtonProps {
  type: 'primary' | 'secondary';
  onPress: () => void;
}

export interface WalletButtonProps extends PresetWalletButtonProps {
  children: string;
}

export const WalletButton: React.FC<WalletButtonProps> = ({
  type,
  onPress,
  children,
}) => (
  <Button
    hasBorder={type !== 'primary'}
    type={type}
    onPress={onPress}
    buttonStyle={{margin: '8px 6px 8px 6px'}}
    leftAccessory={
      <ArrowRightIcon
        color={type === 'primary' ? 'surfaceDark' : 'textDefault'}
      />
    }
  >
    {children}
  </Button>
);

export const LogoutWalletButton: React.FC<PresetWalletButtonProps> = (
  props,
) => <WalletButton {...props}>Disconnect wallet</WalletButton>;

export const RefreshFundsWalletButton: React.FC<PresetWalletButtonProps> = (
  props,
) => <WalletButton {...props}>Refresh funds</WalletButton>;

export const ReconnectWalletButton: React.FC<PresetWalletButtonProps> = (
  props,
) => <WalletButton {...props}>Connect wallet</WalletButton>;
