import {createUtil, PropsFromUtils} from '../utils-utilities';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const flexbox = <
  Media extends Record<any, string> = Record<any, string>,
>(
  media: Media,
) => ({
  flex: createUtil(['flex'], media),
  flexGrow: createUtil(['flexGrow'], media),
  flexShrink: createUtil(['flexShrink'], media),
  flexBasis: createUtil(['flexBasis'], media),
});

export type FlexboxUtilsProps = PropsFromUtils<ReturnType<typeof flexbox>>;
