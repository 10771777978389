import React, {useMemo} from 'react';
import {
  isZilPayProvider,
  Zilliqa,
  ExternalProvider,
  Venly,
  isVenlyProvider,
  isPaperProvider,
} from '@ttx/core';
import {Web3ReactProvider} from '@web3-react/core';
import {WalletConnectStyle} from './walletconnect-style';

const libraryLoader = async (provider: ExternalProvider | Zilliqa | Venly) => {
  const {ZilPayProvider, Web3Provider, VenlyProvider, PaperProvider} =
    await import('@ttx/core');
  if (isZilPayProvider(provider)) return new ZilPayProvider(provider as any);
  if (isVenlyProvider(provider)) return new VenlyProvider(provider as any);
  if (isPaperProvider(provider)) return new PaperProvider(provider as any);

  return new Web3Provider(provider as any, 'any');
};

export interface ClientSideWalletConnectorProps {
  children: NonNullable<React.ReactNode>;
}

export const ClientSideWalletConnector: React.FC<
  ClientSideWalletConnectorProps
> = ({children}) => {
  const getLibrary = useMemo(
    () => (provider: ExternalProvider) => libraryLoader(provider),
    [],
  );

  return (
    <>
      <WalletConnectStyle />
      <Web3ReactProvider getLibrary={getLibrary}>{children}</Web3ReactProvider>
    </>
  );
};
