import {useEffect, useState} from 'react';
import {createPortal} from 'react-dom';

const Portal: React.FC<{isMounted: boolean}> = ({children, isMounted}) => {
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    if (isMounted) setMounted(true);
    else setMounted(false);

    return () => {
      setMounted(false);
    };
  }, [isMounted]);

  return mounted
    ? createPortal(children, document.querySelector('#portal') as HTMLElement)
    : null;
};

export {Portal};
