import React from 'react';
import {ImageRenderer, ImageRendererProvider} from '@ttx/design-system';
import NextImage, {ImageLoader} from 'next/legacy/image';

export interface NextJSImageRendererProviderProps {
  children: React.ReactNode;
}

export const renderNextJSImage: ImageRenderer = ({loader, layout, ...rest}) => {
  const unoptimisedLoader: ImageLoader = ({src}) => src;
  const useUnoptimisedLoader = layout === 'fill';
  return (
    <NextImage
      {...rest}
      layout={layout}
      loader={useUnoptimisedLoader ? unoptimisedLoader : loader}
      unoptimized={useUnoptimisedLoader}
    />
  );
};

export const NextJSImageRendererProvider = React.memo(
  (props: NextJSImageRendererProviderProps) => (
    <ImageRendererProvider {...props} renderImage={renderNextJSImage} />
  ),
);
