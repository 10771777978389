import {createUtil, PropsFromUtils} from '../utils-utilities';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const position = <
  Theme extends Record<'space' | 'zIndices', Record<any, any>> = Record<
    'space' | 'zIndices',
    Record<any, any>
  >,
  Media extends Record<any, string> = Record<any, string>,
>(
  theme: Theme,
  media: Media,
) => ({
  top: createUtil(['top'], media, theme, 'space'),
  left: createUtil(['left'], media, theme, 'space'),
  bottom: createUtil(['bottom'], media, theme, 'space'),
  right: createUtil(['right'], media, theme, 'space'),

  zIndex: createUtil(['zIndex'], media, theme, 'zIndices'),
});

export type PositionUtilsProps = PropsFromUtils<ReturnType<typeof position>>;
