/* eslint-disable import/no-cycle */
import React, {useEffect, useMemo, useState} from 'react';
import {Modal} from 'react-native';
import {Typography} from '../../typography';
import {Box} from '../../box';
import {
  HamburgerIcon,
  CloseIcon,
  TokenTraxxIcon,
  ArrowRightIcon,
} from '../../icons';
import {TransparentButton} from '../../button/transparent-button';
import {CommonNavbarProps} from './types';
import {FrameworkLink} from '../../link-renderer';
import {ArrowLink, BasicLink, NavbarInfoMobile} from './navbar-info-mobile';
import {Button} from '../../button/button';
import {FrameworkImage} from '../../image-renderer';
import {STUDIO_MENUS} from '../../../../../apps/web/utils/consts';

export interface MobileNavbarProps extends CommonNavbarProps {
  onLogoutPress?: () => void;
  isHomePage?: boolean;
  aboutMenuItems: {
    text: string;
    href?: string;
    items?: {text: string; href: string}[];
  }[];
}

// eslint-disable-next-line no-shadow
enum MenuState {
  MAIN,
  ABOUT,
  SUBMENU,
}

const BasicButton: React.FC<{
  key: string;
  onPress: () => void;
  text: string;
}> = ({key, onPress, text}) => {
  return (
    <TransparentButton key={key} onPress={onPress}>
      <Box
        p="three"
        borderTopColor="outlines"
        borderTopWidth="one"
        borderTopStyle="solid"
      >
        <Typography textStyle="h2" color="textDefault">
          {text}
        </Typography>
      </Box>
    </TransparentButton>
  );
};

export const MobileNavbar: React.FC<MobileNavbarProps> = ({
  currentHref,
  items,
  aboutMenuItems,
  walletConnected,
  openWallet,
  openPurchaseHistory,
  homePageHref = '#',
  isHomePage = false,
  studioSlug,
}) => {
  const [showMenu, setShowMenu] = useState(false);
  const [showHomeMenu, setShowHomeMenu] = useState(false);
  const [menuState, setMenuState] = useState(MenuState.MAIN);
  const [subMenuItems, setSubMenuItems] =
    useState<{text: string; href: string}[]>();

  useEffect(() => {
    setShowMenu(false);
  }, [currentHref]);

  const mainMenu = useMemo(() => {
    return (
      <>
        {items.map(
          (item) =>
            item &&
            typeof item === 'object' &&
            'href' in item && (
              <BasicLink
                key={`${item.text}${item.href}`}
                href={item.href}
                text={item.text}
              />
            ),
        )}
        <ArrowLink
          key="about-key"
          onPress={() => {
            setMenuState(MenuState.ABOUT);
          }}
          text="About"
        />
        {currentHref !== '/' && (
          <>
            {!walletConnected && (
              <Button
                type="primary"
                size="M"
                key="connect-wallet"
                onPress={() => {
                  openWallet();
                  setShowMenu(false);
                }}
              >
                Connect wallet
              </Button>
            )}
            {walletConnected && (
              <BasicButton
                key="connect-wallet-1"
                onPress={() => {
                  openWallet();
                  setShowMenu(false);
                }}
                text="My wallet"
              />
            )}

            {walletConnected && (
              <BasicButton
                key="transactions"
                onPress={() => {
                  openPurchaseHistory();
                  setShowMenu(false);
                }}
                text="Transactions"
              />
            )}
          </>
        )}
      </>
    );
  }, [
    currentHref,
    items,
    openPurchaseHistory,
    openWallet,
    walletConnected,
    studioSlug,
  ]);

  const aboutMenu = useMemo(() => {
    return aboutMenuItems.map((item) => {
      if (item.href) {
        return (
          <BasicLink
            key={`${item.text}${item.href}`}
            href={item.href}
            text={item.text}
          />
        );
      }
      return (
        <ArrowLink
          key={`${item.text}${item.href}`}
          onPress={() => {
            setMenuState(MenuState.SUBMENU);
            setSubMenuItems(item.items);
          }}
          text={item.text}
        />
      );
    });
  }, [aboutMenuItems]);

  const subMenu = useMemo(() => {
    return subMenuItems?.map((item) => {
      return (
        <BasicLink
          key={`${item.text}${item.href}`}
          href={item.href}
          text={item.text}
        />
      );
    });
  }, [subMenuItems]);

  const renderMenu = useMemo(() => {
    if (menuState === MenuState.MAIN) {
      return mainMenu;
    }
    if (menuState === MenuState.ABOUT) {
      return aboutMenu;
    }
    return subMenu;
  }, [aboutMenu, mainMenu, menuState, subMenu]);

  const logo = (
    <Box
      height={
        (STUDIO_MENUS[studioSlug as keyof typeof STUDIO_MENUS] &&
          STUDIO_MENUS[studioSlug as keyof typeof STUDIO_MENUS].height) ||
        '55px'
      }
      width="108px"
    >
      <FrameworkImage
        src={
          STUDIO_MENUS[studioSlug as keyof typeof STUDIO_MENUS] &&
          STUDIO_MENUS[studioSlug as keyof typeof STUDIO_MENUS].logo
        }
        alt="background"
        layout="fill"
      />
    </Box>
  );
  return (
    <Box>
      <Box
        width="100%"
        bg="textDefault"
        flexDirection="row"
        borderColor="textLow"
        borderWidth="one"
        borderStyle="solid"
        justifyContent="space-between"
        alignItems="center"
        css={{
          'background-color': 'var(--studio-backgroundColor)',
        }}
        minHeight={60}
      >
        {currentHref === '/' ? (
          <FrameworkLink href="/">
            <Box paddingY="two" pl="three">
              {logo}
            </Box>
          </FrameworkLink>
        ) : (
          <FrameworkLink href={homePageHref}>
            <Box paddingY="two" pl="three">
              {logo}
            </Box>
          </FrameworkLink>
        )}
        <TransparentButton onPress={() => setShowHomeMenu(true)}>
          <Box paddingX="three" paddingY="two">
            <HamburgerIcon color="textLow" size={18} />
          </Box>
        </TransparentButton>
      </Box>
      {showHomeMenu && (
        <NavbarInfoMobile
          walletConnected={walletConnected}
          openWallet={openWallet}
          openPurchaseHistory={openPurchaseHistory}
          currentHref={currentHref}
          closeMenu={() => setShowHomeMenu(false)}
          studioSlug={studioSlug}
        />
      )}
    </Box>
  );
};
