export class LocalStroageUtil {
  static getItem(key: string): string | null {
    return LocalStroageUtil.hasItem(key) ? localStorage.getItem(key) : null;
  }

  static hasItem(key: string): boolean {
    return !!localStorage.getItem(key);
  }

  static setItem(key: string, value: any): void {
    localStorage.setItem(key, value);
  }

  static removeItem(key: string): void {
    localStorage.removeItem(key);
  }

  static removeAll(): void {
    localStorage.clear();
  }
}

export const LOCAL_STORAGE_KEY = {
  PAYMENT_ID: 'PAYMENT_ID',
  IS_CRYPTO_FLOW: 'IS_CRYPTO_FLOW',
  ERROR_BANNER: 'ERROR_BANNER',
  SHARE_FLOW: 'SHARE_FLOW',
  TRACK_NETWORK: 'TRACK_NETWORK',
  ARTIST_FLOW: 'ARTIST_FLOW',
};

export const POLYMESH_LOCAL_STORAGE = {
  NODE_URL: 'NODE_URL',
  DID: 'DID',
};
