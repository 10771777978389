import React from 'react';
import {colors} from '../theme/tokens/colors';

export interface WarningIconProps {
  color?: keyof typeof colors;
  size?: number;
}

export const WarningIcon = React.memo<WarningIconProps>(
  ({color = 'warningPrimary', size = 12}) => (
    <svg
      width={size}
      height={size}
      viewBox="0 0 12 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.2872 11L5.99519 0.947999L0.717188 11H11.2872ZM5.99519 3.188L9.56519 9.964H2.43919L5.99519 3.188Z"
        fill={colors[color] || color}
      />
    </svg>
  ),
);
