import {useMemo, useState} from 'react';

type UpdaterFunction<Value extends any, InitialValue extends any> = (
  currentValue: Value | InitialValue,
) => Promise<Value | InitialValue>;

/**
 * Provides a useState as a cache with an updater function.
 *
 * On the first tick, the returned state value will be initialValue.
 *
 * @todo write unit tests for this hook
 *
 * @param update
 * @param initialValue
 * @returns
 */
export const useCachedState = <Value extends any, InitialValue extends any>(
  update: UpdaterFunction<Value, InitialValue>,
  initialValue: Value | InitialValue,
): Value | InitialValue => {
  const [cachedValue, setCachedValue] = useState<Value | InitialValue>(
    initialValue,
  );
  const value = useMemo((): Value | InitialValue => {
    update(cachedValue).then((newValue) => {
      if (
        [
          'string',
          'number',
          'bigint',
          'function',
          'boolean',
          'symbol',
          'undefined',
        ].includes(typeof newValue)
      ) {
        // Do reference equality check for primitives
        if (newValue === cachedValue) return;
      } else if (JSON.stringify(newValue) === JSON.stringify(cachedValue))
        return;

      setCachedValue(newValue);
    });

    return cachedValue;
  }, [cachedValue, update]);

  return value;
};
