import React from 'react';
import {colors, untypedColors} from '../theme/tokens/colors';

export interface TokenTraxxProps {
  color?: keyof typeof colors | (string & Record<string, never>);
  size?: number;
}

export const TokenTraxxIcon = React.memo<TokenTraxxProps>(
  ({color = 'outlines', size = 26}) => (
    <svg
      width={size}
      height={size}
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.5938 6.5H2.03125V8.125H12.5938V6.5ZM8.125 19.5L8.125 8.9375H6.5L6.5 19.5H8.125ZM19.5 8.9375V19.5H17.875V8.9375H19.5ZM23.9687 6.5H13.4062V8.125H23.9687V6.5Z"
        fill={untypedColors[color] || color}
      />
    </svg>
  ),
);
