/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable no-restricted-globals */
import nookies from 'nookies';
import {
  AUTHENTICATED_WALLET_STATE_COOKIE_NAME,
  AUTHENTICATED_WALLET_ID_STATE,
  registrationSources,
  AUTHENTICATED_WALLET_INFO_STATE,
} from './const';
import {CookieName, LoggedWalletInfo, WalletState} from './types';

export const setCookie = (
  cookieKey: CookieName,
  cookieValue: string,
  maxAge = 3600, // 1 hour
): void => {
  nookies.set(null, cookieKey, cookieValue, {
    maxAge,
    path: '/',
    sameSite: undefined,
    secure: true,
  });
};

export const setWalletIdCookies = (walletId: string): void => {
  setCookie(AUTHENTICATED_WALLET_ID_STATE, walletId);
};

export const setLoggedWalletInfo = (info: LoggedWalletInfo): void => {
  setCookie(AUTHENTICATED_WALLET_INFO_STATE, JSON.stringify(info));
};

export const sanitizeLoadedWalletState = (
  loadedWalletState: unknown,
): WalletState | null => {
  if (typeof loadedWalletState !== 'object') return null;

  const typedWalletState = loadedWalletState as Partial<WalletState>;

  if (
    !typedWalletState.address ||
    typeof typedWalletState.address !== 'string' ||
    !typedWalletState.address.startsWith('0x')
  )
    return null;

  if (
    !typedWalletState.provider ||
    !registrationSources.includes(typedWalletState.provider)
  )
    return null;

  if (!typedWalletState.chainId || typeof typedWalletState.chainId !== 'number')
    return null;

  return typedWalletState as WalletState;
};

export const getAuthenticatedWalletState = (): WalletState | null => {
  const stringifiedWalletState =
    nookies.get()[AUTHENTICATED_WALLET_STATE_COOKIE_NAME];

  if (!stringifiedWalletState) return null;

  const loadedWalletState = JSON.parse(stringifiedWalletState);

  return sanitizeLoadedWalletState(loadedWalletState);
};

export const isPopupBlockerEnabled = (env: string) => {
  const left = screen.width / 2 - 350 / 2;
  const top = screen.height / 2 - 685 / 2;
  const userWindow = window.open(
    env === 'staging'
      ? 'https://connect-staging.venly.io/'
      : 'https://connect.venly.io/',
    '',
    `popup=true, toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=1, height=1, top= ${top}, left= ${left}`,
  );
  try {
    if (
      !userWindow ||
      userWindow.closed ||
      typeof userWindow.closed === 'undefined'
    ) {
      return false;
    }
    userWindow?.close();
    const usrWindow = window.open(
      env === 'staging'
        ? 'https://connect-staging.venly.io/'
        : 'https://connect.venly.io/',
      '',
      `popup=true, toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=1, height=1, top= ${top}, left= ${left}`,
    );
    if (
      !usrWindow ||
      usrWindow.closed ||
      typeof usrWindow.closed === 'undefined'
    )
      return false;
    usrWindow?.close();
    return true;
  } catch (e) {
    return false;
  }
};
