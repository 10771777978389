import {
  mapArrayToObject,
  propertyToVariant,
  VariantProps,
} from '../variant-utilities';

type BackgroundRepeatValue =
  | 'repeat-x'
  | 'repeat-y'
  | 'repeat'
  | 'space'
  | 'round'
  | 'no-repeat';

type BackgroundPositionValue = 'top' | 'bottom' | 'left' | 'right' | 'center';

type BackgroundSizeValue = 'cover' | 'contain';

export const background = {
  ...propertyToVariant(
    'backgroundRepeat',
    mapArrayToObject<
      | BackgroundRepeatValue
      | `${BackgroundRepeatValue} ${BackgroundRepeatValue}`
    >(['repeat-x', 'repeat-y', 'repeat', 'space', 'round', 'no-repeat']),
  ),
  ...propertyToVariant(
    'backgroundPosition',
    mapArrayToObject<
      | BackgroundPositionValue
      | `${BackgroundPositionValue} ${BackgroundPositionValue}`
    >(['top', 'bottom', 'left', 'right', 'center']),
  ),
  ...propertyToVariant(
    'backgroundSize',
    mapArrayToObject<BackgroundSizeValue>(['cover', 'contain']),
  ),
};

export type BackgroundProps = VariantProps<typeof background>;
