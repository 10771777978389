import {ContractInterface} from '@ttx/core';
import {useEffect, useState} from 'react';
import {useTtxQuery} from '../../hooks/ttx-trpc';
import {WalletNetwork} from '../authentication-context/types';

const setWithExpiry = ({
  key,
  value,
  ttlInHours,
}: {
  key: string;
  value: string;
  ttlInHours: number;
}) => {
  const now = new Date();

  const ttlInMs = ttlInHours * 1000 * 60 * 60;

  const item = {
    value,
    expiry: now.getTime() + ttlInMs,
  };
  localStorage.setItem(key, JSON.stringify(item));
};

const getWithExpiry = ({key}: {key: string}) => {
  const itemStr = localStorage.getItem(key);
  if (!itemStr) {
    return null;
  }
  const item = JSON.parse(itemStr);
  if (!JSON.parse(item.value)) return null;
  const now = new Date();
  if (now.getTime() > item.expiry) {
    localStorage.removeItem(key);
    return null;
  }
  return item.value;
};

/**
 * The purpose of this hook is to get relevant smart contract and set it to localStorage.
 */
export const useGetSmartContract: ({network}: {network?: WalletNetwork}) => {
  data: {[k: string]: ContractInterface};
  error: any;
  isError: boolean;
  isLoading: boolean;
} = ({network}) => {
  const [enabled, setEnabled] = useState(false);
  const [result, setResult] = useState<{
    data: any;
    error: any;
    isError: boolean;
    isLoading: boolean;
  }>({data: undefined, error: null, isError: false, isLoading: true});

  const smartContractAbi = useTtxQuery(['evm.getAbi', {network}], {
    enabled,
  });

  useEffect(() => {
    if (!network || network === 'zilliqa') return;
    const smartContractInLocalStorage = getWithExpiry({
      key: `smartContractAbi-${network}`,
    });
    if (smartContractInLocalStorage) {
      const data = JSON.parse(smartContractInLocalStorage);
      setResult({
        data,
        error: null,
        isError: false,
        isLoading: false,
      });
    }
    setEnabled(!smartContractInLocalStorage);
  }, [network]);

  useEffect(() => {
    if (!smartContractAbi.isSuccess) {
      return;
    }
    setWithExpiry({
      key: `smartContractAbi-${network}`,
      value: JSON.stringify(smartContractAbi.data),
      ttlInHours: 1,
    });
    setResult(smartContractAbi);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [smartContractAbi.isSuccess]);

  return result;
};
