import React from 'react';
import {untypedColors} from '../theme/tokens/colors';
import {EthereumIconProps} from './ethereum-network';

export const EthereumCurrencyIcon = React.memo<EthereumIconProps>(
  ({color = 'outlines', size = 32}) => (
    <svg
      width={size}
      height={size}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 28V25.2773H24.9412V28H8ZM10.0168 16.8067V14.084H22.9244V16.8067H10.0168ZM8.67227 6.72269V4H24.2689V6.72269H8.67227Z"
        fill={untypedColors[color] || color}
      />
    </svg>
  ),
);
