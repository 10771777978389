import React from 'react';
import {
  Pressable as ReactNativePressable,
  PressableProps as ReactNativePressableProps,
} from 'react-native';

interface ExtraPressableProps {
  onHoverIn?: (event: MouseEvent) => void;
  onHoverOut?: (event: MouseEvent) => void;
  hovered?: boolean;
  pressed?: boolean;
  // eslint-disable-next-line camelcase
  testOnly_hovered?: boolean;
  // eslint-disable-next-line camelcase
  testOnly_pressed?: boolean;
}

export type PressableProps = ReactNativePressableProps & ExtraPressableProps;

export const Pressable = ReactNativePressable as React.FC<
  ReactNativePressableProps & ExtraPressableProps
>;
