import {
  propertyToVariant,
  mapArrayToObject,
  VariantProps,
} from '../variant-utilities';

export const display = {
  ...propertyToVariant(
    'display',
    mapArrayToObject([
      'none',
      'flex',
      'contents' /* This is just for VisibilityBox on the web */,
      'block',
    ]),
  ),
};

export type DisplayProps = VariantProps<typeof display>;
