import React from 'react';
import {
  LinkRenderer,
  LinkRendererProvider,
  UnstyledHtmlAnchor,
} from '@ttx/design-system';
import NextLink from 'next/link';

export interface NextJSLinkRendererProviderProps {
  children: React.ReactNode;
}

export const renderNextJSLink: LinkRenderer = ({
  children,
  passHref = true,
  ...rest
}) => (
  /**
   * TODO:
   * - add accessibility props
   * - support external links, and with blank targets
   * */
  <NextLink {...rest} passHref={passHref}>
    <UnstyledHtmlAnchor>{children}</UnstyledHtmlAnchor>
  </NextLink>
);

export const NextJSLinkRendererProvider = React.memo(
  (props: NextJSLinkRendererProviderProps) => (
    <LinkRendererProvider {...props} renderLink={renderNextJSLink} />
  ),
);
