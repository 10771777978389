/* eslint-disable no-unused-expressions */
/* eslint-disable import/no-cycle */
import React, {useEffect, useState} from 'react';
import {Typography} from '../../typography';
import {Box} from '../../box';
import {CommonNavbarProps} from './types';
import {FrameworkLink} from '../../link-renderer';
import {TransparentButton} from '../../button/transparent-button';
import {ContentContainerBox} from '../../content-container-box';
import {NavBarInfo} from './navbar-info';
import {
  footerSocialURLs,
  isActiveNavRoute,
  matchWithRoute,
  NavBarSocialLinks,
} from './navbar-block-constants';
import {Button} from '../../button/button';
import {FrameworkImage} from '../../image-renderer';
import {STUDIO_MENUS} from '../../../../../apps/web/utils/consts';
import {NFTGhostItem} from '../../nft-ghost-item';

export interface DesktopNavbarProps extends CommonNavbarProps {
  runLogoAnimation: boolean;
  themeColor?: any;
  navLinks?: {
    title: string;
    href: string;
  }[];
}

const STORED_HOVER_STATE = {
  IS_HOVERED: false,
};

export const isActiveNavRouteUtil = (
  currentHref: string,
  subRoutes: string[],
) => {
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < subRoutes.length; i++) {
    if (currentHref.indexOf(subRoutes[i]) > -1) {
      return true;
    }
  }
  return false;
};

export const DesktopNavbar: React.FC<DesktopNavbarProps> = ({
  items,
  currentHref,
  homePageHref = '#',
  walletConnected,
  openWallet,
  openPurchaseHistory,
  runLogoAnimation,
  studioSlug,
  themeColor,
  isLoading,
  navLinks,
}) => {
  const [isHovered, setIsHovered] = useState(false);

  let height = 'calc(100% - 15px)';
  let width = '202px';
  if (studioSlug === 'here-at-last') {
    height = '120px';
    width = '160px';
  }
  if (studioSlug === 'sample-tools-by-cr2') {
    width = '180px';
  }
  return (
    <ContentContainerBox zIndex={2}>
      {isLoading ? null : (
        <Box
          width="100%"
          flexDirection="row"
          borderStyle="solid"
          height="70px"
          borderColor="textLow"
          bg="textDefault"
          borderWidth="zero"
          css={{
            'backdrop-filter': 'blur(50px)',
            'background-color': 'var(--studio-backgroundColor)',
            'border-bottom-width': '1px !important',
          }}
        >
          <Box flex="1" flexDirection="row" alignItems="center">
            <FrameworkLink href={homePageHref}>
              <Box
                w={112}
                ml="twelve"
                height="100%"
                justifyContent="center"
                mr="twelve"
                position="relative"
                style={{marginLeft: '20px'}}
              >
                <Box height={height} width={width}>
                  <FrameworkImage
                    src={themeColor?.logoUrl || ''}
                    alt="background"
                    layout="fill"
                  />
                </Box>
                {currentHref === '/' && (
                  <Box
                    borderBottomColor="outlines"
                    borderBottomStyle="solid"
                    borderBottomWidth="two"
                    position="absolute"
                    top="0px"
                    left="0px"
                    style={{height: '100%', width: '100%'}}
                  />
                )}
                {/* <TTLogoAnimation runAnimation={runLogoAnimation} /> */}
              </Box>
            </FrameworkLink>
            {navLinks?.length
              ? navLinks.map((elm) => (
                  <FrameworkLink key={elm.href} href={elm.href} target="_blank">
                    <TransparentButton key={elm.href} onPress={() => null}>
                      <Box
                        paddingX="twenty"
                        paddingY="six"
                        position="relative"
                        borderBottomColor="outlines"
                        borderBottomStyle="solid"
                        borderBottomWidth="zero"
                        style={{paddingTop: 36}}
                      >
                        <Typography color="textDefault" textStyle="h3Bold">
                          {elm.title}
                        </Typography>
                      </Box>
                    </TransparentButton>
                  </FrameworkLink>
                ))
              : null}
          </Box>

          <Box
            flexDirection="row"
            alignItems="center"
            justifyContent="flex-end"
          >
            {currentHref === '/' ||
            (currentHref && matchWithRoute(currentHref)) ? (
              <NavBarSocialLinks />
            ) : (
              <>
                {!walletConnected && (
                  <Box marginLeft="two" right="eight">
                    <Button
                      hasBorder={false}
                      type="primary"
                      hasButtonStyle
                      buttonStyle={{
                        backgroundColor: 'var(--studio-primaryButton)',
                        textColor: 'var(--studio-primaryButtonText)',
                      }}
                      onPress={openWallet}
                    >
                      Connect wallet
                    </Button>
                  </Box>
                )}
              </>
            )}
          </Box>
          {walletConnected &&
            currentHref &&
            currentHref !== '/' &&
            !matchWithRoute(currentHref) && (
              <Box
                flexDirection="row"
                alignItems="center"
                justifyContent="flex-end"
                marginLeft="two"
                right="eight"
              >
                <Button
                  hasBorder
                  onPress={openPurchaseHistory}
                  hasButtonStyle
                  buttonStyle={{
                    backgroundColor: 'var(--studio-primaryButton)',
                    textColor: 'var(--studio-primaryButtonText)',
                  }}
                  type="primary"
                >
                  Transactions
                </Button>
                <Box marginLeft="three">
                  <Button
                    hasBorder
                    onPress={openWallet}
                    hasButtonStyle
                    buttonStyle={{
                      backgroundColor: 'var(--studio-primaryButton)',
                      textColor: 'var(--studio-primaryButtonText)',
                    }}
                    type="primary"
                  >
                    My wallet
                  </Button>
                </Box>
              </Box>
            )}
        </Box>
      )}
      {isHovered && (
        <Box
          zIndex="1"
          backgroundColor="outlinesInvert"
          marginLeft="one"
          marginRight="one"
          borderBottomColor="outlines"
          borderBottomWidth="one"
          borderBottomStyle="solid"
          onHoverIn={() => {
            STORED_HOVER_STATE.IS_HOVERED = true;
            setIsHovered(true);
          }}
          onHoverOut={() => {
            STORED_HOVER_STATE.IS_HOVERED = false;
            setTimeout(() => {
              if (!STORED_HOVER_STATE.IS_HOVERED) {
                setIsHovered(false);
              }
            }, 500);
          }}
        >
          <NavBarInfo footerSocialLinks={footerSocialURLs} />
        </Box>
      )}
    </ContentContainerBox>
  );
};
