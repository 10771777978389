import React from 'react';
import {Box} from '../box';
import {TransparentButton} from '../button/transparent-button';
import {HorizontalDivider} from '../divider';
import {CloseIcon} from '../icons';
import {theme} from '../theme/theme';
import {Typography} from '../typography';

export const GridContainerWithTitle: React.FC<{
  onModalClose?: () => void;
  titleIcon?: JSX.Element;
  title: string;
  children: React.ReactNode;
  hasBottomBorder?: boolean;
  maxWidth?: number;
  backgroundColor?: any;
}> = ({
  onModalClose,
  titleIcon,
  title,
  children,
  hasBottomBorder = true,
  maxWidth = 568,
  backgroundColor = 'surfaceDark',
}) => {
  return (
    <Box
      w="100%"
      maxWidth={maxWidth}
      minWidth={['inherit', maxWidth]}
      borderColor="outlines"
      borderWidth="one"
      borderStyle="solid"
      backgroundColor={backgroundColor}
      borderBottomWidth={hasBottomBorder ? 'one' : 'zero'}
    >
      <Box flexDirection="row" alignItems="center">
        <Box flex={1} pl="md" paddingY="md">
          <Box flexDirection="row" alignItems="center">
            {!!titleIcon && <Box mr="s">{titleIcon}</Box>}
            <Typography color="textDefault" textStyle="s">
              {title}
            </Typography>
          </Box>
        </Box>
        {onModalClose && (
          <Box mr="s">
            <TransparentButton
              onPress={onModalClose}
              style={{padding: theme.space.md}}
            >
              <CloseIcon size={18} />
            </TransparentButton>
          </Box>
        )}
      </Box>
      <HorizontalDivider />
      {children}
    </Box>
  );
};
