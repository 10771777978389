import React from 'react';
import {colors} from '../theme/tokens/colors';

export interface HelpIconProps {
  color?: keyof typeof colors;
  size?: number;
}

export const HelpIcon = React.memo<HelpIconProps>(
  ({color = 'outlines', size = 18}) => (
    <svg
      width={size}
      height={size}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 16.5C4.85786 16.5 1.5 13.1421 1.5 9C1.5 4.85786 4.85786 1.5 9 1.5C13.1421 1.5 16.5 4.85786 16.5 9C16.5 13.1421 13.1421 16.5 9 16.5ZM0 9C0 4.02944 4.02944 0 9 0C13.9706 0 18 4.02944 18 9C18 13.9706 13.9706 18 9 18C4.02944 18 0 13.9706 0 9ZM10.4503 9.03491C9.77279 9.5864 9.52068 10.2009 9.52068 11.3197H8.14982C8.14982 9.9173 8.40193 9.08218 9.694 8.02647L10.0879 7.69557C10.6237 7.27013 11.1279 6.67137 11.1279 5.86776C11.1279 4.89083 10.4188 4.1345 9.18978 4.1345C7.78741 4.1345 6.95229 5.09567 6.95229 6.6241H5.54993C5.54993 4.3551 7.07835 3 9.18978 3C11.1752 3 12.5618 4.27631 12.5618 5.77322C12.5618 7.06529 11.8685 7.90041 10.8758 8.68826L10.4503 9.03491ZM9.66249 12.5645V14.282H8.03952V12.5645H9.66249Z"
        fill={colors[color] || color}
      />
    </svg>
  ),
);
