import React from 'react';
import {colors} from '../theme/tokens/colors';

export interface ActivityIndicatorProps {
  size?: number;
  color?: 'brandPrimary' | 'outlines' | 'outlinesInvert';
  duration?: number;
  loaderColor?: 'brandPrimary' | 'outlines' | 'outlinesInvert' | null;
}

export const ActivityIndicator = React.memo<ActivityIndicatorProps>(
  ({size = 12, color = 'brandPrimary', duration = 0.8, loaderColor = null}) => {
    const fillOptions = {
      brandPrimary: '742_211545',
      outlines: '8349_4965',
      outlinesInvert: '#010203',
    };

    return (
      <svg
        width={size}
        height={size}
        viewBox="0 0 12 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12 6C12 7.26708 11.5989 8.50163 10.8541 9.52671C10.1093 10.5518 9.05916 11.3148 7.8541 11.7063C6.64904 12.0979 5.35096 12.0979 4.1459 11.7063C2.94084 11.3148 1.89067 10.5518 1.1459 9.52671C0.40113 8.50163 -1.10771e-07 7.26708 0 6C1.10771e-07 4.73292 0.40113 3.49837 1.1459 2.47329C1.89067 1.4482 2.94084 0.685208 4.1459 0.293661C5.35096 -0.0978871 6.64904 -0.0978869 7.8541 0.293661L7.59453 1.09255C6.55818 0.755817 5.44182 0.755817 4.40547 1.09255C3.36912 1.42928 2.46597 2.08545 1.82547 2.96703C1.18497 3.8486 0.84 4.91031 0.84 6C0.84 7.08968 1.18497 8.1514 1.82547 9.03297C2.46597 9.91455 3.36912 10.5707 4.40547 10.9075C5.44182 11.2442 6.55818 11.2442 7.59453 10.9075C8.63088 10.5707 9.53403 9.91455 10.1745 9.03297C10.815 8.1514 11.16 7.08968 11.16 6H12Z"
          fill={
            loaderColor
              ? colors[loaderColor]
              : `url(#paint0_linear_${fillOptions[color]})`
          }
        />
        <animateTransform
          attributeName="transform"
          attributeType="XML"
          type="rotate"
          from="0"
          to="360"
          dur={`${duration}s`}
          repeatCount="indefinite"
        />
        <defs>
          <linearGradient
            id={`paint0_linear_${fillOptions[color]}`}
            x1="6"
            y1="-1.71429"
            x2="15.4286"
            y2="6"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.442708" stopColor={colors[color] || color} />
            <stop
              offset="1"
              stopColor={colors[color] || color}
              stopOpacity="0.12"
            />
          </linearGradient>
        </defs>
      </svg>
    );
  },
);
