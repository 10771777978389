import React from 'react';
import {colors} from '../theme/tokens/colors';

export interface CloseIconProps {
  color?: keyof typeof colors;
  size?: number;
}

export const CloseIcon = React.memo<CloseIconProps>(
  ({color = 'outlines', size = 32}) => (
    <svg
      width={size}
      height={size}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <line
        x1="7.70711"
        y1="8.29289"
        x2="24.6777"
        y2="25.2635"
        stroke={colors[color] || color}
        strokeWidth="2"
      />
      <line
        x1="24.7071"
        y1="7.70711"
        x2="7.73654"
        y2="24.6777"
        stroke={colors[color] || color}
        strokeWidth="2"
      />
    </svg>
  ),
);
