import React from 'react';
import {colors} from '../theme/tokens/colors';

export interface PauseIconProps {
  color?: keyof typeof colors;
  size?: number;
}

export const PauseIcon = React.memo<PauseIconProps>(
  ({color = 'surfaceDark', size = 12}) => (
    <svg
      width={size}
      height={size}
      viewBox="0 0 7 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M2 0L2 12H0L0 0" fill={colors[color] || color} />
      <path d="M7 0L7 12H5L5 0" fill={colors[color] || color} />
    </svg>
  ),
);
