import {WindowWithMaybeZilPay, WindowZilPay, Zilliqa} from './types';

export const isZilPayProvider = (
  providerLike: Record<string, any>,
): providerLike is ZilPayProvider => {
  if (!providerLike.blockchain) return false;
  if (!providerLike.contracts) return false;
  if (!providerLike.network) return false;
  if (!providerLike.provider) return false;
  if (!providerLike.subscriptionBuilder) return false;
  if (!providerLike.transactions) return false;
  if (!providerLike.wallet) return false;

  return true;
};

const ACCOUNT_NOT_CREATED_ERROR = 'Account is not created';
export class ZilPayProvider {
  public provider: Zilliqa;

  public browserProvider: WindowZilPay;

  constructor(provider: Zilliqa) {
    this.provider = provider;
    this.browserProvider = (window as WindowWithMaybeZilPay).zilPay as any;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  public getSigner() {
    return {
      signMessage: async (message: string) =>
        this.browserProvider.wallet.sign(message),
    };
  }

  public async getBalance(): Promise<string> {
    let balance = '0';
    try {
      const response = await this.browserProvider.blockchain.getBalance(
        this.browserProvider.wallet.defaultAccount.bech32,
      );

      /**
       * If we don't get a response back from the server, we'll assume the balance is 0...
       */
      if (!response) return balance;

      balance = response.result.balance;
    } catch (e) {
      /**
       * If the account has not transacted on the chain yet, then an error will
       * be thrown. We can safely ignore this error, and surface zero balance to
       * the user.
       *
       * @see https://github.com/Zilliqa/Zilliqa/issues/2626
       */
      if ((e as Error).message !== ACCOUNT_NOT_CREATED_ERROR) throw e;
    }
    return balance;
  }
}
