import React from 'react';
import {colors} from '../theme/tokens/colors';

export interface ArrowDownIconProps {
  color?: keyof typeof colors;
  size?: number;
}

export const ArrowDownIcon = React.memo<ArrowDownIconProps>(
  ({color = 'textLow', size = 10}) => (
    <svg
      width={size}
      height={size}
      viewBox="0 0 10 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.928 8.918L9.618 0H0.223999L4.928 8.918Z"
        fill={colors[color] || color}
      />
    </svg>
  ),
);
