import {
  propertyToVariant,
  propertyShorthandToVariant,
  VariantProps,
} from '../variant-utilities';
import {theme} from '../../theme';

export const space = {
  // Margin
  ...propertyToVariant('marginTop', theme.space),
  ...propertyToVariant('marginBottom', theme.space),
  ...propertyToVariant('marginLeft', theme.space),
  ...propertyToVariant('marginRight', theme.space),

  ...propertyShorthandToVariant('mt', ['marginTop'], theme.space),
  ...propertyShorthandToVariant('mb', ['marginBottom'], theme.space),
  ...propertyShorthandToVariant('ml', ['marginLeft'], theme.space),
  ...propertyShorthandToVariant('mr', ['marginRight'], theme.space),

  ...propertyShorthandToVariant(
    'marginX',
    ['marginLeft', 'marginRight'],
    theme.space,
  ),
  ...propertyShorthandToVariant(
    'mx',
    ['marginLeft', 'marginRight'],
    theme.space,
  ),
  ...propertyShorthandToVariant(
    'marginY',
    ['marginTop', 'marginBottom'],
    theme.space,
  ),
  ...propertyShorthandToVariant(
    'my',
    ['marginTop', 'marginBottom'],
    theme.space,
  ),

  ...propertyShorthandToVariant(
    'margin',
    ['marginTop', 'marginRight', 'marginBottom', 'marginLeft'],
    theme.space,
  ),
  ...propertyShorthandToVariant(
    'm',
    ['marginTop', 'marginRight', 'marginBottom', 'marginLeft'],
    theme.space,
  ),

  // Padding
  ...propertyToVariant('paddingTop', theme.space),
  ...propertyToVariant('paddingBottom', theme.space),
  ...propertyToVariant('paddingLeft', theme.space),
  ...propertyToVariant('paddingRight', theme.space),

  ...propertyShorthandToVariant('pt', ['paddingTop'], theme.space),
  ...propertyShorthandToVariant('pb', ['paddingBottom'], theme.space),
  ...propertyShorthandToVariant('pl', ['paddingLeft'], theme.space),
  ...propertyShorthandToVariant('pr', ['paddingRight'], theme.space),

  ...propertyShorthandToVariant(
    'paddingX',
    ['paddingLeft', 'paddingRight'],
    theme.space,
  ),
  ...propertyShorthandToVariant(
    'paddingY',
    ['paddingTop', 'paddingBottom'],
    theme.space,
  ),

  ...propertyShorthandToVariant(
    'padding',
    ['paddingTop', 'paddingRight', 'paddingBottom', 'paddingLeft'],
    theme.space,
  ),
  ...propertyShorthandToVariant(
    'p',
    ['paddingTop', 'paddingRight', 'paddingBottom', 'paddingLeft'],
    theme.space,
  ),
};

export type SpaceProps = VariantProps<typeof space>;
