import React from 'react';
import {colors, untypedColors} from '../theme/tokens/colors';

export interface UsdtIconProps {
  color?: keyof typeof colors | (string & Record<string, unknown>);
  size?: number;
}

export const UsdtIcon = React.memo<UsdtIconProps>(
  ({color = 'outlines', size = 32}) => (
    <svg
      width={size}
      height={size}
      viewBox="0 0 21 20"
      fill={untypedColors[color] ?? color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.5 17.8125C14.9873 17.8125 18.625 14.1748 18.625 9.6875C18.625 5.20019 14.9873 1.5625 10.5 1.5625C6.01269 1.5625 2.375 5.20019 2.375 9.6875C2.375 14.1748 6.01269 17.8125 10.5 17.8125ZM10.5118 10.4739C11.1503 10.4739 11.4365 10.4568 11.4919 10.4527L11.4901 10.4522C13.4489 10.3654 14.9101 10.0238 14.9101 9.61567C14.9101 9.20756 13.4485 8.86592 11.4901 8.77866V10.1129C11.4351 10.118 11.13 10.1434 10.5206 10.1434C10.0127 10.1434 9.64523 10.1217 9.51689 10.1124V8.77774C7.55433 8.86546 6.08945 9.20663 6.08945 9.61567C6.08945 10.0247 7.55433 10.3663 9.51689 10.4527C9.64338 10.4587 10.0039 10.4739 10.5118 10.4739ZM11.4919 8.64018V8.64063L11.4914 8.64016C11.4916 8.64017 11.4918 8.64018 11.4919 8.64018ZM11.4919 7.44629V8.64018C13.7091 8.74272 15.375 9.1822 15.375 9.70893C15.375 10.2357 13.7075 10.6752 11.4914 10.7772V14.6059H9.51642V10.7786C7.29533 10.6761 5.62501 10.2366 5.62501 9.70939C5.62501 9.18216 7.29533 8.74219 9.51642 8.64016V7.44629H6.7838V5.625H14.225V7.44629H11.4919Z"
      />
    </svg>
  ),
);
