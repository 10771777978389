import React from 'react';
import {colors} from '../theme/tokens/colors';

export interface EmaiLoginIconProps {
  color?: keyof typeof colors;
  size?: number;
}

export const EmaiLoginIcon = React.memo<EmaiLoginIconProps>(
  ({color = 'textLow', size = 10}) => (
    <svg
      width={size}
      height={size}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.58594 17.4375C9.34375 17.4375 11.0781 16.8024 12.2734 15.8044L11.5469 14.7157C10.4219 15.5549 9.29688 16.0086 7.63281 16.0086C4.16406 16.0086 1.84375 13.4002 1.84375 9.18145C1.84375 4.71321 4.35156 2.01411 7.63281 2.01411C10.7266 2.01411 12.7656 4.3503 12.7656 8.11542C12.7656 10.4289 12.1328 12.13 11.0078 12.13C10.3984 12.13 10.1641 11.6764 10.2344 10.7238L10.75 4.91734H9.29687L9.20312 5.8246C8.89844 5.23488 8.26562 4.66784 7.25781 4.66784C5.38281 4.66784 3.71875 6.5504 3.71875 9.49899C3.71875 11.9713 5.05469 13.4456 6.71875 13.4456C7.70312 13.4456 8.57031 12.9693 9.08594 12.1074C9.36719 13.0373 10.0703 13.4456 10.9375 13.4456C12.9297 13.4456 14.3125 11.3135 14.3125 8.11542C14.3125 3.55645 11.5703 0.5625 7.60937 0.5625C3.48437 0.5625 0.25 3.89667 0.25 9.15877C0.25 14.3528 3.32031 17.4375 7.58594 17.4375ZM6.95312 12.1527C5.89844 12.1527 5.28906 11.1094 5.28906 9.47631C5.28906 7.50302 6.13281 6.00605 7.35156 6.00605C8.28906 6.00605 8.96875 6.95867 8.96875 8.6371C8.96875 10.7692 8.21875 12.1527 6.95312 12.1527Z"
        fill={colors[color] || color}
      />
    </svg>
  ),
);
