import {propertyToVariant, VariantProps} from '../variant-utilities';
import {theme} from '../../theme';

export const border = {
  ...propertyToVariant('borderWidth', theme.borderWidths),
  ...propertyToVariant('borderStyle', theme.borderStyles),
  ...propertyToVariant('borderRadius', theme.radii),
  ...propertyToVariant('borderColor', theme.colors),

  ...propertyToVariant('borderTopWidth', theme.borderWidths),
  ...propertyToVariant('borderTopStyle', theme.borderStyles),
  ...propertyToVariant('borderTopColor', theme.colors),

  ...propertyToVariant('borderRightWidth', theme.borderWidths),
  ...propertyToVariant('borderRightStyle', theme.borderStyles),
  ...propertyToVariant('borderRightColor', theme.colors),

  ...propertyToVariant('borderTopRightRadius', theme.radii),

  ...propertyToVariant('borderBottomWidth', theme.borderWidths),
  ...propertyToVariant('borderBottomStyle', theme.borderStyles),
  ...propertyToVariant('borderBottomColor', theme.colors),

  ...propertyToVariant('borderBottomRightRadius', theme.radii),

  ...propertyToVariant('borderLeftWidth', theme.borderWidths),
  ...propertyToVariant('borderLeftStyle', theme.borderStyles),
  ...propertyToVariant('borderLeftColor', theme.colors),

  ...propertyToVariant('borderTopLeftRadius', theme.radii),
  ...propertyToVariant('borderBottomLeftRadius', theme.radii),
};

export type BorderProps = VariantProps<typeof border>;
