import React from 'react';
import {colors} from '../theme/tokens/colors';

export interface TwitterIconProps {
  color?: keyof typeof colors;
  size?: number;
}

export const TwitterIcon = React.memo<TwitterIconProps>(
  ({color = 'textInvert', size = 18}) => (
    <svg
      width={size}
      height={size}
      viewBox="0 0 19 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1482_8430)">
        <path
          d="M5.69183 12.5369C3.89607 12.3203 2.69377 11.4522 2.04131 9.71754H3.62842C1.80273 9.00019 0.788547 7.71633 0.648307 5.7046L2.27304 6.16676L2.33205 6.05977C1.55217 5.41959 0.994632 4.63823 0.792823 3.62447C0.582773 2.64875 0.735234 1.62776 1.22038 0.761222C3.36418 3.31929 6.03131 4.72242 9.30815 4.96621C9.30815 4.39619 9.22263 3.83494 9.32183 3.30877C9.44386 2.6052 9.74706 1.94788 10.2001 1.40488C10.653 0.861868 11.2393 0.452916 11.8982 0.220374C12.5571 -0.0121684 13.2647 -0.0598483 13.9476 0.0822716C14.6305 0.224391 15.2639 0.551144 15.7823 1.02869C16.0149 1.23741 16.215 1.26898 16.4613 1.17953C17.1385 0.932227 17.8106 0.670018 18.4965 0.409563C18.2297 1.25495 17.6798 1.91178 16.9906 2.51425C17.3095 2.44672 17.6319 2.39411 17.9449 2.30817C18.2724 2.22047 18.5905 2.09594 18.9958 1.95738C18.6056 2.61405 18.0982 3.18944 17.5002 3.65341C17.2249 3.87616 17.1214 4.09189 17.1206 4.44968C17.1043 9.49216 13.8668 14.5346 8.48381 15.7133C5.60289 16.3447 2.85795 15.9439 0.289154 14.4277C0.203641 14.3794 0.132668 14.3154 -0.00244141 14.2163C2.11313 14.3733 3.96363 13.8471 5.69183 12.5369Z"
          fill={colors[color] || color}
        />
      </g>
      <defs>
        <clipPath id="clip0_1482_8430">
          <rect width="19" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  ),
);
