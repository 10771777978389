import {colors} from './tokens/colors';
import {sizes} from './tokens/sizes';
import {space} from './tokens/space';

export const theme = {
  colors,
  sizes,
  space,
  fontSizes: {
    hero: '100px',
    heroSmall: '48px',
    lead: '20px',
    h1: '60px',
    h2: '30px',
    h3: '32px',
    m: '18px',
    xm: '16px',
    s: '14px',
    xs: '12px',
  },
  fonts: {
    altone: 'Altone, apple-system, sans-serif',
    abc: 'ABC Diatype Semi Mono, apple-system, sans-serif',
    abcHeading: 'ABC Diatype, apple-system, sans-serif',
    times: 'Times New Roman, Georgia, Garamond',
  },
  fontWeights: {
    regular: 400,
    bold: 700,
  },
  lineHeights: {
    hero: '90px',
    h1: '60px',
    h2: '30px',
    heroSmall: '48px',
    biglead: '28px',
    lead: '25.02px',
    s: '18px',
    normal: 'normal',
  },
  letterSpacings: {
    hero: '-0.02em',
    heroSmall: '-0.02em',
  },
  borderWidths: {
    zero: '0px',
    one: '1px',
    two: '2px',
  },
  borderStyles: {
    solid: 'solid',
  },
  radii: {
    zero: '0px',
    one: '4px',
    two: '8px',
    three: '12px',
    four: '16px',
    circle: '50%',
    customFifty: '50px',
  },
  shadows: {
    highlightPlusShadow:
      '-5px -5px 10px rgba(255, 255, 255, 0.05), 5px 5px 10px #000000',
    toastShadow: '0px 5px 10px rgba(0, 0, 0, 0.4)',
    buttonShadow: '-1px -1px 2px rgba(255, 255, 255, 0.1), 2px 2px 4px #000000',
    playPauseButtonShadow:
      '0px 4px 4px rgba(0, 0, 0, 0.25), inset 3px 3px 6px rgba(255, 255, 255, 0.15), inset 3px 3px 6px rgba(0, 0, 0, 0.4)',
    embossShadowNull:
      'inset 0px 0px 0px 0px rgba(0, 0, 0, 0), inset 0px 0px 0px 0px rgba(255, 255, 255, 0)',
    embossShadowText:
      'inset -22px -22px 16px -16px rgba(0, 0, 0, 0.4), inset 22px 0px 16px -16px  rgba(255, 255, 255, 0.1)',
    embossShadow:
      'inset -6px -6px 16px 0px rgba(0, 0, 0, 0.3), inset 6px 6px 16px 0px rgba(255, 255, 255, 0.1)',
    embossShadowLarge:
      'box-shadow: inset -12px -12px 24px rgba(0, 0, 0, 0.25), inset 12px 12px 24px rgba(255, 255, 255, 0.15)',
    embossFilterNull: 'drop-shadow(0px 0px 0px rgba(0, 0, 0, 0))',
    embossFilterText: 'drop-shadow(0px 6px 18px rgba(0, 0, 0, 0.7))',
    embossFilter: 'drop-shadow(0px 4px 12px rgba(0, 0, 0, 0.5))',
    embossFilterLarge: 'drop-shadow(0px 12px 48px rgba(0, 0, 0, 0.2))',
  },
  zIndices: {},
  breakpoints: ['768px', '960px', '1200px', '1440px'],
} as const;

export type Theme = typeof theme;
