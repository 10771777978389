import React from 'react';
import {colors} from '../theme/tokens/colors';

export interface InfoIconProps {
  color?: keyof typeof colors;
  size?: number;
}

export const InfoIcon = React.memo<InfoIconProps>(
  ({color = 'outlines', size = 32}) => (
    <svg
      width={size}
      height={size}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.3333 12.0002L14.6666 12.0002L14.6666 9.33349L17.3333 9.3335L17.3333 12.0002ZM17.3333 22.6668L14.6666 22.6668L14.6666 14.6668L17.3333 14.6668L17.3333 22.6668ZM16.0133 29.3335C23.3733 29.3335 29.3333 23.3602 29.3333 16.0002C29.3333 8.64016 23.3733 2.66683 16.0133 2.66683C8.63992 2.66683 2.66659 8.64016 2.66659 16.0002C2.66658 23.3602 8.63992 29.3335 16.0133 29.3335ZM15.9999 5.33349C21.8933 5.3335 26.6666 10.1068 26.6666 16.0002C26.6666 21.8935 21.8933 26.6668 15.9999 26.6668C10.1066 26.6668 5.33325 21.8935 5.33325 16.0002C5.33325 10.1068 10.1066 5.33349 15.9999 5.33349Z"
        fill={colors[color] || color}
      />
    </svg>
  ),
);
