import React from 'react';
import {AppProps} from 'next/app';
import Script from 'next/script';
import PlausibleProvider from 'next-plausible';
import {TokenTraxxThemeProvider} from '@ttx/design-system';
import {NextComponentType, NextPageContext} from 'next/types';
import Head from 'next/head';
import {useRouter} from 'next/router';
import {PrivyProvider} from '@privy-io/react-auth';
import {mainnet, sepolia, polygon, polygonAmoy} from 'viem/chains';
import {RightSideDrawerProvider} from '../components/right-side-drawer';
import {TokenTraxxQueryProvider} from '../contexts/token-traxx-query-provider';
import {AuthenticationContextProvider} from '../contexts/authentication-context';
import {ClientSideWalletConnector} from '../components/client-side-wallet-connector';
import {ToastContextProvider} from '../contexts/toast-context';
import {NextJSLinkRendererProvider} from '../contexts/nextjs-link-renderer/nextjs-link-renderer';
import {PageLayout} from '../components/page-layout';
import {NextJSImageRendererProvider} from '../contexts/nextjs-image-renderer/nextjs-image-renderer';
import {SmartContractProvider} from '../contexts/smart-contract';
import {AnalyticsContextProvider} from '../contexts/analytics-context';
import {LifiWidgetProvider} from '../contexts/lifi-context';
import MaintenancePage from './page-under-maintenance.page';
import {usePageViewEffect} from '../contexts/analytics-context/use-pageview-effect';
import {ENVIRONMENT} from '../contexts/authentication-context/connectors';
import {NO_HEADER, STUDIOS_PAGES, WEBFLOW_PAGES} from '../utils/consts';

import './custom-circle-widget.css';
import Hubspot from './hubspot';
import {PolymeshProvider} from '../contexts/polymesh-context';

type AppPropsWithMaybeComponentPageLayoutOptions<P = Record<string, unknown>> =
  AppProps<P> & {
    Component: NextComponentType<NextPageContext, any, P> & {
      pageLayoutOptions?: {
        showRightSideDrawerButtonOverContent?: boolean;
      };
    };
  };

const App: React.FC<AppPropsWithMaybeComponentPageLayoutOptions> = ({
  Component,
  pageProps,
}) => {
  const UNDER_MAINTENANCE = false;

  const {pageLayoutOptions} = Component;

  usePageViewEffect();
  const router = useRouter();
  const {route} = router;
  const isStudioPage = STUDIOS_PAGES.includes(router.pathname);
  const lifiWidgetVisiblePages = [
    '/studio/nft/[slug]/[id]',
    '/tracks/[id]',
  ].includes(router.pathname);
  return (
    <>
      <Head>
        <link rel="icon" type="image/x-icon" href="/favicon.png" />
      </Head>
      <Script src="https://embed.typeform.com/next/embed.js" />
      {isStudioPage && <div className="studio-loading" id="studio-loading" />}
      <PlausibleProvider
        domain={
          ENVIRONMENT === 'production'
            ? 'tokentraxx.com'
            : 'app.testnet.tokentraxx.com'
        }
      >
        <TokenTraxxThemeProvider>
          <NextJSLinkRendererProvider>
            <NextJSImageRendererProvider>
              <ToastContextProvider isStudioPage={isStudioPage}>
                <TokenTraxxQueryProvider>
                  <PrivyProvider
                    appId={process.env.NEXT_PUBLIC_PRIVY_APP_ID || ''}
                    config={{
                      loginMethods: ['email'],
                      embeddedWallets: {
                        createOnLogin: 'users-without-wallets',
                      },
                      defaultChain:
                        ENVIRONMENT === 'production' ? polygon : polygonAmoy,
                      supportedChains: [mainnet, sepolia, polygon, polygonAmoy],
                    }}
                  >
                    <ClientSideWalletConnector>
                      <AuthenticationContextProvider>
                        <LifiWidgetProvider
                          isWidgetVisible={lifiWidgetVisiblePages}
                        >
                          <SmartContractProvider>
                            {NO_HEADER.includes(route) ? (
                              <Component {...pageProps} />
                            ) : (
                              <RightSideDrawerProvider
                                isStudioPage={isStudioPage}
                              >
                                <AnalyticsContextProvider>
                                  {UNDER_MAINTENANCE ? (
                                    <PageLayout
                                      key={router.asPath}
                                      {...pageLayoutOptions}
                                      showNavbar={false}
                                    >
                                      <MaintenancePage {...pageProps} />
                                    </PageLayout>
                                  ) : (
                                    <PolymeshProvider>
                                      <PageLayout
                                        key={router.asPath}
                                        {...pageLayoutOptions}
                                        showNavbar={
                                          !WEBFLOW_PAGES.includes(route)
                                        }
                                      >
                                        <Component {...pageProps} />
                                      </PageLayout>
                                    </PolymeshProvider>
                                  )}
                                </AnalyticsContextProvider>
                              </RightSideDrawerProvider>
                            )}
                          </SmartContractProvider>
                        </LifiWidgetProvider>
                      </AuthenticationContextProvider>
                    </ClientSideWalletConnector>
                  </PrivyProvider>
                </TokenTraxxQueryProvider>
              </ToastContextProvider>
            </NextJSImageRendererProvider>
          </NextJSLinkRendererProvider>
        </TokenTraxxThemeProvider>
      </PlausibleProvider>
      <Hubspot />
    </>
  );
};

export default App;
