import React from 'react';
import {Box} from '.';
import {BoxProps} from './types';

/**
 * The array-style values should be: number of breakpoints in the design-system + 1
 */
type ShowProp =
  | boolean
  | [boolean]
  | [boolean, boolean]
  | [boolean, boolean, boolean]
  | [boolean, boolean, boolean, boolean]
  | [boolean, boolean, boolean, boolean, boolean];

const mapBooleanToDisplayValue = (booleanValue: ShowProp) => {
  if (typeof booleanValue === 'boolean') {
    return booleanValue ? 'contents' : 'none';
  }
  return booleanValue.map((booleanValueAt) =>
    booleanValueAt ? 'contents' : 'none',
  );
};

type SomeBoxProps = Omit<BoxProps, 'display'>;

export interface VisibilityBoxProps extends SomeBoxProps {
  /**
   * Whether or not to show the component. This can be defined per breakpoint.
   *
   * @example
   * <VisibilityBox show={true} /> // will show on all breakpoints
   * <VisibilityBox show={[true]} /> // equivalent to the above
   * <VisibilityBox show={[true, false]} /> // show on mobile, hide on rest
   */
  style?: any;
  show: ShowProp;
  children?: BoxProps['children'];
}

export const VisibilityBox = React.memo(
  ({show, children, ...rest}: VisibilityBoxProps) => (
    <Box {...(rest as any)} display={mapBooleanToDisplayValue(show)}>
      {children}
    </Box>
  ),
);
