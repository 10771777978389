import React from 'react';
import {colors} from '../theme/tokens/colors';

export interface TickIconProps {
  color?: keyof typeof colors;
  size?: number;
}

export const TickIcon = React.memo<TickIconProps>(
  ({color = 'brandPrimary', size = 18}) => (
    <svg
      width={size}
      height={size}
      viewBox="0 0 12 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.75909 8.32L1.16109 3.672L0.223086 4.414L4.74509 10.21L11.8431 0.983999L10.9331 0.255999L4.75909 8.32Z"
        fill={colors[color] || color}
      />
    </svg>
  ),
);
