/* eslint-disable import/no-extraneous-dependencies */
import React, {useState} from 'react';
import {useSpring} from '@ttx/design-system';
import {AnimatedBox, Box} from '../box';
import {TransparentButton} from '../button/transparent-button';
import {ContentContainerBox} from '../content-container-box';
import {CloseIcon, RadioOffIcon} from '../icons';
import {UnstyledHtmlAnchor} from '../link-renderer';
import {Typography} from '../typography';
import {VisibilityBox} from '../box/visibility-box';
import {Pressable} from '../pressable';

export interface CookieBannerProps {
  privacyPolicyURL: string;
  onAcceptPress: () => void;
  onClosePress: () => void;
  visible: boolean;
}

export const CookieBanner: React.FC<CookieBannerProps> = ({
  privacyPolicyURL,
  onAcceptPress,
  onClosePress,
  visible,
}: CookieBannerProps) => {
  const [acceptHovered, setAcceptHovered] = useState(false);

  const fadeStyle = useSpring({
    opacity: visible ? 1 : 0,
    config: {
      duration: 200,
    },
  });

  const FadeStyle = useSpring({
    background: visible ? 'rgba(1, 2, 3, 0.5)' : 'rgba(1, 2, 3, 0)',
    backdropFilter: visible ? 'blur(20px)' : 'blur(0px)',
    config: {
      duration: 300,
    },
  });

  return (
    <ContentContainerBox zIndex={1}>
      <AnimatedBox
        position="absolute"
        left={0}
        right={0}
        borderWidth="one"
        borderStyle="solid"
        borderColor="outlines"
        borderTopWidth="zero"
        backgroundColor="textInvert"
        style={fadeStyle}
      >
        <AnimatedBox
          flexDirection={['column', 'row']}
          flex="1"
          justifyContent="space-between"
          alignItems="center"
          paddingRight="lg"
          paddingLeft="three"
          pt="five"
          pb={['lg', 'six']}
          style={FadeStyle}
        >
          <Box
            flex="1"
            flexDirection="row"
            alignItems={['flex-start', 'center']}
          >
            <Box mt={['xs', 'zero']}>
              <RadioOffIcon size={12} />
            </Box>
            <Box
              ml="two"
              flexDirection="row"
              alignItems="center"
              flex="1"
              pr="two"
            >
              <Typography textStyle="s" color="textDefault">
                We use cookies to improve your experience and deliver
                personalized content.{' '}
                <UnstyledHtmlAnchor href={privacyPolicyURL} target="_blank">
                  <Typography
                    textStyle="s"
                    color="textDefault"
                    textDecoration="underline"
                  >
                    Privacy policy
                  </Typography>
                </UnstyledHtmlAnchor>
              </Typography>
            </Box>
          </Box>
          <Box
            flexDirection={['column', 'row']}
            alignItems="center"
            width={['100%', 'auto']}
            mt={['six', 'zero']}
          >
            <Box
              mr={['zero', 'five']}
              mb={['two', 'zero']}
              width={['100%', 'auto']}
            >
              <Pressable
                onPress={onAcceptPress}
                onHoverIn={() => setAcceptHovered(true)}
                onHoverOut={() => setAcceptHovered(false)}
              >
                <Box
                  width="100%"
                  borderWidth="one"
                  borderStyle="solid"
                  borderColor="outlines"
                  paddingY="s"
                  paddingX={['zero', 'nine']}
                  alignItems="center"
                >
                  <Typography
                    color={acceptHovered ? 'outlines' : 'textLow'}
                    textStyle="s"
                  >
                    Accept
                  </Typography>
                </Box>
              </Pressable>
            </Box>
            <VisibilityBox show={[true, false]}>
              <Box width="100%">
                <Pressable onPress={onClosePress}>
                  <Box
                    borderWidth="one"
                    borderStyle="solid"
                    borderColor="outlines"
                    paddingY="s"
                    paddingX={['zero', 'nine']}
                    alignItems={['center', 'flex-start']}
                  >
                    <Typography color="textLow" textStyle="s">
                      Decline
                    </Typography>
                  </Box>
                </Pressable>
              </Box>
            </VisibilityBox>
            <VisibilityBox show={[false, true]}>
              <TransparentButton onPress={onClosePress}>
                <CloseIcon size={14} color="textDefault" />
              </TransparentButton>
            </VisibilityBox>
          </Box>
        </AnimatedBox>
      </AnimatedBox>
    </ContentContainerBox>
  );
};
