import React from 'react';
import {colors} from '../theme/tokens/colors';

export interface ArrowRightIconProps {
  color?: keyof typeof colors;
  size?: number;
  customColor?: string;
}

export const ArrowRightIcon = React.memo<ArrowRightIconProps>(
  ({color = 'surfaceDark', size = 8, customColor}) => (
    <svg
      width={size}
      height={size}
      viewBox="0 0 10 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.544 7.866L9.394 4.016L5.544 0.152H4.018L7.322 3.442H0.392V4.59H7.322L4.018 7.866H5.544Z"
        fill={customColor || colors[color] || color}
      />
    </svg>
  ),
);
