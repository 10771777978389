import React, {forwardRef} from 'react';
import {Box} from '../box';
import {CONTENT_MAX_WIDTH} from '../../../../apps/web/utils/consts';

type ContentContainerBoxProps = Omit<
  React.ComponentProps<typeof Box>,
  'width' | 'maxWidth' | 'paddingX'
>;

export const ContentContainerBox = forwardRef<
  typeof Box,
  ContentContainerBoxProps
>((props, ref) => (
  <Box
    {...(props as any)}
    ref={ref as any}
    width={CONTENT_MAX_WIDTH}
    maxWidth="100%"
    style={{alignSelf: 'center'}}
  />
));
