/* eslint-disable class-methods-use-this */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {PaperEmbeddedWalletSdk} from '@paperxyz/embedded-wallet-service-sdk';

export const isPaperProvider = (
  providerLike: Record<string, any>,
): providerLike is PaperProvider => {
  if (!providerLike.clientId) return false;

  return true;
};

export class PaperProvider {
  public provider: PaperEmbeddedWalletSdk;
  constructor(provider: PaperEmbeddedWalletSdk) {
    this.provider = provider;
  }
  public getSigner() {
    return {
      signMessage: async (): Promise<string> => {
        return '';
      },
    };
  }

  public getBalance() {
    return '0';
  }
}
