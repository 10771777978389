import {ViewStyle} from 'react-native';
import {
  propertyToVariant,
  mapArrayToObject,
  VariantProps,
} from '../variant-utilities';

export const flexbox = {
  ...propertyToVariant(
    'alignItems',
    mapArrayToObject<NonNullable<ViewStyle['alignItems']>>([
      'flex-start',
      'flex-end',
      'center',
      'stretch',
      'baseline',
    ]),
  ),
  ...propertyToVariant(
    'justifyContent',
    mapArrayToObject<NonNullable<ViewStyle['justifyContent']>>([
      'flex-start',
      'flex-end',
      'center',
      'space-between',
      'space-around',
      'space-evenly',
    ]),
  ),
  ...propertyToVariant(
    'flexWrap',
    mapArrayToObject<NonNullable<ViewStyle['flexWrap']>>([
      'wrap',
      'nowrap',
      'wrap-reverse',
    ]),
  ),
  ...propertyToVariant(
    'flexDirection',
    mapArrayToObject<NonNullable<ViewStyle['flexDirection']>>([
      'row',
      'column',
      'row-reverse',
      'column-reverse',
    ]),
  ),
};

export type FlexboxProps = VariantProps<typeof flexbox>;
