import React from 'react';
import {Box} from '../box';
import {Pressable, PressableProps} from '../pressable';
import {DISABLED_BUTTON_OPACITY} from './button';
import {InputState, useInteractionState} from './use-interaction-state';

const HOVERED_TOUCHABLE_OPACITY = 0.7;

export interface TransparentButtonProps extends PressableProps {
  onPress: () => any;
  children: React.ComponentProps<typeof Box>['children'];
  disabled?: boolean;
}

export const TransparentButton = React.memo(
  ({
    children,
    onPress,
    disabled,
    accessibilityRole = 'button',
    ...rest
  }: TransparentButtonProps) => {
    const {
      state,
      events: {onHoverIn, onHoverOut, onPressIn, onPressOut},
    } = useInteractionState();

    return children ? (
      <Pressable
        {...rest}
        hovered={state === InputState.Hovering}
        pressed={state === InputState.Pressed}
        onPress={onPress}
        disabled={disabled}
        onHoverIn={onHoverIn}
        onHoverOut={onHoverOut}
        onPressIn={onPressIn}
        onPressOut={onPressOut}
        accessibilityRole={accessibilityRole}
        accessibilityState={{
          disabled: !!disabled,
          busy: undefined, // TODO: implement if/when buttons have a loading state
          checked: false,
          expanded: false,
          selected: state === InputState.Pressed,
        }}
      >
        <Box
          opacity={
            // eslint-disable-next-line no-nested-ternary
            disabled
              ? DISABLED_BUTTON_OPACITY
              : // eslint-disable-next-line no-nested-ternary
              state === InputState.Hovering
              ? HOVERED_TOUCHABLE_OPACITY
              : state === InputState.Pressed
              ? DISABLED_BUTTON_OPACITY
              : undefined
          }
        >
          {children}
        </Box>
      </Pressable>
    ) : null;
  },
);
