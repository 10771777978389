import {TextStyle} from 'react-native';
import {CSSProperties} from '@stitches/react';
import {
  propertyToVariant,
  mapArrayToObject,
  VariantProps,
} from '../variant-utilities';
import {theme} from '../../theme';
import {makeTextStyleVariants} from '../../../typography/utils';

export const typography = {
  textStyle: makeTextStyleVariants(
    Object.keys(theme.fontSizes) as (keyof typeof theme.fontSizes)[],
  ),
  ...propertyToVariant('fontFamily', theme.fonts),
  ...propertyToVariant('fontSize', theme.fontSizes),
  ...propertyToVariant('fontWeight', theme.fontWeights),
  ...propertyToVariant('lineHeight', theme.lineHeights),
  ...propertyToVariant('letterSpacing', theme.letterSpacings),
  ...propertyToVariant(
    'textAlign',
    mapArrayToObject<NonNullable<TextStyle['textAlign']>>([
      'auto',
      'left',
      'right',
      'center',
      'justify',
    ]),
  ),
  ...propertyToVariant(
    'fontStyle',
    mapArrayToObject<NonNullable<TextStyle['fontStyle']>>(['normal', 'italic']),
  ),
  ...propertyToVariant(
    'textDecoration',
    mapArrayToObject<NonNullable<CSSProperties['textDecoration']>>([
      'overline',
      'underline',
      'strike-through',
    ]),
  ),
  ...propertyToVariant(
    'whiteSpace',
    mapArrayToObject<NonNullable<CSSProperties['whiteSpace']>>([
      'normal',
      'nowrap',
      'pre',
      'pre-line',
      'pre-wrap',
      'break-spaces',
    ]),
  ),
  ...propertyToVariant(
    'textOverflow',
    mapArrayToObject<NonNullable<CSSProperties['textOverflow']>>([
      'clip',
      'ellipsis',
    ]),
  ),
  ...propertyToVariant(
    'overflow',
    mapArrayToObject<NonNullable<CSSProperties['overflowBlock']>>([
      'auto',
      'clip',
      'hidden',
      'scroll',
      'visible',
    ]),
  ),
};

export type TypographyProps = VariantProps<typeof typography>;
