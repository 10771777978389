import React from 'react';
import {animated} from '@react-spring/web';
import {styled, utils} from '../theme/stitches/stitches.config';
import {
  transformStyleProps,
  objectKeys,
} from '../theme/stitches/variant-utilities';
import {border} from '../theme/stitches/variants/border';
import {backgroundColor} from '../theme/stitches/variants/color';
import {flexbox} from '../theme/stitches/variants/flexbox';
import {layout} from '../theme/stitches/variants/layout';
import {position} from '../theme/stitches/variants/position';
import {display} from '../theme/stitches/variants/display';
import {space} from '../theme/stitches/variants/space';
import {BoxProps} from './types';
import {transformUtilsProps} from '../theme/stitches/utils-utilities';
import {interaction} from '../theme/stitches/variants/interaction';
import {shadow} from '../theme/stitches/variants/shadow';
import {theme} from '../theme/theme';
import {background} from '../theme/stitches/variants/background';

/**
 * Style of a <View> component from React Native Web
 *
 * This will give <Box> the same base styling as native <View>
 */
const rnwStyle = {
  display: 'flex',
  flexBasis: 'auto',
  flexDirection: 'column',
  flexShrink: 0,
  margin: 0,
  minHeight: 0,
  minWidth: 0,
  padding: 0,
  position: 'relative',
  zIndex: 0,
  boxSizing: 'border-box',
};

/**
 * Makes scrollbars invisible
 */
const hiddenScrollbarStyle = {
  '::-webkit-scrollbar': {
    display: 'none',
  },
  MsOverflowStyle: 'none',
  scrollbarWidth: 'none',
};

const variants = {
  ...space,
  ...layout,
  ...flexbox,
  ...border,
  ...backgroundColor,
  ...position,
  ...display,
  ...interaction,
  ...shadow,
  ...background,
};

const rnwStyleAndVariants = {
  ...rnwStyle,
  variants,
};

const DivWithRnwStyleAndVariants = styled('div', rnwStyleAndVariants);

const variantPropNames = [
  ...objectKeys(space),
  ...objectKeys(layout),
  ...objectKeys(flexbox),
  ...objectKeys(border),
  ...objectKeys(backgroundColor),
  ...objectKeys(position),
  ...objectKeys(display),
  ...objectKeys(interaction),
  ...objectKeys(shadow),
  ...objectKeys(background),
];

const utilsPropNames = objectKeys(utils);

export const Box = React.memo(
  React.forwardRef<HTMLDivElement, BoxProps>(
    ({css = {}, showScrollbar, onHoverIn, onHoverOut, ...rest}, ref) => {
      const transformedStyleProps = transformStyleProps(
        variantPropNames,
        rest,
        [...theme.breakpoints],
      );
      const transformedUtilsProps = transformUtilsProps(utilsPropNames, rest);

      return (
        <DivWithRnwStyleAndVariants
          {...(rest as Omit<typeof rest, keyof typeof transformedStyleProps>)}
          {...transformedStyleProps}
          onMouseOver={onHoverIn}
          onMouseOut={onHoverOut}
          css={
            {
              ...transformedUtilsProps,
              ...css,
              ...(showScrollbar ? {} : hiddenScrollbarStyle),
            } as any
          }
          ref={ref}
        />
      );
    },
  ),
);

export const AnimatedBox = animated(Box);

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const styledBox = (style = {}, BaseComponent = Box) =>
  styled(BaseComponent, style);
