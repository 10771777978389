/* eslint-disable import/no-cycle */
/* eslint-disable react/jsx-no-undef */
import React, {useState} from 'react';
import {Modal} from 'react-native';
import {Box} from '../../box';
import {Button} from '../../button/button';
import {TransparentButton} from '../../button/transparent-button';
import {ArrowRightIcon, CloseIcon} from '../../icons';
import {FrameworkImage} from '../../image-renderer';
import {FrameworkLink, UnstyledHtmlAnchor} from '../../link-renderer';
import {Typography} from '../../typography';
import {
  MOBILE_NAV_ITEMS,
  footerSocialURLs,
  matchWithRoute,
  DROP_NAV_ITEMS,
} from './navbar-block-constants';
import {STUDIO_MENUS} from '../../../../../apps/web/utils/consts';

const BasicButton: React.FC<{
  key: string;
  onPress: () => void;
  text: string;
}> = ({key, onPress, text}) => {
  return (
    <TransparentButton key={key} onPress={onPress}>
      <Box
        p="three"
        borderTopColor="outlines"
        borderTopWidth="one"
        borderTopStyle="solid"
      >
        <Typography textStyle="h2" color="textDefault">
          {text}
        </Typography>
      </Box>
    </TransparentButton>
  );
};

export const ComingSoonTypography = ({text}: {text: string}) => {
  return (
    <Typography textStyle="h2" color="textLow">
      {text}{' '}
      <Typography fontSize="s" color="textLow">
        (coming soon)
      </Typography>
    </Typography>
  );
};

export const BasicLink: React.FC<{key: string; href: string; text: string}> = ({
  key,
  href,
  text,
}) => {
  return (
    <Box
      key={key}
      p="three"
      borderTopColor="outlines"
      borderTopWidth="one"
      borderTopStyle="solid"
    >
      {href === '-' ? (
        <ComingSoonTypography text={text} />
      ) : (
        <FrameworkLink href={href}>
          <Typography textStyle="h2" color="textDefault">
            {text}
          </Typography>
        </FrameworkLink>
      )}
    </Box>
  );
};

export const ArrowLink: React.FC<{
  key: string;
  onPress: () => void;
  text: string;
}> = ({key, onPress, text}) => {
  return (
    <TransparentButton key={key} onPress={onPress}>
      <Box
        p="three"
        borderTopColor="outlines"
        borderTopWidth="one"
        borderTopStyle="solid"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography textStyle="h2" color="textDefault">
          {text}
        </Typography>
        <Box>
          <ArrowRightIcon size={24} color="outlines" />
        </Box>
      </Box>
    </TransparentButton>
  );
};

export interface NavbarInfoMobileProps {
  closeMenu: () => void;
  currentHref: string | undefined;
  walletConnected: boolean;
  openPurchaseHistory: () => void;
  openWallet: () => void;
  studioSlug: string;
}

export const NavbarInfoMobile: React.FC<NavbarInfoMobileProps> = ({
  closeMenu,
  walletConnected,
  openWallet,
  openPurchaseHistory,
  currentHref,
  studioSlug,
}) => {
  const [activeMenus, setActiveMenu] = useState<any[]>(MOBILE_NAV_ITEMS);
  const [title, setTitle] = useState('');
  const [level, setLevel] = useState(0);

  const createMenuBar = () => {
    const menus = activeMenus;
    return (
      <>
        {studioSlug === 'the-drop' &&
          DROP_NAV_ITEMS &&
          DROP_NAV_ITEMS.map((item) => {
            if (item.url) {
              return (
                <BasicLink
                  key={`${item.title}${item.url}`}
                  href={item.url}
                  text={item.title}
                />
              );
            }
          })}
        {walletConnected && (
          <BasicLink href="/my-nfts" text="My NFTs" key="My NFTs" />
        )}
        {!activeMenus &&
          footerSocialURLs.map((link) => (
            <Box
              key={link.socialName}
              p="three"
              borderTopColor="outlines"
              borderTopWidth="one"
              borderTopStyle="solid"
            >
              <FrameworkLink href={link.url} target="_blank">
                <Box flexDirection="row" alignItems="center">
                  {link.icon}
                  <Box pl="five">
                    <Typography textStyle="h2" color="textDefault">
                      {link.socialName}
                    </Typography>
                  </Box>
                </Box>
              </FrameworkLink>
            </Box>
          ))}
        {level === 0 &&
          currentHref &&
          currentHref !== '/' &&
          !matchWithRoute(currentHref) && (
            <>
              {!walletConnected && (
                <Button
                  type="secondary"
                  size="M"
                  buttonStyle={{
                    margin: '8px 6px 8px 6px',
                    backgroundColor: 'var(--studio-primaryButton)',
                    textColor: 'red',
                  }}
                  key="connect-wallet"
                  onPress={() => {
                    openWallet();
                    closeMenu();
                  }}
                >
                  Connect wallet
                </Button>
              )}
              {walletConnected && (
                <BasicButton
                  key="connect-wallet"
                  onPress={() => {
                    openWallet();
                    closeMenu();
                  }}
                  text="My wallet"
                />
              )}

              {walletConnected && (
                <BasicButton
                  key="transactions"
                  onPress={() => {
                    openPurchaseHistory();
                    closeMenu();
                  }}
                  text="Transactions"
                />
              )}
            </>
          )}
      </>
    );
  };

  return (
    // @ts-expect-error modal fix
    <Modal visible transparent>
      <Box
        width="100%"
        // bg="textDefault"
        borderColor="textLow"
        borderWidth="one"
        borderStyle="solid"
        css={{'background-color': 'var(--studio-backgroundColor)'}}
      >
        <Box
          width="100%"
          flexDirection="row"
          flex={1}
          justifyContent="space-between"
        >
          {level !== 0 ? (
            <TransparentButton
              onPress={() => {
                setActiveMenu(MOBILE_NAV_ITEMS);
                setLevel(0);
              }}
            >
              <Box
                flexDirection="row"
                justifyContent="center"
                alignItems="center"
                p="three"
              >
                <Box
                  css={{transform: 'rotate(180deg)'} as any}
                  marginRight="one"
                >
                  <ArrowRightIcon color="textLow" size={14} />
                </Box>
                <Typography color="textDefault" textStyle="s">
                  Back
                </Typography>
              </Box>
            </TransparentButton>
          ) : (
            <Box
              flexDirection="row"
              justifyContent="center"
              alignItems="center"
              m="three"
              width="90px"
              height="15px"
            >
              <FrameworkImage
                src={STUDIO_MENUS[studioSlug as keyof typeof STUDIO_MENUS].logo}
                alt="background"
                layout="fill"
              />
            </Box>
          )}
          {level !== 0 && (
            <Box mt="three">
              <Typography textAlign="center" color="textLow" textStyle="s">
                {title}
              </Typography>
            </Box>
          )}
          <TransparentButton onPress={closeMenu}>
            <Box padding="three">
              <CloseIcon size={18} color="textLow" />
            </Box>
          </TransparentButton>
        </Box>
        {createMenuBar()}
      </Box>
      <Box
        flex="1"
        bg="textDefault"
        css={{
          backdropFilter: 'blur(10px)',
          'background-color': 'var(--studio-backgroundColor)',
        }}
      />
    </Modal>
  );
};
