import React from 'react';
import {colors} from '../theme/tokens/colors';

export interface CopyIconProps {
  color?: keyof typeof colors;
  size?: number;
}

export const CopyIcon = React.memo<CopyIconProps>(
  ({color = 'textLow', size = 10}) => (
    <svg
      width={size}
      height={size}
      viewBox="0 0 10 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0.5H1H7V1.5L1 1.5V9.5H0V1.5V0.5ZM3.14286 3.5H8.85714V10.5H3.14286V3.5ZM2 11.5V2.5H10V11.5H2Z"
        fill={colors[color] || color}
      />
    </svg>
  ),
);
