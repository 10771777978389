import {createUtil, PropsFromUtils} from '../utils-utilities';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const layout = <
  Theme extends Record<'sizes', Record<any, any>> = Record<
    'sizes',
    Record<any, any>
  >,
  Media extends Record<any, string> = Record<any, string>,
>(
  theme: Theme,
  media: Media,
) => ({
  width: createUtil(['width'], media, theme, 'sizes'),
  height: createUtil(['height'], media, theme, 'sizes'),

  w: createUtil(['width'], media, theme, 'sizes'),
  h: createUtil(['height'], media, theme, 'sizes'),

  minWidth: createUtil(['minWidth'], media, theme, 'sizes'),
  maxWidth: createUtil(['maxWidth'], media, theme, 'sizes'),

  minW: createUtil(['minWidth'], media, theme, 'sizes'),
  maxW: createUtil(['maxWidth'], media, theme, 'sizes'),

  minHeight: createUtil(['minHeight'], media, theme, 'sizes'),
  maxHeight: createUtil(['maxHeight'], media, theme, 'sizes'),

  minH: createUtil(['minHeight'], media, theme, 'sizes'),
  maxH: createUtil(['maxHeight'], media, theme, 'sizes'),

  size: createUtil(['width', 'height'], media, theme, 'sizes'),

  minSize: createUtil(['minWidth', 'minHeight'], media, theme, 'sizes'),
  maxSize: createUtil(['maxWidth', 'maxHeight'], media, theme, 'sizes'),
});

export type LayoutUtilsProps = PropsFromUtils<ReturnType<typeof layout>>;
