import {Platform, useWindowDimensions} from 'react-native';
import React from 'react';
import {convertStringValueToPixels} from './utils';
import {theme} from '../theme/theme';

const breakpointPixelValues = theme.breakpoints.map(convertStringValueToPixels);

/**
 * Provides the matching breakpoint from the theme based on the window width
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useBreakpoint = () => {
  const {width} = useWindowDimensions();

  const currentBreakpoint = React.useMemo(():
    | typeof theme.breakpoints[number]
    | null => {
    if (Platform.OS === 'web' && typeof window === 'undefined') return null;

    /**
     * TODO: change when we have all of the breakpoints in the theme from Figma
     */
    if (width < breakpointPixelValues[0]) return '768px';
    if (width < breakpointPixelValues[1]) return '960px';
    if (width < breakpointPixelValues[2]) return '1200px';
    return '1440px';
  }, [width]);

  const getResponsiveValue = React.useCallback(
    <ValueAtBreakpoint extends [any, any, any, any, any, any]>(
      responsiveValues: ValueAtBreakpoint[],
    ) => {
      const currentBreakpointIndex = currentBreakpoint
        ? theme.breakpoints.indexOf(currentBreakpoint)
        : 0;

      if (currentBreakpointIndex === -1) return responsiveValues[0];
      if (currentBreakpointIndex >= responsiveValues.length)
        return responsiveValues[responsiveValues.length - 1];

      return responsiveValues[currentBreakpointIndex];
    },
    [currentBreakpoint],
  );

  return {currentBreakpoint, getResponsiveValue};
};
