import React from 'react';
import {Typography} from '../typography';
import {Box} from '../box';

export interface WalletItemProps {
  title: string;
  text: string;
  // TODO: loading state in the future for when `amount` is null
  amount: string | null; // null means we don't have a value yet
  /**
   * Icon-like component
   */
  IconComponent?: (props: {size: number}) => JSX.Element | null;
}

export const WalletItem: React.FC<WalletItemProps> = ({
  title,
  text,
  amount,
  IconComponent,
}: WalletItemProps) => {
  return (
    <Box
      flexDirection="row"
      alignItems="flex-start"
      justifyContent="space-between"
    >
      {IconComponent ? (
        <Box width="20%">
          <IconComponent size={18} />
        </Box>
      ) : null}
      <Box flexDirection="column" width="60%">
        <Typography color="textDefault" textStyle="s">
          {title}
        </Typography>
        <Typography color="textLow" textStyle="s">
          {text}
        </Typography>
      </Box>
      <Box width="20%" p="zero" m="zero">
        <Typography
          color="textDefault"
          textStyle="s"
          whiteSpace="nowrap"
          textOverflow="ellipsis"
          overflow="hidden"
        >
          {amount ?? ''}
          {/* TODO: loading state when null */}
        </Typography>
        <Box />
      </Box>
    </Box>
  );
};
