import {createUtil, PropsFromUtils} from '../utils-utilities';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const grid = <Media extends Record<any, string> = Record<any, string>>(
  media: Media,
) => ({
  columns: createUtil(['gridTemplateColumns'], media),
});

export type GridUtilsProps = PropsFromUtils<ReturnType<typeof grid>>;
