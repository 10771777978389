/* eslint-disable class-methods-use-this */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {WindowMode} from '@venly/connect';
import {LoggedWalletInfo, Venly} from './types';
import {setLoggedWalletInfo} from './utils';

export const isVenlyProvider = (
  providerLike: Record<string, any>,
): providerLike is VenlyProvider => {
  if (!providerLike.clientId) return false;

  return true;
};
export class VenlyProvider {
  public provider: Venly;

  constructor(provider: Venly) {
    this.provider = provider;
  }

  public getSigner() {
    return {
      signMessage: async (options: string): Promise<string> => {
        const opt = JSON.parse(options);
        const messageRequest = {
          walletId: opt.walletId,
          secretType: opt.secretType,
          data: opt.data,
        };
        const walletInfo: LoggedWalletInfo = {
          walletAddress: opt.walletAddress,
          walletNetwork: opt.walletNetwork,
          walletChain: opt.walletChain,
          nonce: opt.data,
        };
        setLoggedWalletInfo(walletInfo);
        const signer = this.provider.createSigner(WindowMode.REDIRECT);
        try {
          return new Promise(() => {
            setTimeout(async () => {
              await signer.signMessage(messageRequest, {
                correlationID: `${Date.now()}`,
              });
            }, 100);
          });
        } catch (e) {
          throw new Error('User denied to sign.');
        }
      },
    };
  }

  public async getBalance(walletAddress: string, chainId = 5): Promise<string> {
    // const secretType = Object.keys(VENLY_CHAINS)[
    //   Object.values(VENLY_CHAINS).indexOf(chainId)
    // ] as VenlyChains;
    // const getBalFromVenly = await this.provider.api.getBalanceByAddress(
    //   secretType,
    //   walletAddress,
    // );
    // const {rawBalance = '0'} = getBalFromVenly;
    // return rawBalance;
    return '0';
  }
}
