import React from 'react';
import {colors} from '../theme/tokens/colors';

export interface LinkedinIconProps {
  color?: keyof typeof colors;
  size?: number;
}

export const LinkedinIcon = React.memo<LinkedinIconProps>(
  ({color = 'outlines', size = 18}) => (
    <svg
      width={size}
      height={size}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.7391 1.09082H2.25962C1.61392 1.09082 1.0918 1.60059 1.0918 2.23084V15.7659C1.0918 16.3961 1.61392 16.909 2.25962 16.909H15.7391C16.3848 16.909 16.91 16.3961 16.91 15.769V2.23084C16.91 1.60059 16.3848 1.09082 15.7391 1.09082ZM5.78473 14.5703H3.43672V7.01955H5.78473V14.5703ZM4.61072 5.99075C3.85689 5.99075 3.24826 5.38212 3.24826 4.63137C3.24826 3.88063 3.85689 3.272 4.61072 3.272C5.36147 3.272 5.9701 3.88063 5.9701 4.63137C5.9701 5.37903 5.36147 5.99075 4.61072 5.99075ZM14.5712 14.5703H12.2263V10.8999C12.2263 10.0256 12.2109 8.89796 11.006 8.89796C9.78562 8.89796 9.60025 9.85261 9.60025 10.8382V14.5703H7.25842V7.01955H9.50756V8.05144H9.53846C9.8505 7.45826 10.6167 6.83109 11.7567 6.83109C14.1325 6.83109 14.5712 8.39437 14.5712 10.4273V14.5703Z"
        fill={colors[color] || color}
      />
    </svg>
  ),
);
