import {recoverPublicKey} from '@ethersproject/signing-key';
import {hashMessage} from '@ethersproject/hash';
import {createHash} from 'crypto';

export const getPublicKeyFromMessageAndSignature = (
  message: string,
  signature: string,
): string =>
  recoverPublicKey(
    hashMessage(createHash('sha256').update(message).digest('hex')),
    signature,
  );
