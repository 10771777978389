import React, {forwardRef} from 'react';
import {styledBox} from '../box';

export const StyledBox = styledBox({
  color: 'unset',
  textDecoration: 'none',
  display: 'contents',
});

export const UnstyledHtmlAnchor = forwardRef<
  HTMLAnchorElement,
  React.AnchorHTMLAttributes<HTMLAnchorElement>
>((props, ref) => <StyledBox {...props} ref={ref} as="a" />);
