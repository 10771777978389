import React from 'react';
import {Box} from '../../box';
import {Button} from '../../button/button';
import {Typography} from '../typography';
import {toastDefaults, ToastVariants} from './toast-defaults';

export interface ToastData {
  artist?: string;
  songTitle?: string;
  title?: string;
  text?: string;
  errorMessage?: string;
  transactionHash?: string;
  variant: ToastVariants;
}

export interface ToastProps extends ToastData {
  onClose: () => void;
}

export const Toast: React.FC<ToastProps> = ({
  artist,
  songTitle,
  transactionHash,
  onClose,
  variant,
  title,
  text,
  errorMessage,
}) => {
  return (
    <Box>
      <Box
        position="absolute"
        h="100%"
        w="100%"
        borderStyle="solid"
        borderColor="outlines"
        borderWidth="one"
        css={{background: 'var(--studio-backgroundColor)'}}
      />
      <Box flexDirection="row" padding="three">
        <Box pr={['four', 'fifteen']} pt="xs">
          {toastDefaults[variant].icon()}
        </Box>
        <Box flexDirection={['column', 'row']} flex={1}>
          <Box flex={['zero', '1']}>
            <Typography
              css={{
                color: 'var(--studio-primaryText)',
              }}
              textStyle="s"
            >
              {artist || title || toastDefaults[variant].title}
            </Typography>
            {!!songTitle && (
              <Box mt="xs">
                <Typography
                  css={{
                    color: 'var(--studio-primaryText)',
                  }}
                  textStyle="s"
                >
                  {songTitle || ' '}
                </Typography>
              </Box>
            )}
          </Box>
          <Box flex={['zero', '1']} mt={['xs', 'zero']}>
            <Typography
              css={{
                color: 'var(--studio-primaryText)',
              }}
              textStyle="s"
            >
              {transactionHash
                ? `#${transactionHash}`
                : text || toastDefaults[variant].text}
            </Typography>
            {!!toastDefaults[variant].paymentStatus && (
              <Box mt="xs">
                <Typography
                  css={{
                    color: 'var(--studio-primaryText)',
                  }}
                  textStyle="s"
                >
                  {errorMessage ||
                    `Payment ${toastDefaults[variant].paymentStatus}`}
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
      <Button
        onPress={onClose}
        type="secondary"
        size="S"
        hasBorder
        buttonStyle={{
          backgroundColor: 'var(--studio-secondaryButton)',
          textColor: 'var(--studio-secondaryButtonText)',
          margin: '8px 6px 8px 6px',
          width: '320px',
        }}
        hasButtonStyle
      >
        Dismiss
      </Button>
    </Box>
  );
};
