/**
 * An array reducer function merges object entries into a single object.
 *
 * @param acc
 * @param curr
 * @returns
 */
export const mergeObjectArrayToObject = <
  Key extends string,
  Acc extends Record<Key, unknown>,
  Curr extends Record<Key, unknown>,
>(
  acc: Acc,
  curr: Curr,
): Acc & Curr => ({...acc, ...curr});

type PropertyBasedVariant<
  PropertyName extends keyof CSSStyleDeclaration,
  TokenName extends string,
  TokenValue extends string | number,
> = {
  [x in PropertyName]: {
    [y in TokenName]: {
      [z in x]: TokenValue;
    };
  };
};

/**
 * Creates a Stitches-compatible variant. Intended to used for a CSS property.
 *
 * @param propertyName
 * @param tokens
 * @returns
 */
export const propertyToVariant = <
  PropertyName extends keyof CSSStyleDeclaration,
  TokenName extends string,
  TokenValue extends string | number,
>(
  propertyName: PropertyName,
  tokens: Record<TokenName, TokenValue>,
): PropertyBasedVariant<PropertyName, TokenName, TokenValue> =>
  ({
    [propertyName]: {
      ...(Object.keys(tokens) as TokenName[])
        .map((tokenName) => ({
          [tokenName]: {
            [propertyName]: tokens[tokenName],
          },
        }))
        .reduce(mergeObjectArrayToObject, {}),
    },
  } as any);

type PropertyShorthandBasedVariant<
  PropertyShorthand extends string,
  PropertyName extends keyof CSSStyleDeclaration,
  TokenName extends string,
  TokenValue extends CSSStyleDeclaration[PropertyName],
> = {
  [x in PropertyShorthand]: {
    [y in TokenName]: {
      [z in PropertyName]: TokenValue;
    };
  };
};

/**
 * Creates a Stitches-compatible variant. Intended to be used for shorthand CSS properties.
 *
 * @param propertyShorthand
 * @param propertyNames
 * @param tokens
 * @returns
 */
export const propertyShorthandToVariant = <
  PropertyShorthand extends string,
  PropertyName extends keyof CSSStyleDeclaration,
  TokenName extends string,
  TokenValue extends CSSStyleDeclaration[PropertyName],
>(
  propertyShorthand: PropertyShorthand,
  propertyNames: PropertyName[],
  tokens: Record<TokenName, TokenValue>,
): PropertyShorthandBasedVariant<
  PropertyShorthand,
  PropertyName,
  TokenName,
  TokenValue
> =>
  ({
    [propertyShorthand]: {
      ...(Object.keys(tokens) as TokenName[])
        .map((tokenName) => ({
          [tokenName]: propertyNames
            .map((propertyName) => ({[propertyName]: tokens[tokenName]}))
            .reduce(mergeObjectArrayToObject, {}),
        }))
        .reduce(mergeObjectArrayToObject, {}),
    },
  } as any);

/**
 * Takes an array and returns an object with the array values as keys and the array values as values.
 *
 * @param values
 * @returns
 */
export const mapArrayToObject = <T extends string | number>(
  values: T[],
): Record<T, T> =>
  values.reduce((acc, curr) => ({...acc, [curr]: curr}), {} as Record<T, T>);

/**
 * Takes an array of breakpoint values (e.g. 720px, 768px, etc.) and converts it to a Stitches-compatible media object.
 *
 * @param breakpoints array of breakpoint values
 * @returns Stitches-compatible media object
 */
export const convertBreakpointsArrayToMediaObject = <
  BreakpointValue extends string,
  MediaQuery =
    | `(min-width: ${BreakpointValue &
        string}) and (max-width: ${BreakpointValue & string})`
    | `(min-width: ${BreakpointValue & string})`,
>(
  breakpoints: BreakpointValue[],
): Record<keyof typeof breakpoints & number, MediaQuery> =>
  breakpoints
    .filter(Boolean)
    .map((breakpointValue, index: keyof typeof breakpoints & number) => ({
      [index + 1]: `(min-width: ${breakpointValue})${
        index === breakpoints.length - 1
          ? ''
          : ` and (max-width: ${breakpoints[index + 1]})`
      }` as unknown as MediaQuery,
    }))
    .reduce(mergeObjectArrayToObject, {});

type MediaObjectValues<
  ResponsiveArrayValues extends (string | null)[],
  MediaObjectKey = `@${keyof ResponsiveArrayValues & number}`,
> = {
  '@initial': NonNullable<ResponsiveArrayValues[keyof ResponsiveArrayValues]>;
} & {
  [mediaObjectKey in MediaObjectKey & string]?: NonNullable<
    ResponsiveArrayValues[keyof ResponsiveArrayValues]
  >;
};

/**
 * Takes a StyledSystem-like responsive array of values and converts it to a Stitches-compatible responsive object.
 *
 * @param responsiveArrayValues StyledSystem-like responsive array
 * @returns Stitches responsive object
 */
export const convertResponsiveArrayValueToMediaObjectValues = <
  ResponsiveArrayValue extends string | null,
  Breakpoint extends string,
>(
  responsiveArrayValues: ResponsiveArrayValue[],
  breakpoints: Breakpoint[],
): MediaObjectValues<ResponsiveArrayValue[]> =>
  responsiveArrayValues
    .map((responsiveArrayValue, index) =>
      responsiveArrayValue
        ? {
            [index === 0 ? '@initial' : `@${index}`]: responsiveArrayValue,
          }
        : {},
    )
    .concat(
      // @ts-expect-error type fix
      responsiveArrayValues.length < breakpoints.length
        ? new Array(breakpoints.length - responsiveArrayValues.length)
            .fill(null)
            .map((_, index) => ({
              [`@${responsiveArrayValues.length + index}`]:
                responsiveArrayValues[responsiveArrayValues.length - 1],
            }))
        : [],
      {
        [`@${breakpoints.length}`]:
          responsiveArrayValues[responsiveArrayValues.length - 1],
      },
    )
    /**
     * Bug workaround:
     * We need to filter out the sibling values of `@initial` that are the
     * same as the value of `@initial`.
     *
     * @see - https://github.com/modulz/stitches/issues/896
     */
    .filter(
      (responsiveArrayValueObject, index, responsiveArrayValueObjects) =>
        // Always include the first responsiveArrayValueObject (@initial)
        index === 0 ||
        // Exclude the responsiveArrayValueObjects that are the same as `@initial`
        Object.values(responsiveArrayValueObject)[0] !==
          Object.values(responsiveArrayValueObjects[0])[0],
    )
    .reduce(mergeObjectArrayToObject, {}) as any;

export type VariantProps<T extends Record<string, Record<string, unknown>>> =
  Partial<{
    [spaceProp in keyof T]: keyof T[spaceProp] | (keyof T[spaceProp] | null)[];
  }>;

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const transformStyleProps = <
  VariantPropName extends string,
  Props extends Partial<Record<VariantPropName, unknown>>,
  Breakpoint extends string,
>(
  variantPropNames: VariantPropName[],
  props: Props,
  breakpoints: Breakpoint[],
) =>
  variantPropNames
    .map((variantPropName) => {
      const variantPropValue = props[variantPropName];

      if (!variantPropValue) return [variantPropName, undefined] as const;

      if (!Array.isArray(variantPropValue))
        return [variantPropName, variantPropValue] as const;

      return [
        variantPropName,
        convertResponsiveArrayValueToMediaObjectValues(
          variantPropValue,
          breakpoints,
        ),
      ] as const;
    })
    .filter(([, value]) => value !== undefined)
    .map(([variantPropName, variantPropValue]) => ({
      [variantPropName]: variantPropValue,
    }))
    .reduce(mergeObjectArrayToObject, {});

export const objectKeys = <Key extends string>(
  object: Record<Key, unknown>,
): Key[] => Object.keys(object) as Key[];
