import React from 'react';
import {colors} from '../theme/tokens/colors';
import {EthereumCurrencyIcon} from './ethereum-currency';
import {PolygonIcon} from './polygon';
import {TokenTraxxIcon} from './token-traxx';
import {UsdtIcon} from './usdt';
import {UsdIcon} from './usd';
import {ZilliqaIcon} from './zilliqa';

type CurrencySymbol = 'TRAXX' | 'USDT' | 'ZIL' | 'ETH' | 'MATIC' | 'USD';

const CurrencyIconComponentMap: Record<
  CurrencySymbol,
  // Icon-like component
  (props: {
    size: number | undefined;
    color: keyof typeof colors | (string & Record<string, never>) | undefined;
  }) => JSX.Element | null
> = {
  TRAXX: TokenTraxxIcon,
  USDT: UsdtIcon,
  USD: UsdIcon,
  ZIL: ZilliqaIcon,
  ETH: EthereumCurrencyIcon,
  MATIC: PolygonIcon,
};

export interface CurrencyIconProps {
  name: CurrencySymbol;
  size: number;
  color?: keyof typeof colors | (string & Record<string, never>);
}

export const CurrencyIcon: React.FC<CurrencyIconProps> = ({
  size,
  color,
  name,
}) => {
  const CurrencyIconComponent = CurrencyIconComponentMap[name];
  return CurrencyIconComponent ? (
    <CurrencyIconComponent size={size} color={color} />
  ) : null;
};
