/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import {useRouter} from 'next/router';
import {useEffect} from 'react';
import {mixpanelTrack} from './utils';

export const usePageViewEffect = (): void => {
  const router = useRouter();

  const {pathname} = router;

  const trackPageView = (url: string) => {
    mixpanelTrack('Page View', {
      url,
      pathname,
    });
  };

  useEffect(() => {
    const handleRouteChange = (url: string) => {
      trackPageView(url);
    };
    router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.events]);
};
