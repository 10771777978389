import React from 'react';
import {Box} from '../../box';
import {FooterLogoIcon, TokenTraxxIcon} from '../../icons';
import {Typography} from '../../typography';
import {FrameworkLink, UnstyledHtmlAnchor} from '../../link-renderer';
import {ExternalURL, SocialURL} from './types';
import {ContentContainerBox} from '../../content-container-box';

export interface FooterProps {
  footerExternalLinks: ExternalURL[];
  footerSocialLinks: SocialURL[];
  contact: string;
  isLoading?: boolean;
}

const MOBILE_HEIGHT = 670;
// const MOBILE_ANIMATION_WIDTH = MOBILE_HEIGHT * 3;
const WEB_HEIGHT = 350;
// const WEB_ANIMATION_WIDTH = WEB_HEIGHT * 3;

const STUDIO_WEB_HEIGHT = 280;

export const Footer: React.FC<FooterProps> = ({
  footerSocialLinks,
  footerExternalLinks,
  contact,
  isLoading,
}) => {
  return (
    <ContentContainerBox>
      {isLoading ? null : (
        <Box
          overflow="hidden"
          borderColor="grey24"
          borderStyle="solid"
          borderWidth="zero"
          minHeight={[MOBILE_HEIGHT, STUDIO_WEB_HEIGHT]}
          css={{
            'border-width': '0px !important',
            backgroundColor: 'var(--studio-footerBackground)',
          }}
        >
          <Box height="100%" width="100%" justifyContent="flex-start">
            <Box
              paddingTop={['eight']}
              paddingX={['zero', 'four']}
              flexDirection={['column', 'row']}
              alignItems={['flex-start', 'flex-start']}
              flex={['none', '1']}
            >
              {contact !== 'npayme@oneiro.io' && (
                <Box
                  flex="5"
                  paddingX={['three', 'zero', 'one']}
                  paddingY={['zero', 'three', 'one']}
                >
                  <Typography
                    textStyle={['h2', 'h2', 's']}
                    css={{
                      color: 'var(--studio-footerText)',
                    }}
                  >
                    Powered by
                  </Typography>
                  <FrameworkLink href="/">
                    <Box mb="s">
                      <FooterLogoIcon
                        width={[140, 140, 280]}
                        css={{
                          color: 'var(--studio-footerText) !important',
                        }}
                      />
                    </Box>
                  </FrameworkLink>
                </Box>
              )}
              <Box
                pb="three"
                flexDirection={['column', 'row']}
                flex={['none', '7']}
                width={['100%', 'auto']}
              >
                <Box flex={['none', '1']} paddingX={['three', 'four']}>
                  <Box mb="five">
                    <Typography
                      css={{
                        color: 'var(--studio-footerText)',
                      }}
                    >
                      Useful Links
                    </Typography>
                  </Box>
                  {footerExternalLinks.map((link) => (
                    <Box marginBottom="s" key={link.title}>
                      <UnstyledHtmlAnchor
                        href={link.url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Box flexDirection="row" alignItems="center">
                          <Typography
                            textStyle="s"
                            css={{
                              color: 'var(--studio-footerText)',
                            }}
                          >
                            {link.title}
                          </Typography>
                        </Box>
                      </UnstyledHtmlAnchor>
                    </Box>
                  ))}
                </Box>
                <Box
                  borderTopColor="outlinesInvert"
                  borderTopStyle="solid"
                  borderTopWidth="one"
                  width="100%"
                  display={['flex', 'none']}
                  marginY="three"
                />
                <Box flex={['none', '1']} paddingX={['three', 'four']}>
                  <Box marginBottom="five">
                    <Typography
                      css={{
                        color: 'var(--studio-footerText)',
                      }}
                    >
                      Connect with us
                    </Typography>
                  </Box>
                  {footerSocialLinks.map((link) => (
                    <Box marginBottom="s" key={link.socialName}>
                      <UnstyledHtmlAnchor
                        href={link.url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Box flexDirection="row" alignItems="center">
                          {link.icon}
                          <Box pl="five">
                            <Typography
                              textStyle="s"
                              css={{
                                color: 'var(--studio-footerText)',
                              }}
                            >
                              {link.socialName}
                            </Typography>
                          </Box>
                        </Box>
                      </UnstyledHtmlAnchor>
                    </Box>
                  ))}
                </Box>
                <Box
                  borderTopColor="outlinesInvert"
                  borderTopStyle="solid"
                  borderTopWidth="one"
                  width="100%"
                  display={['flex', 'none']}
                  marginY="three"
                />
                <Box flex={['none', '1']} paddingX={['three', 'four']}>
                  <Box marginBottom="five">
                    <Typography
                      css={{
                        color: 'var(--studio-footerText)',
                      }}
                    >
                      Questions?
                    </Typography>
                  </Box>
                  <Box marginTop="one">
                    <Typography
                      css={{
                        color: 'var(--studio-footerText)',
                      }}
                    >
                      Let&apos;s Chat :
                    </Typography>
                  </Box>
                  <Box marginTop="one">
                    <UnstyledHtmlAnchor
                      href={`mailto:${contact}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Typography
                        css={{
                          color: 'var(--studio-footerText)',
                        }}
                        textDecoration="underline"
                      >
                        {contact}
                      </Typography>
                    </UnstyledHtmlAnchor>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </ContentContainerBox>
  );
};
