import React from 'react';
import {QueryClient, QueryClientProvider} from 'react-query';
import {createTrpcClient, TrpcProvider} from '../../hooks/ttx-trpc';

const port = process.env.TTX_PORT || 3000;
const endpoint = '/api/trpc';

export const ttxQueryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // Prevents the query from being executed if it is already in the cache
      staleTime: Infinity,
      // We want to do a refetch each time a new query hook is mounted, in case
      // stale data is incorrect.
      refetchOnMount: 'always',
    },
  },
});

const trpcClient = createTrpcClient({
  // eslint-disable-next-line no-nested-ternary
  url: process.browser
    ? // For client-side
      endpoint
    : // For SSR
    process.env.VERCEL_URL
    ? `https://${process.env.VERCEL_URL}/${endpoint}`
    : // For local development
      `http://localhost:${port}/${endpoint}`,
});

export interface TokenTraxxQueryProviderProps {
  children: React.ReactNode;
}

export const TokenTraxxQueryProvider: React.FC<
  TokenTraxxQueryProviderProps
> = ({children}) => (
  <TrpcProvider client={trpcClient} queryClient={ttxQueryClient}>
    <QueryClientProvider client={ttxQueryClient}>
      {children}
    </QueryClientProvider>
  </TrpcProvider>
);
