import {CSSProperties} from '@stitches/react';
import {
  propertyToVariant,
  mapArrayToObject,
  VariantProps,
} from '../variant-utilities';

export const interaction = {
  ...propertyToVariant(
    'cursor',
    mapArrayToObject<NonNullable<CSSProperties['cursor']>>([
      'not-allowed',
      'pointer',
      'default',
    ]),
  ),

  ...propertyToVariant(
    'userSelect',
    mapArrayToObject<NonNullable<CSSProperties['userSelect']>>(['none', 'all']),
  ),
};

export type InteractionProps = VariantProps<typeof interaction>;
