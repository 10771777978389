export const space = {
  xxs: '1px',
  xs: '2px',
  s: '6px',
  md: '12px',
  lg: '18px',
  zero: '0px',
  one: '4px',
  two: '8px',
  three: '12px',
  four: '16px',
  five: '20px',
  six: '24px',
  seven: '28px',
  eight: '32px',
  nine: '36px',
  ten: '40px',
  eleven: '44px',
  twelve: '48px',
  thirteen: '52px',
  fourteen: '56px',
  fifteen: '60px',
  sixteen: '64px',
  seventeen: '68px',
  eighteen: '72px',
  nineteen: '76px',
  twenty: '80px',
  twentyOne: '84px',
  twentyTwo: '88px',
  twentyThree: '92px',
  twentyFour: '96px',
  twentyFive: '100px',
  twentySix: '104px',
  twentySeven: '108px',
  twentyEight: '112px',
  twentyNine: '116px',
  thirty: '120px',
  thirtyOne: '124px',
  thirtyTwo: '128px',
  thirtyThree: '132px',
  thirtyFour: '136px',
  thirtyFive: '140px',
  thirtySix: '144px',
};
