import React from 'react';
import {typography} from '../../theme/stitches/variants/typography';
import {styled, utils} from '../../theme/stitches/stitches.config';
import {textColor} from '../../theme/stitches/variants/color';
import {
  transformStyleProps,
  objectKeys,
} from '../../theme/stitches/variant-utilities';
import {TypographyProps, TypographyVariants} from './types';
import {animated} from '../../animation';
import {theme} from '../../theme/theme';
import {transformUtilsProps} from '../../theme/stitches/utils-utilities';

/**
 * Style of a <Text> component from React Native Web
 *
 * This will give <Typography> the same base styling on both web and native
 */
const rnwStyle = {
  boxSizing: 'border-box',
  display: 'inline',
  color: '#000',
  font: '14px -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif',
  margin: 0,
  padding: 0,
  whiteSpace: 'pre-wrap',
  wordWrap: 'break-word',
};

// @ts-expect-error type fix
export const DivWithRnwStyleAndVariants = styled('div', {
  ...rnwStyle,

  variants: {
    ...typography,
    ...textColor,
  },
});

const variantPropNames: (keyof TypographyVariants)[] = [
  ...objectKeys(typography),
  ...objectKeys(textColor),
];

const utilsPropNames = objectKeys(utils);

export const Typography = React.memo(
  React.forwardRef<HTMLDivElement, TypographyProps>(
    ({css = {}, ...rest}, ref) => {
      const transformedStyleProps = transformStyleProps(
        variantPropNames,
        rest,
        [...theme.breakpoints],
      );
      const transformedUtilsProps = transformUtilsProps(utilsPropNames, rest);

      return (
        <DivWithRnwStyleAndVariants
          {...(rest as Omit<typeof rest, keyof typeof transformedStyleProps>)}
          {...transformedStyleProps}
          css={
            {
              ...transformedUtilsProps,
              ...css,
            } as any
          }
          ref={ref}
        />
      );
    },
  ),
);

export const AnimatedTypography = animated(Typography);

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const styledTypography = (style = {}, BaseComponent = Typography) =>
  // @ts-expect-error type fix
  styled(BaseComponent, style);
