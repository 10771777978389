import React, {useCallback, useMemo, useState} from 'react';
import {Box} from '../box';
import {Typography} from '../typography';
import {
  CloseIcon,
  CopyIcon,
  CurrencyIcon,
  CurrencyIconProps,
  TickIcon,
} from '../icons';
import {WalletItem, WalletItemProps} from '../wallet-item';
import {TransparentButton} from '../button/transparent-button';
import {
  RefreshFundsWalletButton,
  LogoutWalletButton,
  ReconnectWalletButton,
} from './wallet-button';
import {HorizontalDivider} from '../divider/horizontal-divider';
import {VerticalDivider} from '../divider/vertical-divider';

const ICON_SIZE = 18;

interface CurrencyWithBalance {
  currencySymbol: CurrencyIconProps['name'];
  currencyName: string;
  formattedValue: string | null; // `null` if the value hasn't been loaded yet
}

export interface WalletContentsProps {
  onClose: () => void;
  onLogoutPress?: () => void;
  onRefreshFundsPress?: () => void;
  onReconnectWallet?: () => void;
  active: boolean;
  walletAddress: string | null;
  formattedWalletAddress: string | null;
  balances?: CurrencyWithBalance[];
}

export const WalletContents: React.FC<WalletContentsProps> = ({
  onClose,
  onLogoutPress,
  onRefreshFundsPress,
  onReconnectWallet,
  active,
  walletAddress,
  formattedWalletAddress,
  balances,
}) => {
  const [copied, setCopied] = useState(false);

  const copyToClipboard = useCallback(() => {
    if (walletAddress) {
      navigator.clipboard.writeText(walletAddress);
      setCopied(true);
    }
  }, [walletAddress]);

  const walletItems = useMemo(
    (): WalletItemProps[] =>
      balances
        ? balances.map(({currencySymbol, formattedValue, currencyName}) => ({
            amount: formattedValue,
            text: currencyName,
            title: currencySymbol,
            IconComponent: (props) => (
              <CurrencyIcon
                {...props}
                color="textDefault"
                size={ICON_SIZE}
                name={currencySymbol}
              />
            ),
          }))
        : [],
    [balances],
  );

  return (
    <Box width="100%" height="100%">
      <Box
        flex="1"
        borderStyle="solid"
        borderWidth="one"
        borderColor="outlines"
        justifyContent="space-between"
      >
        <Box
          position="absolute"
          top="0"
          right="0"
          left="0"
          bottom="0"
          bg="surfaceDark"
        />
        <Box>
          <Box
            alignItems="center"
            justifyContent="space-between"
            flexDirection="row"
            pl="lg"
          >
            <Typography color="textDefault" textStyle="s">
              {active && formattedWalletAddress ? (
                <Box flexDirection="row" alignItems="center">
                  <div>{formattedWalletAddress}</div>
                  <Box m="two">
                    <TransparentButton
                      onPress={copyToClipboard}
                      disabled={copied}
                    >
                      <Box flexDirection="row" alignItems="center">
                        {copied ? (
                          <TickIcon size={12} />
                        ) : (
                          <CopyIcon color="textLow" size={12} />
                        )}
                      </Box>
                    </TransparentButton>
                  </Box>
                </Box>
              ) : (
                'No wallet connected'
              )}
            </Typography>
            <TransparentButton onPress={onClose}>
              <Box padding="md">
                <CloseIcon size={16} />
              </Box>
            </TransparentButton>
          </Box>
          <HorizontalDivider />
          <Box
            flex="1"
            overflowX="hidden"
            justifyContent={active ? 'center' : 'flex-start'}
          >
            {active ? (
              <Box>
                {walletItems.map((item) => (
                  <React.Fragment key={item.title}>
                    <Box paddingX="four" paddingY="three">
                      <WalletItem {...item} />
                    </Box>
                    <HorizontalDivider />
                  </React.Fragment>
                ))}
              </Box>
            ) : (
              <Box p="lg">
                <Typography textStyle="s" color="textLow">
                  Please connect a wallet to continue
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
        <Box>
          {/* <HorizontalDivider /> */}
          {onRefreshFundsPress || onLogoutPress ? (
            <Box alignItems="center" flexDirection="row">
              {onLogoutPress && (
                <Box flex={1}>
                  <LogoutWalletButton
                    type="secondary"
                    onPress={onLogoutPress}
                  />
                </Box>
              )}
              {onRefreshFundsPress && onLogoutPress && (
                <Box height="100%" bg="outlines">
                  <VerticalDivider />
                </Box>
              )}
              {onRefreshFundsPress && (
                <Box flex={1}>
                  <RefreshFundsWalletButton
                    type="secondary"
                    onPress={onRefreshFundsPress}
                  />
                </Box>
              )}
            </Box>
          ) : (
            onReconnectWallet && (
              <ReconnectWalletButton
                type="primary"
                onPress={onReconnectWallet}
              />
            )
          )}
        </Box>
      </Box>
    </Box>
  );
};
