import {useMemo} from 'react';
import {useSpring} from '../animation';
import {InputState} from './use-interaction-state';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useButtonAnimation = (state: InputState) => {
  const isActive = useMemo(() => {
    return (
      state === InputState.Hovering ||
      state === InputState.Pressed ||
      state === InputState.Focused
    );
  }, [state]);

  const animatedTypographyStyle = useSpring({
    marginLeft: isActive ? '3px' : '0',
    config: {duration: 100},
  });

  const animatedAccessoryStyle = useSpring({
    opacity: isActive ? 1 : 0,
    // TODO: get accurate width of glyph
    width: isActive ? '5px' : '0px',
    marginRight: isActive ? '3px' : '0',
    config: {duration: 100},
  });

  return {
    animatedTypographyStyle,
    animatedAccessoryStyle,
  };
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useTransparentButtonAnimation = (state: InputState) => {
  const hovered = state === InputState.Hovering;

  const animatedTransparentBoxStyle = useSpring({
    transform: `scaleX(${hovered ? 1.15 : 1})`,
  });

  return {
    animatedTransparentBoxStyle,
  };
};
