import {BigNumber} from '@ethersproject/bignumber';
import {formatUnits} from '@ethersproject/units';
import {toBech32Address} from '@zilliqa-js/zilliqa';

type USDString = `$${string}`;
export type PaymentCurrency = 'TRAXX' | 'USDT' | 'MATIC';
export type NativeCurrency = 'ZIL' | 'ETH' | 'MATIC';
export type FormattableCurrency = PaymentCurrency | NativeCurrency;

/**
 * Takes a number and formats it into dollars.
 * @example toUSDString(123456789) // => '$123,456,789.00'
 */
export const toUSDString = (amount: number): USDString => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  return formatter.format(amount) as USDString;
};

/**
 * Takes a number and formats it into the application's native currency.
 * @example toNativeCurrencyString(1.23456789, USDT) // => '1.234567'
 */
export const toNativeCurrencyString = (
  amount: number | BigNumber | string,
  formattableCurrency: FormattableCurrency | null,
): string => {
  if (!formattableCurrency) return amount.toString();

  const decimalPlaces =
    // eslint-disable-next-line no-nested-ternary
    formattableCurrency === 'USDT'
      ? 6
      : formattableCurrency === 'ZIL'
      ? 13
      : 18;
  try {
    return formatUnits(amount, decimalPlaces);
  } catch (e) {
    // Probably underflow error...
    return Number(Number(amount).toFixed(decimalPlaces)).toString();
  }
};

export const addressFrom0xToZil = (address: string) => {
  if (!address) return '';
  return toBech32Address(address);
};
