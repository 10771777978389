import React from 'react';
import {Pressable} from 'react-native';
import {Box} from '../box';
import {BoxProps} from '../box/types';

type SomeBoxProps = Omit<BoxProps, 'display'>;

// @ts-expect-error modal fix
export interface ModalLayoutBoxProps extends SomeBoxProps {
  children: React.ReactNode;
  visible: boolean;
  onModalClose: () => void;
  closeOnOverlay?: boolean;
  __useReactNativeModal?: boolean;
}

export const ModalLayoutBox: React.FC<ModalLayoutBoxProps> = ({
  onModalClose,
  children,
  closeOnOverlay,
  ...rest
}) => {
  return (
    <Box flex="1" justifyContent="center" alignItems="center" p="md">
      <Box
        {...(rest as any)}
        zIndex="2"
        justifyContent="center"
        alignItems="center"
        w="100%"
      >
        {children}
      </Box>
      <Box
        position="absolute"
        top={0}
        left={0}
        width="100%"
        height="100%"
        css={{
          backdropFilter: 'blur(20px)',
        }}
      />
      <Pressable
        style={{
          position: 'absolute',
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          zIndex: 1,
          backgroundColor: 'rgba(1, 2, 3, 0.6)',
        }}
        onPress={closeOnOverlay ? onModalClose : null}
      />
    </Box>
  );
};
