/* eslint-disable jsx-a11y/media-has-caption */
import {
  Box,
  ContentContainerBox,
  MusicTrackPlayer,
  MusicTrackSlider,
  VisibilityBox,
} from '@ttx/design-system';
import React, {
  Dispatch,
  MutableRefObject,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {usePlausible} from 'next-plausible';
import {mixpanelTrack} from '../analytics-context/utils';

interface MusicPlayerValue {
  isPlaying: boolean;
  isMute?: boolean;
  togglePlayPause: () => void;
  toggleMute?: () => void;
  toggleFullScreen?: () => void;
  isFullScreen?: boolean;
  isLoading: boolean;
  duration: number;
  setDuration: Dispatch<SetStateAction<number>>;
  currentTime: number;
  setCurrentTime: Dispatch<SetStateAction<number>>;
  audioPlayer: MutableRefObject<HTMLAudioElement | null>;
  setAudioSrc: Dispatch<SetStateAction<string | undefined>>;
  audioSrc: string | undefined;
  setIsHidden: Dispatch<SetStateAction<boolean>>;
  isHidden: boolean;
  setIsPlaying: Dispatch<SetStateAction<boolean>>;
  musicPlayerDisabled: boolean;
  setMusicPlayerDisabled: Dispatch<SetStateAction<boolean>>;
  setIsVideo: Dispatch<SetStateAction<boolean>>;
}

const MusicPlayer = React.createContext<MusicPlayerValue | null>(null);

interface MusicPlayerProviderProps {
  showMusicPlayer?: boolean;
  children: React.ReactElement | (React.ReactElement | null)[];
}

export const MusicPlayerProvider: React.FC<MusicPlayerProviderProps> = ({
  children,
  showMusicPlayer,
}: MusicPlayerProviderProps) => {
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const [isMute, setIsMute] = useState<boolean>(false);
  const [isFullScreen, setIsFullScreen] = useState<boolean>(false);
  const [isVideo, setIsVideo] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isHidden, setIsHidden] = useState<boolean>(false);
  const [duration, setDuration] = useState<number>(0);
  const [currentTime, setCurrentTime] = useState<number>(0);
  const [audioSrc, setAudioSrc] = useState<string | undefined>(undefined);
  const [musicPlayerDisabled, setMusicPlayerDisabled] = useState<boolean>(true);
  const _showMusicPlayer = !isHidden && showMusicPlayer;
  const plausible = usePlausible();
  const audioPlayer = useRef<HTMLAudioElement | null>(null);

  const togglePlayPause = useCallback(() => {
    const prevValue = isPlaying;
    if (!duration) {
      audioPlayer.current?.load();
      setIsLoading(true);
      return;
    }

    if (!prevValue) {
      audioPlayer.current?.play();
      plausible('Track played');
      mixpanelTrack('Track Played');
      setIsPlaying(!prevValue);
    } else {
      audioPlayer.current?.pause();
      setIsPlaying(!prevValue);
    }
  }, [duration, isPlaying, plausible]);

  const toggleMute = useCallback(() => {
    if (audioPlayer && audioPlayer.current) {
      audioPlayer.current.muted = !audioPlayer.current.muted;
      setIsMute(audioPlayer.current.muted);
    }
  }, [isMute]);

  const toggleFullScreen = useCallback(() => {
    const prevValue = isFullScreen;
    if (!prevValue) {
      setIsFullScreen(!prevValue);
    } else {
      setIsFullScreen(!prevValue);
    }
    if (audioPlayer.current?.requestFullscreen) {
      audioPlayer.current?.requestFullscreen();
    }
  }, [isFullScreen]);

  useEffect(() => {
    if (isLoading && !isPlaying) {
      togglePlayPause();
    }
  }, [duration]);

  useEffect(() => {
    if (audioPlayer.current) {
      audioPlayer.current.currentTime = 0;
      if (!Number.isNaN(audioPlayer.current?.duration)) {
        setDuration(audioPlayer.current?.duration);
        audioPlayer.current.currentTime = 0;
        setIsLoading(false);
        audioPlayer.current?.play();
        setIsPlaying(true);
      }
      audioPlayer.current?.addEventListener('loadedmetadata', () => {
        const seconds = audioPlayer.current?.duration || 0;
        setDuration(seconds);
      });

      audioPlayer.current?.addEventListener('waiting', () => {
        setIsLoading(true);
      });

      audioPlayer.current?.addEventListener('playing', () => {
        setIsLoading(false);
      });

      audioPlayer.current?.addEventListener('ended', () => {
        if (!audioPlayer.current) return;
        audioPlayer?.current.pause();
        setIsPlaying(false);
        audioPlayer.current.currentTime = 0;
      });
    }

    return () => {
      if (!audioPlayer.current) return;
      audioPlayer.current.removeEventListener('loadedmetadata', () => {
        const seconds = audioPlayer.current?.duration || 0;
        setDuration(seconds);
      });

      audioPlayer.current?.removeEventListener('waiting', () => {
        setIsLoading(true);
      });

      audioPlayer.current?.removeEventListener('playing', () => {
        setIsLoading(false);
      });

      audioPlayer.current?.removeEventListener('ended', () => {
        if (!audioPlayer.current) return;
        audioPlayer?.current.pause();
        setIsPlaying(false);
        audioPlayer.current.currentTime = 0;
      });
    };
  }, []);

  const value = useMemo(
    (): MusicPlayerValue => ({
      isPlaying,
      isMute,
      togglePlayPause,
      toggleMute,
      toggleFullScreen,
      isFullScreen,
      isLoading,
      duration,
      setDuration,
      currentTime,
      setCurrentTime,
      audioPlayer,
      audioSrc,
      setAudioSrc,
      setIsHidden,
      isHidden,
      setIsPlaying,
      musicPlayerDisabled,
      setMusicPlayerDisabled,
      setIsVideo,
    }),
    [
      isPlaying,
      isMute,
      togglePlayPause,
      toggleMute,
      toggleFullScreen,
      isFullScreen,
      isLoading,
      duration,
      setDuration,
      currentTime,
      setCurrentTime,
      audioPlayer,
      audioSrc,
      setAudioSrc,
      setIsHidden,
      isHidden,
      setIsPlaying,
      musicPlayerDisabled,
      setMusicPlayerDisabled,
      setIsVideo,
    ],
  );

  return (
    <MusicPlayer.Provider value={value}>
      <audio preload="metadata" ref={audioPlayer}>
        <source src={audioSrc} />
      </audio>
      {children}
      <VisibilityBox show={[false, false, true]}>
        {_showMusicPlayer && !isVideo ? (
          <ContentContainerBox>
            <Box position="absolute" left="zero" right="zero" bottom="zero">
              <MusicTrackSlider
                isPlaying={isPlaying}
                duration={duration}
                currentTime={currentTime}
                setCurrentTime={setCurrentTime}
                audioPlayer={audioPlayer}
              />
              <MusicTrackPlayer
                isPlaying={isPlaying}
                isLoading={isLoading}
                duration={duration}
                currentTime={currentTime}
                togglePlayPause={togglePlayPause}
                fullPagePlayer
                musicPlayerDisabled={musicPlayerDisabled}
              />
            </Box>
          </ContentContainerBox>
        ) : null}
      </VisibilityBox>
    </MusicPlayer.Provider>
  );
};

export const useMusicPlayer = (): MusicPlayerValue => {
  const context = useContext(MusicPlayer);

  if (!context) {
    throw new Error(
      'useMusicPlayer must be used within an MusicPlayerProvider',
    );
  }

  return context;
};
