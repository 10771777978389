import React from 'react';
import {colors, untypedColors} from '../theme/tokens/colors';

export interface PolygonIconProps {
  color?: keyof typeof colors | (string & Record<string, never>);
  size?: number;
}

export const PolygonIcon = React.memo<PolygonIconProps>(
  ({color = 'outlines', size = 32}) => (
    <svg
      width={size}
      height={size}
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.9008 9.14752C17.517 8.9282 17.0235 8.9282 16.5849 9.14752L13.5144 10.9569L11.4308 12.1084L8.41514 13.9178C8.03133 14.1371 7.53786 14.1371 7.09922 13.9178L4.74151 12.4922C4.3577 12.2728 4.08355 11.8342 4.08355 11.3407V8.59922C4.08355 8.16057 4.30287 7.72193 4.74151 7.44778L7.09922 6.07702C7.48303 5.8577 7.9765 5.8577 8.41514 6.07702L10.7728 7.50261C11.1567 7.72193 11.4308 8.16057 11.4308 8.65405V10.4634L13.5144 9.25718V7.39295C13.5144 6.95431 13.295 6.51567 12.8564 6.24151L8.46997 3.66449C8.08616 3.44517 7.59269 3.44517 7.15405 3.66449L2.65796 6.29634C2.21932 6.51567 2 6.95431 2 7.39295V12.547C2 12.9856 2.21932 13.4243 2.65796 13.6984L7.09922 16.2755C7.48303 16.4948 7.9765 16.4948 8.41514 16.2755L11.4308 14.5209L13.5144 13.3146L16.53 11.5601C16.9138 11.3407 17.4073 11.3407 17.846 11.5601L20.2037 12.9308C20.5875 13.1501 20.8616 13.5888 20.8616 14.0822V16.8238C20.8616 17.2624 20.6423 17.701 20.2037 17.9752L17.9008 19.346C17.517 19.5653 17.0235 19.5653 16.5849 19.346L14.2272 17.9752C13.8433 17.7559 13.5692 17.3172 13.5692 16.8238V15.0692L11.4856 16.2755V18.0849C11.4856 18.5235 11.705 18.9621 12.1436 19.2363L16.5849 21.8133C16.9687 22.0326 17.4621 22.0326 17.9008 21.8133L22.342 19.2363C22.7258 19.017 23 18.5783 23 18.0849V12.876C23 12.4373 22.7807 11.9987 22.342 11.7245L17.9008 9.14752Z"
        fill={untypedColors[color] || color}
      />
    </svg>
  ),
);
