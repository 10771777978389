import React from 'react';
import {colors, untypedColors} from '../theme/tokens/colors';

export interface ZilliqaIconProps {
  color?: keyof typeof colors | (string & Record<string, never>);
  size?: number;
}

export const ZilliqaIcon = React.memo<ZilliqaIconProps>(
  ({color = 'outlines', size = 32}) => (
    <svg
      width={size}
      height={size}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_810_786)">
        <path
          d="M10.3499 21.6079C13.8537 23.2374 17.2047 24.8047 20.5707 26.3484C21.0678 26.5765 21.2669 26.8402 21.243 27.3987C21.192 28.5542 21.2265 29.7141 21.2265 31.0044C20.912 30.8711 20.6725 30.7792 20.4419 30.6696C15.5027 28.3339 10.5615 25.9997 5.61836 23.667C5.18114 23.4611 4.9775 23.2315 4.99996 22.7248C5.04488 21.7145 5.03889 20.7012 4.99996 19.6909C4.982 19.2021 5.1497 18.9502 5.59889 18.7384C8.74328 17.2392 11.8562 15.7059 14.9826 14.1816C15.2192 14.066 15.4483 13.9342 15.7942 13.7505C15.327 13.5224 14.9602 13.3387 14.5963 13.1579C11.6017 11.7239 8.59505 10.2825 5.58243 8.86185C5.16467 8.66482 4.99398 8.4352 5.00746 7.973C5.0404 6.78788 5.01793 5.60274 5.01793 4.29614C5.3174 4.42354 5.536 4.508 5.74413 4.60577C10.7108 6.94195 15.6744 9.28849 20.65 11.6054C21.1082 11.8187 21.2624 12.0632 21.249 12.5491C21.21 13.4853 21.2025 14.426 21.249 15.3637C21.2759 15.8867 21.0797 16.1459 20.6261 16.3741C17.4083 17.9932 14.2025 19.6332 10.9938 21.2716C10.8156 21.3545 10.6479 21.4479 10.3499 21.6079Z"
          fill={untypedColors[color] || color}
        />
        <path
          d="M5.0614 3.74663C6.41948 3.16888 7.71765 2.60744 9.02632 2.07117C9.22339 1.98729 9.44504 1.97936 9.64771 2.04894C14.8569 4.56733 20.0571 7.09757 25.428 9.71077C23.9696 10.3033 22.655 10.8411 21.3284 11.3536C21.2026 11.4025 20.9945 11.3003 20.8432 11.2292C15.6525 8.79378 10.4583 6.35538 5.26054 3.91403C5.18883 3.86474 5.12214 3.80868 5.0614 3.74663Z"
          fill={untypedColors[color] || color}
        />
        <path
          d="M25.9491 16.3579C25.9655 16.6957 25.9865 16.9327 25.9865 17.1682C25.9865 20.9192 25.9775 24.6701 26 28.4269C26 28.941 25.8637 29.2254 25.3666 29.4239C24.1688 29.8994 23.0038 30.4327 21.7401 30.9794C21.7251 30.6831 21.7057 30.4772 21.7042 30.2683C21.7042 26.5174 21.7146 22.7665 21.6907 19.0096C21.6907 18.466 21.8254 18.1652 22.354 17.9504C23.5414 17.463 24.7003 16.912 25.9491 16.3579Z"
          fill={untypedColors[color] || color}
        />
        <path
          d="M21.7072 16.0468C21.7072 14.6691 21.7072 13.4232 21.7191 12.1759C21.7326 12.0972 21.764 12.0225 21.811 11.9576C21.8579 11.8926 21.9192 11.8391 21.9902 11.8011C23.2808 11.2471 24.5805 10.7152 25.9805 10.1345C25.9805 11.49 25.9805 12.7344 25.9701 13.9862C25.9633 14.0556 25.9401 14.1225 25.9024 14.1814C25.8647 14.2404 25.8135 14.2897 25.7529 14.3254C24.4458 14.8898 23.1296 15.4424 21.7072 16.0468Z"
          fill={untypedColors[color] || color}
        />
      </g>
      <defs>
        <clipPath id="clip0_810_786">
          <rect
            width="21"
            height="29"
            fill="white"
            transform="translate(5 2)"
          />
        </clipPath>
      </defs>
    </svg>
  ),
);
