import React from 'react';
import {colors} from '../theme/tokens/colors';

export interface WalletConnectIconProps {
  color?: keyof typeof colors;
  size?: number;
}

export const WalletConnectIcon = React.memo<WalletConnectIconProps>(
  ({color = 'warningPrimary', size = 32}) => (
    <svg
      width={size}
      height={size}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.8777 24.4986C21.5972 24.4906 21.4261 24.3027 21.2448 24.1259C19.6246 22.5449 17.9872 20.9718 16.3761 19.3777C16.0723 19.0764 15.9063 19.0884 15.6106 19.3777C13.9985 20.9718 12.3702 22.5439 10.7399 24.1239C10.2336 24.6171 10.0219 24.6201 9.52474 24.134C7.17546 21.8531 4.82718 19.5716 2.47993 17.2894C1.83286 16.6596 1.83894 16.5139 2.50524 15.8831L3.71026 14.739C4.17506 14.301 4.39378 14.3 4.85959 14.751C6.49801 16.3421 8.1415 17.9272 9.76473 19.5334C10.0614 19.8277 10.2326 19.8348 10.5313 19.5334C12.1424 17.9393 13.7717 16.3662 15.401 14.7862C15.9225 14.2839 16.1189 14.2839 16.6566 14.8073C18.2768 16.3883 19.9142 17.9604 21.5253 19.5545C21.8159 19.8428 21.979 19.8197 22.2564 19.5465C23.8695 17.9543 25.4968 16.3793 27.1261 14.7992C27.6598 14.2809 27.8289 14.2819 28.3747 14.8073C28.8223 15.2382 29.2729 15.6671 29.7134 16.1041C30.0891 16.4767 30.0972 16.7188 29.7265 17.0804C27.2726 19.4711 24.8177 21.8598 22.3617 24.2465C22.2352 24.3842 22.0639 24.4735 21.8777 24.4986V24.4986Z"
        fill={colors[color] || color}
      />
      <path
        d="M15.9503 10.9439C13.5898 10.956 11.596 11.8289 9.95149 13.4933C9.1495 14.303 9.29127 14.309 8.44775 13.4933C8.03663 13.0966 7.62247 12.7048 7.21944 12.301C6.85085 11.9324 6.85388 11.6792 7.21133 11.3136C9.19607 9.2815 11.5656 7.94453 14.4151 7.603C18.1537 7.15399 21.4407 8.19565 24.197 10.7702C25.2097 11.7144 25.2947 11.7515 24.2041 12.7309C23.8295 13.0674 23.4639 13.415 23.1206 13.7826C22.8168 14.1061 22.5789 14.091 22.2862 13.7726C21.4488 12.8625 20.4878 12.1122 19.3395 11.619C18.2717 11.1553 17.1157 10.9251 15.9503 10.9439V10.9439Z"
        fill={colors[color] || color}
      />
    </svg>
  ),
);
