import React from 'react';
import {Box} from '../box';

interface VerticalDividerProps {
  color?:
    | 'outlines'
    | 'outlinesInvert'
    | 'brandPrimary'
    | 'grey12'
    | 'grey18'
    | 'grey24'
    | 'grey32'
    | 'gray64';
}

export const VerticalDivider: React.FC<VerticalDividerProps> = ({color}) => {
  return <Box h="100%" w="1px" backgroundColor={color || 'outlines'} />;
};
