import {useContext, createContext} from 'react';
import {SmartContractContextValue} from './types';

export const SmartContractContext =
  createContext<SmartContractContextValue | null>(null);

export const useSmartContractContext = (): SmartContractContextValue => {
  const context = useContext(SmartContractContext);
  if (!context) {
    throw new Error(
      'useSmartContractContext must be used within a SmartContractContext',
    );
  }
  return context;
};

/**
 * This is an example of how to read from the smart contract.
 *
 * @example
 * const {contract} = useSmartContractContext();
 *
 * useEffect(() => {
 *  (async () => {
 *    const loadedContract = await contract;
 *
 *    if (!loadedContract) return;
 *
 *    await loadedContract.salePrice(Number(data[0].tokenId)).toString();
 *   })();
 *  }, [data, contract]);
 */
