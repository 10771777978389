/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable import/no-cycle */
/* eslint-disable react/jsx-no-undef */
import React from 'react';
import {Box} from '../../box';
import {Typography} from '../../typography';
import {FrameworkLink, UnstyledHtmlAnchor} from '../../link-renderer';
import {ContentContainerBox} from '../../content-container-box';
import {SocialURL} from '../../footer/types';
import {
  ABOUTS,
  ADVISORYS,
  MARKETPLACES,
  PROTOCOLS,
} from './navbar-block-constants';
import {TransparentButton} from '../../button/transparent-button';

export interface NavBarInfoProps {
  footerSocialLinks?: SocialURL[];
  menuItems?: any[];
}

const MOBILE_HEIGHT = 670;
const WEB_HEIGHT = 260;

export const NavbarLink: React.FC<{
  url: string;
  title: string;
  isNew: boolean;
}> = ({url, title, isNew}) => {
  if (isNew && url) {
    return (
      <TransparentButton onPress={() => window.open(url, '_blank')}>
        <Box
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          marginBottom="two"
        >
          <Typography color="textDefault" fontFamily="abc" textStyle="s">
            {title}
          </Typography>
        </Box>
      </TransparentButton>
    );
  }
  if (url === '-') {
    return (
      <Box
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        marginBottom="two"
      >
        <Typography color="textLow" textStyle="s">
          {title} (coming soon)
        </Typography>
      </Box>
    );
  }
  return (
    <FrameworkLink href={url === '-' ? '' : url}>
      <TransparentButton onPress={() => null}>
        <Box
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          marginBottom="two"
        >
          <Typography color="textDefault" fontFamily="abc" textStyle="s">
            {title}
          </Typography>
        </Box>
      </TransparentButton>
    </FrameworkLink>
  );
};

const NAVBAR_POPUP_ITEMS = [
  {title: 'Marketplace', subItems: MARKETPLACES},
  {title: 'TRAXX Studios', subItems: PROTOCOLS},
  {title: 'Advisory', subItems: ADVISORYS},
  {title: 'About', subItems: ABOUTS},
];

export const NavBarInfo: React.FC<NavBarInfoProps> = ({footerSocialLinks}) => (
  <ContentContainerBox>
    <Box overflow="hidden" minHeight={[WEB_HEIGHT]}>
      <Box height="100%" width="100%" justifyContent="flex-start">
        <Box
          paddingTop={['eight']}
          paddingX={['zero', 'four']}
          flexDirection={['column', 'row']}
          alignItems={['flex-start', 'flex-start']}
          flex={['none', '1']}
        >
          <Box
            pb="three"
            flexDirection={['column', 'row']}
            flex={['none', '1']}
            width={['100%', 'auto']}
          >
            {NAVBAR_POPUP_ITEMS.map((e) => (
              <Box flex={['none', '1']} paddingX={['three', 'four']}>
                <Box mb="five">
                  <Typography color="textLow" fontFamily="abc">
                    {e.title}
                  </Typography>
                </Box>
                {e.subItems.map((link: any) => (
                  <NavbarLink
                    key={link.title}
                    url={link.url}
                    title={link.title}
                    isNew={link.isNew}
                  />
                ))}
              </Box>
            ))}
            <Box
              borderTopColor="outlinesInvert"
              borderTopStyle="solid"
              borderTopWidth="one"
              width="100%"
              display={['flex', 'none']}
              marginY="three"
            />
            <Box flex={['none', '1']} paddingX={['three', 'four']}>
              <Box marginBottom="five">
                <Typography color="textLow">Socials</Typography>
              </Box>
              {footerSocialLinks &&
                footerSocialLinks.map((link) => (
                  <Box marginBottom="s" key={link.socialName}>
                    <UnstyledHtmlAnchor
                      href={link.url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Box flexDirection="row" alignItems="center">
                        {link.icon}
                        <Box pl="five">
                          <Typography textStyle="s" color="textDefault">
                            {link.socialName}
                          </Typography>
                        </Box>
                      </Box>
                    </UnstyledHtmlAnchor>
                  </Box>
                ))}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  </ContentContainerBox>
);
