import React from 'react';
import {colors} from '../theme/tokens/colors';

export interface ErrorIconProps {
  color?: keyof typeof colors;
  size?: number;
}

export const ErrorIcon = React.memo<ErrorIconProps>(
  ({color = 'warningPrimary', size = 32}) => (
    <svg
      width={size}
      height={size}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.9999 7.98675L26.0399 25.3334H5.95992L15.9999 7.98675ZM15.9999 2.66675L1.33325 28.0001H30.6666L15.9999 2.66675ZM17.3333 21.3334H14.6666V24.0001H17.3333V21.3334ZM17.3333 13.3334H14.6666V18.6667H17.3333V13.3334Z"
        fill={colors[color] || color}
      />
    </svg>
  ),
);
