import {createUtil, PropsFromUtils} from '../utils-utilities';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const opacity = <
  Media extends Record<any, string> = Record<any, string>,
>(
  media: Media,
) => ({
  opacity: createUtil(['opacity'], media),
});

export type OpacityUtilsProps = PropsFromUtils<ReturnType<typeof opacity>>;
