import {Address, BaseContract as EthContract, ZilliqaContract} from '@ttx/core';
import {BlockchainNetworkDetail, PaymentToken} from '../../server/router/types';
import {WalletNetwork} from '../authentication-context/types';

export const getBlockchainNetworkDetailForNetwork = (
  network: WalletNetwork,
  blockchainNetworkDetailList: BlockchainNetworkDetail[],
): BlockchainNetworkDetail | undefined =>
  blockchainNetworkDetailList.find(
    (blockchainNetworkDetail) => blockchainNetworkDetail.network === network,
  );

export const isWeb3Contract = (
  contract: EthContract | ZilliqaContract,
): contract is EthContract =>
  'callStatic' in contract && 'interface' in contract;

export const isZilliqaContract = (
  contract: EthContract | ZilliqaContract,
): contract is ZilliqaContract => !isWeb3Contract(contract);

export const getPaymentTokenAddressFromResponseFragmentForSymbol = (
  symbol: string,
  responseFragment: Record<Address, PaymentToken>,
): Address | null => {
  const addresses = Object.keys(responseFragment);
  let addressForPaymentToken = null;

  addresses.forEach((address) => {
    if ((responseFragment as any)[address]?.symbol === symbol) {
      addressForPaymentToken = address;
    }
  });

  return addressForPaymentToken;
};

export const areAddressesEqual = (
  address1: Address,
  address2: Address,
): boolean => new RegExp(address1, 'i').test(address2);
