const padTo2Digits = (num: number) => {
  return num.toString().padStart(2, '0');
};

export const formatDate: (date: Date) => string = (date) => {
  return [
    padTo2Digits(date.getDate()),
    padTo2Digits(date.getMonth() + 1),
    date.getFullYear(),
  ].join('/');
};

export const capitalize: (str: string) => string = (str) => {
  return str[0].toUpperCase() + str.slice(1).toLowerCase();
};
