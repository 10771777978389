import React from 'react';
import {Box} from '../box';
import {colors} from '../theme/tokens/colors';

export interface FooterLogoIconProps {
  width?: number | number[];
  color?: keyof typeof colors;
}

export const FooterLogoIcon = React.memo<FooterLogoIconProps>(
  ({width = 'auto', color = 'textDefault'}) => (
    <Box width={width}>
      <svg viewBox="0 0 271 41" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_5291_107714)">
          <path
            d="M25.356 25.3405C25.356 16.2012 31.2561 9.74557 39.6393 9.74557C48.0225 9.74557 53.9276 16.2062 53.9276 25.3405C53.9276 34.7233 47.9586 41 39.6393 41C31.3201 41 25.356 34.7233 25.356 25.3405ZM48.5633 25.3405C48.5633 18.4575 45.0675 13.8853 39.6393 13.8853C34.2111 13.8853 30.7202 18.4575 30.7202 25.3405C30.7202 32.2235 34.0931 36.9199 39.6393 36.9199C45.1855 36.9199 48.5633 32.2285 48.5633 25.3405Z"
            fill={colors[color] || color}
          />
          <path
            d="M85.179 40.0856H78.1233L67.2719 26.7419L64.8135 29.1821V40.0856H59.6165V0H64.8037V23.2085C65.5265 22.4183 66.3082 21.6281 67.0949 20.8926L76.9777 10.7196H83.4876L70.8415 23.2085L85.179 40.0856Z"
            fill={colors[color] || color}
          />
          <path
            d="M85.1592 25.2809C85.1592 16.2658 91.0053 9.74557 98.9607 9.74557C107.644 9.74557 112.403 15.8981 112.403 25.3405V26.3792H90.3415C90.4644 33.0783 94.3192 36.9199 99.3245 36.9199C102.879 36.9199 106.257 35.5184 106.98 31.006H112.103C111.499 36.1893 107.521 41 99.2655 41C90.5824 41 85.1592 35.4538 85.1592 25.2809ZM106.857 22.6618C106.857 17.4238 104.025 13.8853 98.9607 13.8853C94.0783 13.8853 90.8873 17.7269 90.5234 22.6618H106.857Z"
            fill={colors[color] || color}
          />
          <path
            d="M117.33 40.0856V10.7196H122.512V14.6954C124.022 12.136 127.517 9.7605 131.436 9.7605C138.128 9.7605 142.165 14.0245 142.165 21.5784V40.1005H136.982V22.2344C136.982 16.6882 135.173 13.8853 130.59 13.8853C125.526 13.8853 122.512 17.7865 122.512 22.4183V40.0856H117.33Z"
            fill={colors[color] || color}
          />
          <path
            d="M168.066 40.0856V10.7196H173.249V15.5949C174.724 12.2404 177.89 9.74558 182.35 9.74558C182.793 9.7382 183.236 9.75813 183.677 9.80522V14.9836C183.139 14.9024 182.595 14.8625 182.05 14.8644C176.445 14.8644 173.249 18.0301 173.249 24.5503V40.0856H168.066Z"
            fill={colors[color] || color}
          />
          <path
            d="M185.049 32.0446C185.049 26.3792 189.509 23.7601 194.691 22.9053L200.901 21.9114C203.128 21.5436 203.851 20.7535 203.851 18.9843C203.851 15.7589 201.393 13.8655 198.005 13.8655C193.546 13.8655 191.254 16.246 191.122 19.8987H185.949C186.131 14.5364 190.291 9.72571 197.582 9.72571C204.874 9.72571 208.916 14.0493 208.916 20.8131V40.0657H203.974V36.0899C202.529 39.1364 199.392 40.9652 195.055 40.9652C189.509 40.9652 185.049 37.551 185.049 32.0198V32.0446ZM203.851 28.9733V24.6447C203.492 25.1964 202.406 25.5592 200.66 25.9269L196.442 26.7171C193 27.388 190.409 28.9137 190.409 32.0198C190.409 35.1258 192.823 36.9 195.778 36.9C200.419 36.9 203.851 33.7293 203.851 28.9783"
            fill={colors[color] || color}
          />
          <path
            d="M212.5 40.0856L223.41 25.0373L213.085 10.7197H219.428L225.097 19.1681C225.637 19.9633 226.178 20.6889 226.66 21.4244L228.135 19.2278L233.922 10.6998H239.95L229.399 25.0175L240.368 40.0657H234.08L227.934 30.9861C227.432 30.1665 226.889 29.3736 226.306 28.6106C225.765 29.4057 225.22 30.1959 224.738 30.9861L218.533 40.0657L212.5 40.0856Z"
            fill={colors[color] || color}
          />
          <path
            d="M243.151 40.0856L254.062 25.0373L243.736 10.7196H250.064L255.733 19.1681C256.274 19.9633 256.815 20.6888 257.302 21.4244L258.747 19.2278L264.564 10.7196H270.592L260.04 25.0373L271.015 40.0856H264.731L258.58 31.0059C258.078 30.1863 257.535 29.3934 256.953 28.6304C256.412 29.4256 255.866 30.2158 255.384 31.0059L249.179 40.0856H243.151Z"
            fill={colors[color] || color}
          />
          <path
            d="M18.6643 7.59866H13.4771V40.0856H18.6643V7.59866Z"
            fill={colors[color] || color}
          />
          <path
            d="M32.1412 0H0V5.24303H32.1412V0Z"
            fill={colors[color] || color}
          />
          <path
            d="M157.318 7.59866H152.131V40.0856H157.318V7.59866Z"
            fill={colors[color] || color}
          />
          <path
            d="M170.795 0H138.654V5.24303H170.795V0Z"
            fill={colors[color] || color}
          />
        </g>
        <defs>
          <clipPath id="clip0_5291_107714">
            <rect width="271" height="41" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </Box>
  ),
);
