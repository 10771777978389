import {ViewStyle} from 'react-native';
import {
  propertyToVariant,
  mapArrayToObject,
  VariantProps,
} from '../variant-utilities';
import {theme} from '../../theme';

export const position = {
  ...propertyToVariant(
    'position',
    mapArrayToObject<NonNullable<ViewStyle['position']>>([
      'absolute',
      'relative',
    ]),
  ),
  ...propertyToVariant('zIndex', theme.zIndices),

  ...propertyToVariant('top', theme.space),
  ...propertyToVariant('right', theme.space),
  ...propertyToVariant('bottom', theme.space),
  ...propertyToVariant('left', theme.space),
};

export type PositionProps = VariantProps<typeof position>;
