import React from 'react';
import {colors} from '../theme/tokens/colors';

export interface MoreIconProps {
  color?: keyof typeof colors;
  size?: number;
}

export const MoreIcon = React.memo<MoreIconProps>(
  ({color = 'warningPrimary', size = 32}) => (
    <svg
      width={size}
      height={size}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z"
        fill={colors[color] || color}
      />
    </svg>
  ),
);
