import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import {ANALYTICS_STATE, persistAnalytics, runAnalytics} from './utils';

interface ContextType {
  analyticsState: ANALYTICS_STATE;
  setCookiesResponse: React.Dispatch<ANALYTICS_STATE | null>;
}

const AnalyticsContext = createContext<ContextType>({
  setCookiesResponse: () => null,
  analyticsState: ANALYTICS_STATE.UNKNOWN,
});

interface AnalyticsContextProps {
  children?: React.ReactNode;
}

const AnalyticsContextProvider: React.FC = ({
  children,
}: AnalyticsContextProps) => {
  const [analyticsState, setAnalyticsState] = useState(ANALYTICS_STATE.UNKNOWN);

  {/* @ts-expect-error type fix */}
  const setCookiesResponse = useCallback((state) => {
    persistAnalytics(state);
    setAnalyticsState(state);
  }, []);

  useEffect(() => {
    setAnalyticsState(runAnalytics());
  }, [analyticsState]);

  const value = {
    setCookiesResponse,
    analyticsState,
  };

  return (
    <AnalyticsContext.Provider value={value}>
      {children}
    </AnalyticsContext.Provider>
  );
};

const useAnalyticsContext: () => ContextType = () =>
  useContext(AnalyticsContext);

export {AnalyticsContextProvider, useAnalyticsContext};
