import React, {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import {NftToken} from '../../../../../apps/web/server/router/collection/types';
import {Transaction} from '../../../../../apps/web/server/router/user/types';
import {formatAddress} from '../../../../../apps/web/components/right-side-drawer/utils';
import {Box} from '../../box';
import {Typography} from '../typography';
import {
  ArrowRightIcon,
  CloseIcon,
  CopyIcon,
  RadioOffIcon,
  TickIcon,
  WarningIcon,
} from '../../icons';
import {TransparentButton} from '../../button/transparent-button';
import {HorizontalDivider} from '../../divider';
import {FrameworkImage} from '../../image-renderer';
import {Button} from '../../button/button';
import {ActivityIndicator} from '../../activity-indicator';
import {VisibilityBox} from '../../box/visibility-box';

interface Log {
  _id: string;
  paymentId: string;
  status:
    | 'created'
    | 'failedOnCircle'
    | 'failedValidation'
    | 'processingOnKMS'
    | 'failedOnKMS'
    | 'passedOnKMS'
    | 'failedOnBlockchain'
    | 'passedOnBlockchain'
    | 'chargedback'
    | string;
  nft?: NftToken;
  currency?: string;
  email?: string;
  listingId?: string;
  purchaseQuantity?: string;
}

export interface PurchaseHistoryLogProps {
  onClose: () => void;
  onRefreshPurchaseHistory: () => void;
  onTryAgain: (slug: string) => void;
  purchaseHistoryData?: Transaction[];
  formattedWalletAddress: string | null;
  loading?: boolean;
}

interface StatusWrapperProps {
  icon: ReactNode;
  text: string;
}

const StatusWrapper = ({icon, text}: StatusWrapperProps) => {
  return (
    <Box flexDirection="row" alignItems="center">
      <Box mr="s" width={14} height={14}>
        <>{icon}</>
      </Box>
      <Typography
        textStyle="s"
        css={{
          color: 'var(--studio-primaryText)',
        }}
      >
        {text}
      </Typography>
    </Box>
  );
};

export const PurchaseHistoryLog: React.FC<PurchaseHistoryLogProps> = ({
  onClose,
  onRefreshPurchaseHistory,
  onTryAgain,
  formattedWalletAddress,
  purchaseHistoryData,
  loading,
}) => {
  const formatStatus = useCallback((status: Log['status']) => {
    switch (status) {
      case 'processingOnKMS':
      case 'created':
      case 'passedOnKMS': {
        return (
          <StatusWrapper icon={<RadioOffIcon size={14} />} text="Processing" />
        );
      }
      case 'failedOnCircle':
      case 'failedValidation':
      case 'failedOnKMS':
      case 'failedOnBlockchain': {
        return <StatusWrapper icon={<WarningIcon size={12} />} text="Failed" />;
      }
      case 'passedOnBlockchain': {
        return (
          <StatusWrapper icon={<TickIcon size={12} />} text="Successful" />
        );
      }
      default:
        return null;
    }
  }, []);

  const [idCopied, setIdCopied] = useState<string | null>(null);

  useEffect(() => {
    if (idCopied) setTimeout(() => setIdCopied(null), 4000);
  }, [idCopied]);

  const displayContents = useMemo(() => {
    if (loading) {
      return (
        <Box justifyContent="center" alignItems="center" flex={1} height="100%">
          <ActivityIndicator size={40} duration={2} />
        </Box>
      );
    }
    if (purchaseHistoryData?.length) {
      return (
        <Box>
          {purchaseHistoryData?.map(
            ({txnHash, status, purchaseQuantity, nft: nftList}) => {
              const copyToClipboard = () => {
                if (txnHash) {
                  navigator.clipboard.writeText(txnHash);
                  setIdCopied(txnHash);
                }
              };
              const artist =
                nftList[0]?.properties?.artist ||
                nftList[0]?.minterDetails[0]?.user?.displayName;
              const quantity = purchaseQuantity ? (
                <Box ml={['s', 'zero']}>
                  <Typography
                    textStyle="s"
                    css={{
                      color: 'var(--studio-secondaryText)',
                    }}
                  >
                    {`x${purchaseQuantity}`}
                  </Typography>
                </Box>
              ) : null;
              return (
                <>
                  {nftList.map((nft) => (
                    <React.Fragment key={nft._id}>
                      <Box flex="1" flexDirection="row">
                        <Box
                          width={['120px', '72px']}
                          height={['120px', '72px']}
                        >
                          {nft?.image ? (
                            <FrameworkImage
                              src={nft?.image}
                              alt={nft?.name}
                              layout="fill"
                              objectFit="cover"
                            />
                          ) : null}
                        </Box>
                        <Box flex="1" flexDirection={['column', 'row']} m="lg">
                          <VisibilityBox show={[false, true]}>
                            <Box flex="1.5" mr="md">
                              <Box>
                                <Typography
                                  textStyle="s"
                                  css={{
                                    color: 'var(--studio-secondaryText)',
                                  }}
                                  whiteSpace="nowrap"
                                  textOverflow="ellipsis"
                                  overflow="hidden"
                                >
                                  {artist}
                                </Typography>
                                <Typography
                                  textStyle="s"
                                  css={{
                                    color: 'var(--studio-primaryText)',
                                  }}
                                  whiteSpace="nowrap"
                                  textOverflow="ellipsis"
                                  overflow="hidden"
                                >
                                  {nft?.name}
                                </Typography>
                              </Box>
                            </Box>
                          </VisibilityBox>
                          <VisibilityBox show={[true, false]}>
                            <Box flexDirection="row" mb="xs" maxWidth="100%">
                              <Typography
                                css={{
                                  color: 'var(--studio-primaryText)',
                                }}
                                textStyle="s"
                                whiteSpace="nowrap"
                                textOverflow="ellipsis"
                                overflow="hidden"
                              >
                                {artist}, {nft?.name}
                              </Typography>
                              {quantity}
                            </Box>
                          </VisibilityBox>
                          <Box width="thirtyFive">
                            <Box flexDirection="row" alignItems="center">
                              <Typography
                                textStyle="s"
                                css={{
                                  color: 'var(--studio-secondaryText)',
                                }}
                              >
                                {idCopied === txnHash
                                  ? 'Copied'
                                  : formatAddress(txnHash)}
                              </Typography>
                              <Box ml="s">
                                {idCopied === txnHash ? (
                                  <TickIcon size={12} />
                                ) : (
                                  <TransparentButton onPress={copyToClipboard}>
                                    {formatAddress(txnHash) === 'N/A' ? null : (
                                      <CopyIcon
                                        color={
                                          'var(--studio-secondaryText)' as any
                                        }
                                        size={10}
                                      />
                                    )}
                                  </TransparentButton>
                                )}
                              </Box>
                            </Box>
                            <VisibilityBox show={[false, true]}>
                              {quantity}
                            </VisibilityBox>
                          </Box>
                          <Box flex="1" mr="md" mt={['md', 'zero']}>
                            <Typography
                              textStyle="s"
                              css={{
                                color: 'var(--studio-primaryText)',
                              }}
                            >
                              {formatStatus(status)}
                            </Typography>
                            {status?.includes('failed') ? (
                              <Box ml="five">
                                <TransparentButton
                                  onPress={() => onTryAgain(nft.slug)}
                                >
                                  <Typography
                                    css={{
                                      color: 'var(--studio-secondaryText)',
                                    }}
                                    textDecoration="underline"
                                  >
                                    Try Again
                                  </Typography>
                                </TransparentButton>
                              </Box>
                            ) : null}
                          </Box>
                        </Box>
                      </Box>
                      <HorizontalDivider />
                    </React.Fragment>
                  ))}
                </>
              );
            },
          )}
        </Box>
      );
    }
    return (
      <Box justifyContent="center" alignItems="center" flex={1} height="100%">
        <Typography
          textStyle="s"
          css={{
            color: 'var(--studio-primaryText)',
          }}
        >
          You haven&apos;t made any transactions yet.
        </Typography>
      </Box>
    );
  }, [formatStatus, idCopied, loading, onTryAgain, purchaseHistoryData]);

  return (
    <Box width="100%" height="100%">
      <Box
        flex="1"
        borderStyle="solid"
        borderWidth="one"
        borderColor="outlines"
        css={{background: 'var(--studio-backgroundColor)'}}
        justifyContent="space-between"
      >
        <Box
          position="absolute"
          top="0"
          right="0"
          left="0"
          bottom="0"
          css={{background: 'var(--studio-backgroundColor)'}}
          opacity="0.6"
        />
        <Box flex={1} overflowY="scroll">
          <Box
            alignItems="center"
            justifyContent="space-between"
            flexDirection="row"
            pl="lg"
          >
            <Typography
              css={{
                color: 'var(--studio-primaryText)',
                'line-height': '1.8em !important',
              }}
              textStyle="s"
            >
              {formattedWalletAddress}
            </Typography>
            <TransparentButton onPress={onClose}>
              <Box padding="md">
                <CloseIcon size={16} />
              </Box>
            </TransparentButton>
          </Box>
          <HorizontalDivider />
          <Box flex="1" overflowX="hidden">
            {displayContents}
          </Box>
        </Box>
        <Box>
          {/* <HorizontalDivider /> */}
          <Button
            hasBorder
            type="secondary"
            onPress={onRefreshPurchaseHistory}
            leftAccessory={
              <ArrowRightIcon
                color={'var(--studio-secondaryButtonText)' as any}
              />
            }
            buttonStyle={{
              backgroundColor: 'var(--studio-secondaryButton)',
              textColor: 'var(--studio-secondaryButtonText)',
              margin: '8px 6px 8px 6px',
            }}
            hasButtonStyle
          >
            {loading ? 'Refreshing...' : 'Refresh'}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
