import {
  propertyToVariant,
  propertyShorthandToVariant,
  VariantProps,
} from '../variant-utilities';
import {theme} from '../../theme';

export const backgroundColor = {
  ...propertyToVariant('backgroundColor', theme.colors),
  ...propertyShorthandToVariant('bg', ['backgroundColor'], theme.colors),
};

export type BackgroundColorProps = VariantProps<typeof backgroundColor>;

export const textColor = {
  ...propertyToVariant('color', theme.colors),
};

export type TextColorProps = VariantProps<typeof textColor>;
