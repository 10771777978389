import {
  Box,
  Typography,
  HorizontalDivider,
  UnstyledHtmlAnchor,
  Pressable,
  HelpIcon,
  StudioDesignSystem,
} from '@ttx/design-system';
import {usePlausible} from 'next-plausible';
import React, {useState} from 'react';
import {Network} from '../../../contexts/authentication-context/types';
import {PaymentType, WalletConnectionButtonGroup} from './index';

const {GridContainerWithTitle} = StudioDesignSystem;

export interface SignUpInModalProps {
  children?: never;
  onClose: () => void;
  network: Network;
  paymentType: PaymentType;
}

export const SignUpInModalStudio = React.memo<SignUpInModalProps>(
  ({onClose, network, paymentType}) => {
    const [hovered, setHovered] = useState(false);
    const plausible = usePlausible();

    return (
      <GridContainerWithTitle
        title="Connect wallet"
        onModalClose={() => {
          onClose();
          plausible('Connect Wallet Unsuccessful : Close Icon');
        }}
        maxWidth={480}
      >
        <WalletConnectionButtonGroup
          network={network}
          paymentType={paymentType}
        />
        <HorizontalDivider />
        <Box flexDirection="row" p="lg">
          <Box pr={['ten', 'fifteen', 'twenty']}>
            <HelpIcon />
          </Box>
          <Box flex={1}>
            <Typography
              css={{
                color: 'var(--studio-primaryText)',
              }}
              textStyle="s"
            >
              Why do I need a wallet?
            </Typography>
            <Box mt="three">
              <Typography
                css={{
                  color: 'var(--studio-primaryText)',
                }}
                textStyle="s"
              >
                A home for your digital assets
              </Typography>
              <Typography
                css={{
                  color: 'var(--studio-secondaryText)',
                }}
                textStyle="s"
              >
                You can connect with your wallet instead of creating new
                accounts for every website each time. Send, receive, store and
                display digital assets in your wallet like NFTs and Ethereum.
              </Typography>
              <UnstyledHtmlAnchor
                href="https://ttdocs.gitbook.io/faqs/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Pressable
                  onHoverIn={() => setHovered(true)}
                  onHoverOut={() => setHovered(false)}
                >
                  <Typography
                    css={
                      hovered
                        ? {
                            color: 'var(--studio-primaryText)',
                          }
                        : {color: 'var(--studio-secondaryText)'}
                    }
                    textDecoration="underline"
                    textStyle="s"
                  >
                    Learn more
                  </Typography>
                </Pressable>
              </UnstyledHtmlAnchor>
            </Box>
          </Box>
          <Box />
        </Box>
      </GridContainerWithTitle>
    );
  },
);
