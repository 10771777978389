import React from 'react';
import {colors} from '../theme/tokens/colors';

export interface HamburgerIconProps {
  color?: keyof typeof colors;
  size?: number;
}

export const HamburgerIcon = React.memo<HamburgerIconProps>(
  ({color = 'outlines', size = 32}) => (
    <svg
      width={size}
      height={size}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="28"
        y="6.625"
        width="2.25"
        height="24"
        transform="rotate(90 28 6.625)"
        fill={colors[color] || color}
      />
      <rect
        x="28"
        y="14.875"
        width="2.25"
        height="24"
        transform="rotate(90 28 14.875)"
        fill={colors[color] || color}
      />
      <rect
        x="28"
        y="23.125"
        width="2.25"
        height="24"
        transform="rotate(90 28 23.125)"
        fill={colors[color] || color}
      />
    </svg>
  ),
);
