import React from 'react';
import {colors} from '../theme/tokens/colors';

export interface TelegramIconProps {
  color?: keyof typeof colors;
  size?: number;
}

export const TelegramIcon = React.memo<TelegramIconProps>(
  ({color = 'textInvert', size = 18}) => (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 10.5C20 16.0228 15.5228 20.5 10 20.5C4.47715 20.5 0 16.0228 0 10.5C0 4.97715 4.47715 0.5 10 0.5C15.5228 0.5 20 4.97715 20 10.5ZM10.3584 7.88244C9.38571 8.287 7.44178 9.12433 4.52658 10.3944C4.05319 10.5827 3.80521 10.7668 3.78264 10.9469C3.74449 11.2512 4.12559 11.3711 4.64456 11.5343C4.71515 11.5565 4.7883 11.5795 4.86328 11.6038C5.37386 11.7698 6.06069 11.964 6.41774 11.9717C6.74162 11.9787 7.10311 11.8452 7.5022 11.5711C10.226 9.7325 11.632 8.80318 11.7203 8.78314C11.7825 8.76901 11.8688 8.75123 11.9273 8.80321C11.9858 8.85518 11.98 8.9536 11.9738 8.98C11.9361 9.14095 10.4401 10.5317 9.66594 11.2515C9.42459 11.4759 9.2534 11.635 9.2184 11.6714C9.14001 11.7528 9.06011 11.8298 8.98332 11.9038C8.50897 12.3611 8.15326 12.704 9.00301 13.264C9.41137 13.5331 9.73813 13.7556 10.0641 13.9776C10.4201 14.2201 10.7752 14.4619 11.2347 14.7631C11.3517 14.8398 11.4635 14.9195 11.5724 14.9971C11.9867 15.2925 12.359 15.5578 12.8188 15.5155C13.086 15.4909 13.3621 15.2397 13.5022 14.4903C13.8335 12.7193 14.4847 8.88205 14.6352 7.30081C14.6484 7.16228 14.6318 6.98498 14.6185 6.90715C14.6051 6.82932 14.5773 6.71842 14.4761 6.63633C14.3563 6.53911 14.1714 6.51861 14.0886 6.52007C13.7125 6.5267 13.1355 6.72735 10.3584 7.88244Z"
        fill={colors[color] || color}
      />
    </svg>
  ),
);
