import React, {
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {Wallet} from './wallet';
import {PurchaseHistory} from './purchase-history';
import {WalletStudio} from './wallet-studio';
import {PurchaseHistoryStudio} from './purchase-history-studio';

interface RightSideDrawerContextValue {
  isOpen: boolean;
  openWallet: () => void;
  openPurchaseHistory: () => void;
  close: () => void;
  openDrawer: string | null;
}

const RightSideDrawerContext =
  React.createContext<RightSideDrawerContextValue | null>(null);

export const useRightSideDrawer = (): RightSideDrawerContextValue => {
  const contextValue = useContext(RightSideDrawerContext);

  if (!contextValue)
    throw new Error(
      'useRightSideDrawer must be used within a RightSideDrawerProvider',
    );

  return contextValue;
};

export const RightSideDrawerProvider: React.FC<PropsWithChildren | any> = ({
  children,
  isStudioPage,
}) => {
  const [isModalMounted, setIsModalMounted] = useState(false);
  const [isModalShowing, setIsModalShowing] = useState(false);
  const [drawerType, setDrawerType] = useState<string | null>(null);

  const open = useCallback(() => {
    setIsModalMounted(true);
  }, []);

  const close = useCallback(() => {
    setIsModalShowing(false);
  }, []);

  const openPurchaseHistory = useCallback(() => {
    setDrawerType('purchase-history');
    open();
  }, [open]);

  const openWallet = useCallback(() => {
    setDrawerType('wallet');
    open();
  }, [open]);

  const openDrawer = isModalShowing ? drawerType : null;

  const unmountModal = useCallback(() => {
    setIsModalMounted(false);
  }, []);

  const closeOnEscape = useCallback(
    (e: KeyboardEvent) => {
      if (!isModalShowing) return;
      if (e.key !== 'Escape') return;

      close();
    },
    [close, isModalShowing],
  );

  useEffect(() => {
    if (!isModalMounted) return;

    setIsModalShowing(true);
  }, [isModalMounted]);

  useEffect(() => {
    document.addEventListener('keyup', closeOnEscape, false);

    return () => document.removeEventListener('keyup', closeOnEscape, false);
  }, [closeOnEscape]);

  const value = useMemo(
    () => ({
      isOpen: isModalMounted,
      openPurchaseHistory,
      openWallet,
      close,
      openDrawer,
    }),
    [close, isModalMounted, openPurchaseHistory, openWallet, openDrawer],
  );

  return (
    <RightSideDrawerContext.Provider value={value}>
      {children}
      {!isStudioPage ? (
        <>
          {drawerType === 'wallet' && (
            <Wallet
              isModalMounted={isModalMounted}
              isModalShowing={isModalShowing}
              setIsModalShowing={setIsModalShowing}
              onClosePress={close}
              onDrawerCloseAnimationFinish={unmountModal}
            />
          )}
          {drawerType === 'purchase-history' && (
            <PurchaseHistory
              isModalMounted={isModalMounted}
              isModalShowing={isModalShowing}
              onClosePress={close}
              onDrawerCloseAnimationFinish={unmountModal}
            />
          )}
        </>
      ) : (
        <>
          {drawerType === 'wallet' && (
            <WalletStudio
              isModalMounted={isModalMounted}
              isModalShowing={isModalShowing}
              setIsModalShowing={setIsModalShowing}
              onClosePress={close}
              onDrawerCloseAnimationFinish={unmountModal}
            />
          )}
          {drawerType === 'purchase-history' && (
            <PurchaseHistoryStudio
              isModalMounted={isModalMounted}
              isModalShowing={isModalShowing}
              onClosePress={close}
              onDrawerCloseAnimationFinish={unmountModal}
            />
          )}
        </>
      )}
    </RightSideDrawerContext.Provider>
  );
};
