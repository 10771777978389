import React from 'react';
import {colors} from '../theme/tokens/colors';

export interface MediumIconProps {
  color?: keyof typeof colors;
  size?: number;
}

export const MediumIcon = React.memo<MediumIconProps>(
  ({color = 'textInvert', size = 18}) => (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.2812 10.3563C11.2812 13.4985 8.75578 16.0458 5.6407 16.0458C2.52561 16.0458 0 13.4979 0 10.3563C0 7.21457 2.52542 4.6665 5.6407 4.6665C8.75598 4.6665 11.2812 7.21399 11.2812 10.3563Z"
        fill={colors[color] || color}
      />
      <path
        d="M17.4688 10.3565C17.4688 13.3142 16.2061 15.7129 14.6485 15.7129C13.0908 15.7129 11.8281 13.3142 11.8281 10.3565C11.8281 7.3987 13.0906 5 14.6483 5C16.2059 5 17.4686 7.39793 17.4686 10.3565"
        fill={colors[color] || color}
      />
      <path
        d="M19.9993 10.3566C19.9993 13.0061 19.5553 15.1552 19.0074 15.1552C18.4595 15.1552 18.0156 13.0067 18.0156 10.3566C18.0156 7.70664 18.4597 5.55811 19.0074 5.55811C19.5551 5.55811 19.9993 7.70645 19.9993 10.3566Z"
        fill={colors[color] || color}
      />
    </svg>
  ),
);
