import React, {useContext, useMemo} from 'react';
// changed to legacy because of Next.js 13
import type {ImageProps} from 'next/legacy/image';

export type ImageRendererConfig = ImageProps;

export type ImageRenderer = (config: ImageRendererConfig) => React.ReactNode;

interface ImageRendererContextValue {
  renderImage: ImageRenderer;
}

const ImageRendererContext =
  React.createContext<ImageRendererContextValue | null>(null);

interface ImageRendererProviderProps {
  renderImage: ImageRenderer;
  children: React.ReactNode;
}

export const ImageRendererProvider = React.memo(
  ({renderImage, children}: ImageRendererProviderProps) => (
    <ImageRendererContext.Provider value={{renderImage}}>
      {children}
    </ImageRendererContext.Provider>
  ),
);

export const useImageRenderer = (): ImageRendererContextValue => {
  const context = useContext(ImageRendererContext);

  if (!context) throw new Error('Missing ImageRenderer Context');

  return context;
};

export type FrameworkImageProps = ImageRendererConfig;

export const FrameworkImage = React.memo((config: FrameworkImageProps) => {
  const {renderImage} = useImageRenderer();

  const renderedImage = useMemo(
    () => renderImage(config),
    [config, renderImage],
  );

  return <>{renderedImage}</>;
});
