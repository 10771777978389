import React from 'react';
import {Box} from '../box';
import {FooterLogoIcon, TokenTraxxIcon} from '../icons';
import {Typography} from '../typography';
import {FrameworkLink, UnstyledHtmlAnchor} from '../link-renderer';
import {ExternalURL, SocialURL} from './types';
import {ContentContainerBox} from '../content-container-box';
import {theme} from '../theme/theme';
import {Button} from '../button/button';

export interface FooterProps {
  footerExternalLinks: ExternalURL[];
  footerSocialLinks: SocialURL[];
  contact: string;
}

const MOBILE_HEIGHT = 670;
// const MOBILE_ANIMATION_WIDTH = MOBILE_HEIGHT * 3;
const WEB_HEIGHT = 350;
// const WEB_ANIMATION_WIDTH = WEB_HEIGHT * 3;

export const Footer: React.FC<FooterProps> = ({
  footerSocialLinks,
  footerExternalLinks,
  contact,
}) => (
  <ContentContainerBox>
    <Box
      overflow="hidden"
      borderColor="grey24"
      borderStyle="solid"
      minHeight={[MOBILE_HEIGHT, WEB_HEIGHT]}
      css={{
        'border-top-width': '1px !important',
      }}
    >
      <Box height="100%" width="100%" justifyContent="flex-start">
        <Box
          paddingTop={['eight']}
          paddingX={['zero', 'four']}
          flexDirection={['column', 'row']}
          alignItems={['flex-start', 'flex-start']}
          flex={['none', '1']}
        >
          <Box
            flex="1"
            paddingX={['three', 'zero', 'one']}
            paddingY={['zero', 'three', 'one']}
          >
            <Box mb="s">
              <FooterLogoIcon width={[140, 140, 280]} />
            </Box>
            <Typography textStyle={['h2', 'h2', 'h1']} color="textDefault">
              Own Your Sound
            </Typography>
          </Box>
          <Box
            borderLeftColor="outlinesInvert"
            borderLeftStyle="solid"
            borderLeftWidth="one"
            height="100%"
            display={['none', 'flex']}
          />
          <Box
            borderTopColor="outlinesInvert"
            borderTopStyle="solid"
            borderTopWidth="one"
            width="100%"
            display={['flex', 'none']}
            marginY="three"
          />
          <Box
            pb="three"
            flexDirection={['column', 'row']}
            flex={['none', '1']}
            width={['100%', 'auto']}
          >
            <Box flex={['none', '1']} paddingX={['three', 'four']}>
              <Box mb="five">
                <Typography color="textLow">Useful Links</Typography>
              </Box>
              {footerExternalLinks.map((link) => (
                <Box marginBottom="s" key={link.title}>
                  <UnstyledHtmlAnchor
                    href={link.url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Box flexDirection="row" alignItems="center">
                      <Typography textStyle="s" color="textDefault">
                        {link.title}
                      </Typography>
                    </Box>
                  </UnstyledHtmlAnchor>
                </Box>
              ))}
            </Box>
            <Box
              borderTopColor="outlinesInvert"
              borderTopStyle="solid"
              borderTopWidth="one"
              width="100%"
              display={['flex', 'none']}
              marginY="three"
            />
            <Box flex={['none', '1']} paddingX={['three', 'four']}>
              <Box marginBottom="five">
                <Typography color="textLow">Connect with us</Typography>
              </Box>
              {footerSocialLinks.map((link) => (
                <Box marginBottom="s" key={link.socialName}>
                  <UnstyledHtmlAnchor
                    href={link.url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Box flexDirection="row" alignItems="center">
                      {link.icon}
                      <Box pl="five">
                        <Typography textStyle="s" color="textDefault">
                          {link.socialName}
                        </Typography>
                      </Box>
                    </Box>
                  </UnstyledHtmlAnchor>
                </Box>
              ))}
            </Box>
            <Box
              borderTopColor="outlinesInvert"
              borderTopStyle="solid"
              borderTopWidth="one"
              width="100%"
              display={['flex', 'none']}
              marginY="three"
            />
            <Box flex={['none', '1']} paddingX={['three', 'four']}>
              <Box marginBottom="five">
                <Typography color="textLow">Questions?</Typography>
              </Box>
              {/* <Box marginTop="one">
                <Typography color="textDefault">Let&apos;s Chat :</Typography>
              </Box> */}
              <Box>
                {/* <UnstyledHtmlAnchor
                  href="#"
                  target="_blank"
                  rel="noopener noreferrer"
                  data-tf-popup="at6skPIl"
                  data-tf-opacity="100"
                  data-tf-size="100"
                  data-tf-iframe-props="title=TOKENTRAXX CONTACT"
                  data-tf-transitive-search-params
                  data-tf-medium="snippet"
                  data-tf-hidden="hubspot_utk=,hubspot_page_name=,hubspot_page_url="
                >
                  <Typography color="textDefault" textDecoration="underline">
                    {contact}
                  </Typography>
                </UnstyledHtmlAnchor> */}
                <button
                  style={{
                    backgroundColor: theme.colors.brandPrimary,
                    color: theme.colors.textInvert,
                    padding: '6.5px 12px 6.5px 12px',
                    borderRadius: 8,
                    fontSize: 14,
                    fontWeight: 400,
                    fontFamily:
                      '"ABC Diatype Semi Mono", apple-system, sans-serif',
                    width: 140,
                    border: 'none',
                    cursor: 'pointer',
                  }}
                  type="button"
                  data-tf-popup="at6skPIl"
                  data-tf-opacity="100"
                  data-tf-size="100"
                  data-tf-iframe-props="title=TOKENTRAXX CONTACT"
                  data-tf-transitive-search-params
                  data-tf-medium="snippet"
                  data-tf-hidden="hubspot_utk=,hubspot_page_name=,hubspot_page_url="
                >
                  Let&apos;s Chat
                </button>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box
          borderTopWidth="one"
          borderTopColor="outlines"
          borderTopStyle="solid"
        />

        <Box
          flexDirection="row"
          padding="three"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography textStyle="s" color="textDefault">
            TokenTraxx is a trading name of TokenTraxx Music Ltd, registered in
            England and Wales, company number 13753129. ©
            {`${new Date().getFullYear()}`}
            TokenTraxx®. TokenTraxx Music Ltd All rights reserved.
          </Typography>
          <Box ml="seven">
            <TokenTraxxIcon size={32} color="textDefault" />
          </Box>
        </Box>
      </Box>
    </Box>
  </ContentContainerBox>
);
