import React from 'react';
import {
  TickIcon,
  ErrorIcon,
  WarningIcon,
  InfoIcon,
  RadioOffIcon,
} from '../../icons';
import {colors} from '../../theme/tokens/colors';

export type ToastVariants =
  | 'success'
  | 'error'
  | 'info'
  | 'warning'
  | 'paymentSuccess'
  | 'paymentProcessing'
  | 'paymentError';
interface ToastDefaults {
  title: string;
  text: string;
  icon: () => JSX.Element;
  color: keyof typeof colors;
  paymentStatus?: 'complete' | 'failed' | 'processing';
}

export const toastDefaults: {
  [key in ToastVariants]: ToastDefaults;
} = {
  success: {
    title: 'Success',
    icon: () => <TickIcon size={12} color="brandPrimary" />,
    text: 'Wallet connected',
    color: 'textDefault',
  },
  error: {
    title: 'Error',
    icon: () => <ErrorIcon size={12} />,
    text: 'No wallet connected',
    color: 'textLow',
  },
  info: {
    title: 'Info',
    icon: () => <InfoIcon size={12} />,
    text: 'Did you know?',
    color: 'textLow',
  },
  warning: {
    title: 'Warning',
    icon: () => <WarningIcon size={12} />,
    text: 'You disconnected your wallet',
    color: 'textLow',
  },
  paymentSuccess: {
    title: 'Warning',
    icon: () => <TickIcon size={12} color="brandPrimary" />,
    text: 'Payment successful',
    color: 'textLow',
    paymentStatus: 'complete',
  },
  paymentProcessing: {
    title: 'Warning',
    icon: () => <RadioOffIcon size={12} />,
    text: 'Payment processing',
    color: 'textLow',
    paymentStatus: 'processing',
  },
  paymentError: {
    title: 'Warning',
    icon: () => <WarningIcon size={12} />,
    text: 'Payment failed',
    color: 'textLow',
    paymentStatus: 'failed',
  },
};
