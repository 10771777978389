import React from 'react';
import {Box} from '../box';

interface HorizontalDividerProps {
  color?:
    | 'outlines'
    | 'outlinesInvert'
    | 'brandPrimary'
    | 'grey12'
    | 'grey18'
    | 'grey24'
    | 'grey32'
    | 'gray64';
  h?: string;
}

export const HorizontalDivider: React.FC<HorizontalDividerProps> = ({
  color,
  h,
  ...restProps
}: HorizontalDividerProps) => {
  return (
    <Box
      w="100%"
      h={h || '1px'}
      backgroundColor={color || 'outlines'}
      {...restProps}
    />
  );
};
