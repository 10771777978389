/* eslint-disable no-unused-expressions */
/* eslint-disable import/no-cycle */
import React, {useState} from 'react';
import {Typography} from '../typography';
import {Box} from '../box';
import {CommonNavbarProps} from './types';
import {FrameworkLink} from '../link-renderer';
import {TransparentButton} from '../button/transparent-button';
import {ContentContainerBox} from '../content-container-box';
import {NavBarInfo} from './navbar-info';
import {
  footerSocialURLs,
  matchWithRoute,
  NavBarSocialLinks,
} from './navbar-block-constants';
import {Button} from '../button/button';
import {FrameworkImage} from '../image-renderer';

export interface DesktopNavbarProps extends CommonNavbarProps {
  runLogoAnimation: boolean;
}

const STORED_HOVER_STATE = {
  IS_HOVERED: false,
};

export const isActiveNavRouteUtil = (
  currentHref: string,
  subRoutes: string[],
): boolean => {
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < subRoutes.length; i++) {
    if (currentHref.indexOf(subRoutes[i]) > -1) {
      return true;
    }
  }
  return false;
};

export const DesktopNavbar: React.FC<DesktopNavbarProps> = ({
  items,
  currentHref,
  homePageHref = '#',
  walletConnected,
  openWallet,
  openPurchaseHistory,
  // runLogoAnimation,
}) => {
  const [isHovered, setIsHovered] = useState(false);
  return (
    <ContentContainerBox zIndex={2}>
      <Box
        width="100%"
        flexDirection="row"
        borderStyle="solid"
        height="70px"
        borderColor="outlines"
        bg="backgroundBlur"
        borderWidth="one"
        css={{
          'backdrop-filter': 'blur(50px)',
          'border-width': '1px',
        }}
      >
        <Box flex="1" flexDirection="row" alignItems="center">
          <FrameworkLink href={homePageHref}>
            <Box
              w={112}
              ml="twelve"
              height="100%"
              justifyContent="center"
              mr="twelve"
              position="relative"
            >
              <Box height="16px" width="108px">
                <FrameworkImage
                  src="/logo-tt-text.png"
                  alt="background"
                  layout="fill"
                />
              </Box>
              {currentHref === '/' && (
                <Box
                  borderBottomColor="outlines"
                  borderBottomStyle="solid"
                  borderBottomWidth="two"
                  position="absolute"
                  top="0px"
                  left="0px"
                  style={{height: '100%', width: '100%'}}
                />
              )}
              {/* <TTLogoAnimation runAnimation={runLogoAnimation} /> */}
            </Box>
          </FrameworkLink>
          {items.map((item, i) =>
            item && typeof item === 'object' && 'href' in item ? (
              <FrameworkLink key={`${item.text}${item.href}`} href={item.href}>
                <TransparentButton
                  key={`${item.text}${item.href}`}
                  onPress={() => null}
                >
                  <Box
                    paddingX="three"
                    paddingY="six"
                    position="relative"
                    borderBottomColor="outlines"
                    borderBottomStyle="solid"
                    onHoverIn={() => {
                      item.handleHover && item.handleHover();
                      STORED_HOVER_STATE.IS_HOVERED = true;
                      setIsHovered(!item.text?.includes('Polymesh'));
                    }}
                    onHoverOut={() => {
                      STORED_HOVER_STATE.IS_HOVERED = false;
                      setTimeout(() => {
                        if (!STORED_HOVER_STATE.IS_HOVERED) {
                          setIsHovered(false);
                        }
                      }, 500);
                    }}
                    borderBottomWidth="zero"
                  >
                    <Typography color="textDefault" textStyle="s">
                      {item.text}
                    </Typography>
                    {currentHref &&
                      item?.subRoutes &&
                      isActiveNavRouteUtil(currentHref, item.subRoutes) && (
                        <Box
                          borderBottomColor="outlines"
                          borderBottomStyle="solid"
                          borderBottomWidth="two"
                          position="absolute"
                          top="1px"
                          left="0px"
                          style={{height: '100%', width: '100%'}}
                        />
                      )}
                  </Box>
                </TransparentButton>
              </FrameworkLink>
            ) : (
              <Box height="100%" justifyContent="center" key={i.toString()}>
                <>{item}</>
              </Box>
            ),
          )}
        </Box>

        <Box flexDirection="row" alignItems="center" justifyContent="flex-end">
          {currentHref === '/' ||
          (currentHref && matchWithRoute(currentHref)) ? (
            <NavBarSocialLinks />
          ) : (
            <>
              {!walletConnected && (
                <Box marginLeft="two" right="eight">
                  <Button hasBorder={false} type="primary" onPress={openWallet}>
                    Connect wallet
                  </Button>
                </Box>
              )}
            </>
          )}
        </Box>
        {walletConnected &&
          currentHref &&
          currentHref !== '/' &&
          !matchWithRoute(currentHref) && (
            <Box
              flexDirection="row"
              alignItems="center"
              justifyContent="flex-end"
              marginLeft="two"
              right="eight"
            >
              <Button
                hasBorder
                type="secondary"
                buttonStyle={{backgroundColor: '#000 !important'}}
                onPress={openPurchaseHistory}
              >
                Transactions
              </Button>
              <Box marginLeft="three">
                <Button hasBorder type="secondary" onPress={openWallet}>
                  My wallet
                </Button>
              </Box>
            </Box>
          )}
      </Box>
      {isHovered && (
        <Box
          zIndex="1"
          backgroundColor="outlinesInvert"
          marginLeft="one"
          marginRight="one"
          borderBottomColor="outlines"
          borderBottomWidth="one"
          borderBottomStyle="solid"
          onHoverIn={() => {
            STORED_HOVER_STATE.IS_HOVERED = true;
            setIsHovered(true);
          }}
          onHoverOut={() => {
            STORED_HOVER_STATE.IS_HOVERED = false;
            setTimeout(() => {
              if (!STORED_HOVER_STATE.IS_HOVERED) {
                setIsHovered(false);
              }
            }, 500);
          }}
        >
          <NavBarInfo footerSocialLinks={footerSocialURLs} />
        </Box>
      )}
    </ContentContainerBox>
  );
};
