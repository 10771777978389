import React from 'react';
import {Box} from '../box';
import {Button} from '../button/button';
import {Typography} from '../typography';
import {toastDefaults, ToastVariants} from './toast-defaults';

export interface ToastData {
  artist?: string;
  songTitle?: string;
  title?: string;
  text?: string;
  errorMessage?: string;
  transactionHash?: string;
  variant: ToastVariants;
}

export interface ToastProps extends ToastData {
  onClose: () => void;
}

export const Toast: React.FC<ToastProps> = ({
  artist,
  songTitle,
  transactionHash,
  onClose,
  variant,
  title,
  text,
  errorMessage,
}) => {
  return (
    <Box>
      <Box
        position="absolute"
        backgroundColor="surfaceDark"
        h="100%"
        w="100%"
        borderStyle="solid"
        borderColor="outlines"
        borderWidth="one"
      />
      <Box flexDirection="row" padding="three">
        <Box pr={['four', 'fifteen']} pt="xs">
          {toastDefaults[variant].icon()}
        </Box>
        <Box flexDirection={['column', 'row']} flex={1}>
          <Box flex={['zero', '1']}>
            <Typography color="textLow" textStyle="s">
              {artist || title || toastDefaults[variant].title}
            </Typography>
            {!!songTitle && (
              <Box mt="xs">
                <Typography color="textDefault" textStyle="s">
                  {songTitle || ' '}
                </Typography>
              </Box>
            )}
          </Box>
          <Box flex={['zero', '1']} mt={['xs', 'zero']}>
            <Typography color={toastDefaults[variant].color} textStyle="s">
              {transactionHash
                ? `#${transactionHash}`
                : text || toastDefaults[variant].text}
            </Typography>
            {!!toastDefaults[variant].paymentStatus && (
              <Box mt="xs">
                <Typography color="textDefault" textStyle="s">
                  {errorMessage ||
                    `Payment ${toastDefaults[variant].paymentStatus}`}
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
      <Button
        onPress={onClose}
        buttonStyle={{margin: '8px 6px 8px 6px'}}
        type="secondary"
        size="S"
        hasBorder
      >
        Dismiss
      </Button>
    </Box>
  );
};
