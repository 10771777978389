import React from 'react';
import {colors} from '../theme/tokens/colors';

export interface PlayIconProps {
  color?: keyof typeof colors;
  size?: number;
}

export const PlayIcon = React.memo<PlayIconProps>(
  ({color = 'outlines', size = 24}) => (
    <svg
      width={size}
      height={size}
      viewBox="0 0 8 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.924 5L0 0.421997V9.578L7.924 5Z"
        fill={colors[color] || color}
      />
    </svg>
  ),
);
