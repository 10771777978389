import React from 'react';
import {colors} from '../theme/tokens/colors';

export interface DiscordIconProps {
  color?: keyof typeof colors;
  size?: number;
}

export const DiscordIcon = React.memo<DiscordIconProps>(
  ({color = 'textInvert', size = 18}) => (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.9308 1.4629C15.6561 0.877995 14.2892 0.447065 12.8599 0.200254C12.8339 0.195491 12.8079 0.207395 12.7945 0.231203C12.6187 0.543882 12.4239 0.951798 12.2876 1.27242C10.7503 1.04228 9.22099 1.04228 7.71527 1.27242C7.57887 0.944671 7.37707 0.543882 7.20048 0.231203C7.18707 0.208189 7.16107 0.196285 7.13504 0.200254C5.70659 0.446277 4.33963 0.877206 3.06411 1.4629C3.05307 1.46766 3.04361 1.4756 3.03732 1.48591C0.444493 5.35954 -0.265792 9.13797 0.0826501 12.8695C0.0842267 12.8878 0.0944749 12.9053 0.108665 12.9164C1.81934 14.1726 3.47642 14.9353 5.10273 15.4408C5.12876 15.4488 5.15634 15.4393 5.1729 15.4178C5.55761 14.8925 5.90054 14.3385 6.19456 13.756C6.21192 13.7219 6.19535 13.6814 6.15989 13.6679C5.61594 13.4616 5.098 13.21 4.59978 12.9243C4.56037 12.9013 4.55721 12.8449 4.59347 12.8179C4.69831 12.7394 4.80318 12.6576 4.9033 12.5751C4.92141 12.56 4.94665 12.5568 4.96794 12.5664C8.24107 14.0608 11.7846 14.0608 15.0191 12.5664C15.0404 12.5561 15.0657 12.5592 15.0846 12.5743C15.1847 12.6568 15.2896 12.7394 15.3952 12.8179C15.4314 12.8449 15.4291 12.9013 15.3897 12.9243C14.8914 13.2155 14.3735 13.4616 13.8288 13.6671C13.7933 13.6806 13.7775 13.7219 13.7949 13.756C14.0952 14.3377 14.4381 14.8917 14.8157 15.417C14.8315 15.4393 14.8599 15.4488 14.8859 15.4408C16.5201 14.9353 18.1772 14.1726 19.8879 12.9164C19.9028 12.9053 19.9123 12.8886 19.9139 12.8703C20.3309 8.55622 19.2154 4.80878 16.9568 1.4867C16.9513 1.4756 16.9419 1.46766 16.9308 1.4629ZM6.68335 10.5974C5.69792 10.5974 4.88594 9.6927 4.88594 8.58163C4.88594 7.47056 5.68217 6.56585 6.68335 6.56585C7.69239 6.56585 8.49651 7.4785 8.48073 8.58163C8.48073 9.6927 7.68451 10.5974 6.68335 10.5974ZM13.329 10.5974C12.3435 10.5974 11.5316 9.6927 11.5316 8.58163C11.5316 7.47056 12.3278 6.56585 13.329 6.56585C14.338 6.56585 15.1421 7.4785 15.1264 8.58163C15.1264 9.6927 14.338 10.5974 13.329 10.5974Z"
        fill={colors[color] || color}
      />
    </svg>
  ),
);
