/* eslint-disable no-param-reassign */
import React, {
  Dispatch,
  MutableRefObject,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useMemo,
} from 'react';
import {useDrag} from '@use-gesture/react';
import {AnimatedBox, Box} from '../box';
import {TransparentButton} from '../button/transparent-button';
import {PauseIcon} from '../icons/pause';
import {PlayIcon} from '../icons/play';
import {Pressable} from '../pressable/index';
import {useSpring} from '../animation';
import {ActivityIndicator} from '../activity-indicator';
import {Typography} from '../typography';
import {useBreakpoint} from '../use-breakpoint';
import {convertDurationMMSS} from './utils';
import {theme} from '../theme/theme';
import {RightArrowIcon} from '../icons';

/* eslint-disable no-param-reassign */

export interface MusicTrackPlayerProps {
  artist?: string;
  songTitle?: string;
  isPlaying: boolean;
  isLoading: boolean;
  duration: number;
  currentTime: number;
  togglePlayPause: () => void;
  fullPagePlayer?: boolean;
  remixCard?: boolean;
  hidePlayer?: boolean;
  musicPlayerDisabled?: boolean;
  isStudioPage?: boolean;
  onBack?: () => void;
}

export const MusicTrackPlayer: React.FC<MusicTrackPlayerProps> = ({
  artist,
  songTitle,
  isPlaying,
  isLoading,
  duration,
  currentTime,
  togglePlayPause,
  fullPagePlayer = false,
  hidePlayer = false,
  musicPlayerDisabled,
  isStudioPage = false,
  onBack,
}: MusicTrackPlayerProps) => {
  const {currentBreakpoint} = useBreakpoint();
  const isMobile = currentBreakpoint?.toString() === theme.breakpoints[0];
  const isTablet = currentBreakpoint?.toString() === theme.breakpoints[1];
  const isMobileOrTablet = isMobile || isTablet;

  const buttonContents = useMemo(() => {
    if (isLoading) return <ActivityIndicator color="outlines" size={16} />;
    if (isPlaying && !isLoading) return <PauseIcon size={12} />;
    return (
      <Box pl="one">
        <PlayIcon size={12} />
      </Box>
    );
  }, [isLoading, isPlaying]);

  return (
    <Box
      width="100%"
      alignItems="center"
      borderStyle={fullPagePlayer ? 'solid' : undefined}
      borderColor={fullPagePlayer ? 'outlines' : undefined}
      borderWidth={fullPagePlayer ? 'one' : 'zero'}
      bg={['surfaceDark', 'surfaceDark', 'backgroundBlur']}
      css={{
        'backdrop-filter': 'blur(20px)',
      }}
    >
      <Box
        width="100%"
        alignItems="center"
        flexDirection={fullPagePlayer ? 'column' : 'row'}
        justifyContent={fullPagePlayer ? 'center' : 'space-between'}
        paddingX={['three', 'four']}
      >
        {!fullPagePlayer ? (
          <Box marginY="three" flex="1">
            <div style={{display: 'flex', alignItems: 'center'}}>
              {isStudioPage && (
                <Pressable onPress={onBack}>
                  <div style={{transform: 'rotate(180deg)', height: '36px'}}>
                    <svg
                      width={36}
                      height={36}
                      viewBox="0 0 10 9"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M5.65102 7.85707L9.50102 4.00707L5.65102 0.143066H4.12502L7.42902 3.43307H0.499023V4.58107H7.42902L4.12502 7.85707H5.65102Z"
                        fill="#FF6C74 !important"
                        style={{fill: '#FF6C74 !important'}}
                      />
                    </svg>
                  </div>
                </Pressable>
              )}
              {isStudioPage ? (
                <p
                  style={{fontSize: '30px', color: 'var(--ttx-colors-gray66)'}}
                >
                  {artist}
                </p>
              ) : (
                <Typography
                  textStyle={['h2', 'h2', 'heroSmall', 'h1']}
                  fontSize={isStudioPage ? 'h2' : undefined}
                  color="textLow"
                >
                  {artist}
                </Typography>
              )}
            </div>

            {isStudioPage ? (
              <p
                style={{
                  fontSize: '48px',
                  color: 'var(--ttx-colors-gray0)',
                  lineHeight: '48px',
                }}
              >
                {songTitle}
              </p>
            ) : (
              <Typography
                textStyle={['h2', 'h2', 'heroSmall', 'h1']}
                color="textDefault"
              >
                {songTitle}
              </Typography>
            )}
          </Box>
        ) : null}
        {(isMobileOrTablet || fullPagePlayer) && !hidePlayer ? (
          <Box marginY="three">
            <TransparentButton
              onPress={togglePlayPause}
              disabled={musicPlayerDisabled}
            >
              <Box
                bg={isPlaying && !isLoading ? 'outlines' : 'surfaceDark'}
                width={38}
                height={38}
                borderRadius="circle"
                borderWidth="one"
                borderColor="outlines"
                borderStyle="solid"
                alignItems="center"
                justifyContent="center"
              >
                {buttonContents}
              </Box>
            </TransparentButton>
          </Box>
        ) : null}
        {fullPagePlayer ? (
          <Box
            position="absolute"
            justifyContent="center"
            left="three"
            top="zero"
            bottom="zero"
          >
            <Typography color="textLow">
              {convertDurationMMSS(currentTime)}/{convertDurationMMSS(duration)}
            </Typography>
          </Box>
        ) : null}
      </Box>
    </Box>
  );
};

export interface MusicTrackSliderProps {
  isPlaying: boolean;
  duration: number;
  currentTime: number;
  setCurrentTime: Dispatch<SetStateAction<number>>;
  audioPlayer: MutableRefObject<HTMLAudioElement | HTMLVideoElement | null>;
  remixCard?: boolean;
}

export const MusicTrackSlider: React.FC<MusicTrackSliderProps> = ({
  isPlaying,
  duration,
  currentTime,
  setCurrentTime,
  audioPlayer,
  remixCard = false,
}: MusicTrackSliderProps) => {
  const {currentBreakpoint} = useBreakpoint();
  const isMobile = currentBreakpoint?.toString() === theme.breakpoints[0];
  const isTablet = currentBreakpoint?.toString() === theme.breakpoints[1];
  const isMobileOrTablet = isMobile || isTablet;

  const progressBar: any = useRef<HTMLInputElement | null>(null);
  const trackBar = useRef<HTMLDivElement | null>(null);
  const isDragging = useRef<boolean>(false);
  const animationRef = useRef<number | null>(null);

  const [{x}, api] = useSpring(() => {
    return {x: 0};
  });

  const mobilePlayerBar = useSpring({
    top:
      (isPlaying && remixCard) ||
      (isMobileOrTablet && isPlaying) ||
      (!isMobileOrTablet && !remixCard)
        ? 0
        : 42,
    config: {duration: 170},
  });

  const playerBar = useSpring({
    backgroundColor:
      isPlaying || !isMobile || remixCard
        ? 'rgba(1,2,3, 0.55)'
        : 'rgba(1,2,3, 0)',
    backdropFilter:
      isPlaying || remixCard || !isMobile ? 'blur(12px)' : 'blur(0px)',
    config: {duration: 160},
  });

  const changePlayerCurrentTime = useCallback(() => {
    if (!trackBar.current) return;
    if (isDragging.current) return;
    api.set({
      x:
        (progressBar.current?.value / duration) * trackBar.current?.offsetWidth,
    });

    setCurrentTime(progressBar.current.value || 0);
  }, [api, duration, setCurrentTime]);

  const whilePlaying = () => {
    if (!audioPlayer.current) return;
    if (!progressBar.current) return;

    progressBar.current.value = audioPlayer.current.currentTime;
    changePlayerCurrentTime();
    animationRef.current = requestAnimationFrame(whilePlaying);
  };

  const updateAnimation = () => {
    if (isPlaying) {
      animationRef.current = requestAnimationFrame(whilePlaying);
    } else {
      cancelAnimationFrame(animationRef?.current || 0);
    }
  };

  const handleSliderClick = useCallback(
    (e: any) => {
      if (!audioPlayer.current) return;
      if (!trackBar.current) return;
      audioPlayer.current.currentTime =
        (e.nativeEvent.offsetX / trackBar.current?.offsetWidth) * duration;
      progressBar.current.value = audioPlayer.current?.currentTime;
      changePlayerCurrentTime();
    },
    [changePlayerCurrentTime, duration, audioPlayer],
  );

  const bind = useDrag(
    // @ts-expect-error type fix
    ({down, offset: [ox]}) => {
      api.set({
        x: ox,
      });
      isDragging.current = true;
      audioPlayer.current?.pause();
      if (down) return;
      isDragging.current = false;
      audioPlayer.current?.play();
      if (!audioPlayer.current) return;
      if (!trackBar.current) return;
      audioPlayer.current.currentTime =
        (ox / trackBar.current?.offsetWidth) * duration;
      progressBar.current.value = audioPlayer.current?.currentTime;
      changePlayerCurrentTime();
    },
    {
      from: () => {
        return [x.get(), 0];
      },
      bounds: {left: 0, right: trackBar.current?.offsetWidth},
    },
  );

  useEffect(() => {
    progressBar.current.max = audioPlayer.current?.duration;
  }, [audioPlayer]);

  useEffect(() => {
    updateAnimation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPlaying, isMobile]);

  return (
    <AnimatedBox
      overflow="hidden"
      position="relative"
      top="zero"
      left="zero"
      right="zero"
      height={['auto', 'auto', remixCard ? 'auto' : 10]}
      borderStyle="solid"
      borderColor="outlines"
      borderWidth="one"
      borderBottomWidth="zero"
      style={mobilePlayerBar}
    >
      <AnimatedBox
        ref={trackBar as any}
        position="absolute"
        left="zero"
        right="zero"
        top="zero"
        bottom="zero"
        zIndex="1"
        style={playerBar}
      />
      <AnimatedBox
        ref={progressBar as any}
        bg="outlines"
        position="absolute"
        top="zero"
        bottom="zero"
        right="100%"
        width="100%"
        style={{x}}
        zIndex="2"
      />
      <Box
        p="three"
        alignItems="flex-end"
        zIndex="3"
        display={['flex', 'flex', remixCard ? 'flex' : 'none']}
      >
        <Typography color="textInvert">
          {convertDurationMMSS(currentTime)}/{convertDurationMMSS(duration)}
        </Typography>
      </Box>
      <AnimatedBox
        display={['flex', 'flex', remixCard ? 'flex' : 'none']}
        position="absolute"
        paddingY="three"
        pr="three"
        alignItems="flex-end"
        right="zero"
        top="zero"
        bottom="zero"
        zIndex="4"
        overflow="hidden"
        style={{left: x}}
      >
        <Typography color="textDefault">
          {convertDurationMMSS(currentTime)}/{convertDurationMMSS(duration)}
        </Typography>
      </AnimatedBox>
      <Box
        position="absolute"
        top="zero"
        left="zero"
        right="zero"
        overflow="hidden"
        zIndex="5"
      >
        <Pressable
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
          }}
          onPress={handleSliderClick as any}
        />
        <AnimatedBox
          height={[40, 40, remixCard ? 40 : 10]}
          width={60}
          right={30}
          style={
            {
              x,
              // Touch Action Added as per ReadMe instructions from useDrag Hook, Prevents glitching on browser scroll
              touchAction: 'none',
              cursor: 'pointer',
            } as any
          }
          {...(bind() as any)}
        />
      </Box>
    </AnimatedBox>
  );
};
