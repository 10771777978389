import React from 'react';
import {BoxProps} from '../box/types';
import {ModalLayoutBox} from './modal-layout-box';
import {ModalContainer} from './modal-container';

type SomeBoxProps = Omit<BoxProps, 'display'>;
// @ts-expect-error modal fix
export interface ModalProps extends SomeBoxProps {
  children: React.ReactNode;
  visible: boolean;
  onModalClose: () => void;
  closeOnOverlay?: boolean;

  /**
   * Designed for when we wouldn't want the modal mounted outside of the React root
   *
   * This is used in Storybook stories
   *
   * @example
   * // In Storybook
   *
   * __useReactNativeModal={false}
   */
  __useReactNativeModal?: boolean;
}

export const Modal: React.FC<ModalProps> = ({
  __useReactNativeModal = true,
  visible,
  ...rest
}: ModalProps) => (
  // @ts-expect-error modal fix
  <ModalContainer useReactNativeModal={__useReactNativeModal} visible={visible}>
    <ModalLayoutBox {...(rest as any)} />
  </ModalContainer>
);
