import {
  AnimatedBox,
  Box,
  Toast as ToastV1,
  ToastData,
  toastDefaults,
  StudioDesignSystem,
} from '@ttx/design-system';
import React, {
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from 'react';
import {Portal} from '../../components/portal';
import {AnimatableToastData} from './types';
import {useToastAnimation} from './use-toast-animation';

const {Toast} = StudioDesignSystem;

const TOAST_TIMEOUT = 5000;

interface AddToastOptions {
  title?: string;
  timeout?: number;
  onClose?: (id: number) => void;
}

interface ToastContextValue {
  addWarningToast: (text?: string, options?: AddToastOptions) => number;
  addSuccessToast: (text?: string, options?: AddToastOptions) => number;
  addInfoToast: (text: string, options?: AddToastOptions) => number;
  addErrorToast: (text?: string, options?: AddToastOptions) => number;
  addPaymentSuccessToast: (
    artist: string,
    songTitle: string,
    transactionHash: string,
    options?: AddToastOptions,
  ) => number;
  addPaymentProcessingToast: (
    artist: string,
    songTitle: string,
    transactionHash: string,
    options?: AddToastOptions,
  ) => number;
  addPaymentErrorToast: (
    artist: string,
    songTitle: string,
    transactionHash?: string,
    errorMessage?: string,
    options?: AddToastOptions,
  ) => number;

  removeToast: (toastId: number) => void;
}

const ToastContext = React.createContext<ToastContextValue | null>(null);

export const ToastContextProvider: React.FC<PropsWithChildren | any> = ({
  children,
  isStudioPage,
}) => {
  const ids = useRef(0);
  const [toasts, setToasts] = useState<AnimatableToastData[]>([]);

  const addToast = useCallback(
    (toastData: ToastData & {timeout?: number | null}) => {
      const ref = React.createRef<HTMLDivElement>();

      // eslint-disable-next-line no-plusplus
      const id = ++ids.current;

      setToasts((currentToasts) => [
        {
          ...toastData,
          id,
          ref,
        },
        ...currentToasts,
      ]);

      return id;
    },
    [],
  );

  const addWarningToast = useCallback(
    (
      text?: string,
      {
        title = toastDefaults.warning.title,
        timeout = TOAST_TIMEOUT,
        ...rest
      }: AddToastOptions = {},
    ) => addToast({...rest, text, title, timeout, variant: 'warning'}),
    [addToast],
  );

  const addSuccessToast = useCallback(
    (
      text?: string,
      {
        title = toastDefaults.success.title,
        timeout = TOAST_TIMEOUT,
        ...rest
      }: AddToastOptions = {},
    ) => addToast({...rest, text, title, timeout, variant: 'success'}),
    [addToast],
  );

  const addInfoToast = useCallback(
    (
      text: string,
      {
        title = toastDefaults.info.title,
        timeout = TOAST_TIMEOUT,
        ...rest
      }: AddToastOptions = {},
    ) => addToast({...rest, text, title, timeout, variant: 'info'}),
    [addToast],
  );

  const addErrorToast = useCallback(
    (
      text?: string,
      {
        title = toastDefaults.error.title,
        timeout = TOAST_TIMEOUT,
        ...rest
      }: AddToastOptions = {},
    ) => addToast({...rest, text, title, timeout, variant: 'error'}),
    [addToast],
  );

  const addPaymentSuccessToast = useCallback(
    (
      artist: string,
      songTitle: string,
      transactionHash: string,
      {timeout = TOAST_TIMEOUT, ...rest}: AddToastOptions = {},
    ) =>
      addToast({
        ...rest,
        artist,
        songTitle,
        transactionHash,
        timeout,
        variant: 'paymentSuccess',
      }),
    [addToast],
  );

  const addPaymentProcessingToast = useCallback(
    (
      artist: string,
      songTitle: string,
      transactionHash: string,
      {timeout = TOAST_TIMEOUT, ...rest}: AddToastOptions = {},
    ) =>
      addToast({
        ...rest,
        artist,
        songTitle,
        transactionHash,
        timeout,
        variant: 'paymentProcessing',
      }),
    [addToast],
  );

  const addPaymentErrorToast = useCallback(
    (
      artist: string,
      songTitle: string,
      transactionHash?: string,
      errorMessage?: string,
      {timeout = TOAST_TIMEOUT, ...rest}: AddToastOptions = {},
    ) => {
      return addToast({
        ...rest,
        artist,
        songTitle,
        transactionHash,
        errorMessage,
        timeout,
        variant: 'paymentError',
      });
    },
    [addToast],
  );

  const removeToast = useCallback((toastId: number) => {
    setToasts((toastArray) => toastArray.filter(({id}) => id !== toastId));
  }, []);

  const handleAnimationEnd = useCallback(
    (toast: AnimatableToastData) =>
      toast.timeout
        ? setTimeout(() => removeToast(toast.id), toast.timeout)
        : null,
    [removeToast],
  );

  const transitions = useToastAnimation(toasts, {
    onAnimationEnd: handleAnimationEnd,
  });

  const value = useMemo(
    (): ToastContextValue => ({
      addWarningToast,
      addSuccessToast,
      addInfoToast,
      addErrorToast,
      addPaymentSuccessToast,
      addPaymentProcessingToast,
      addPaymentErrorToast,
      removeToast,
    }),
    [
      addErrorToast,
      addInfoToast,
      addSuccessToast,
      addWarningToast,
      addPaymentSuccessToast,
      addPaymentProcessingToast,
      addPaymentErrorToast,
      removeToast,
    ],
  );

  return (
    <ToastContext.Provider value={value}>
      {children}
      {!isStudioPage && (
        <Portal isMounted={!!toasts.length}>
          {toasts.length ? (
            <Box
              pt={['eleven', 'eighteen']}
              pr={['zero', 'eight']}
              alignItems="flex-end"
              position="absolute"
              top={[-1, 0]}
              right={0}
              width="100%"
              maxWidth="600px"
            >
              {transitions((style, {ref, ...item}) => (
                <AnimatedBox key={item.id} style={{...style, width: '100%'}}>
                  <Box ref={ref as any} pb="six">
                    <ToastV1
                      variant={item.variant}
                      title={item.title}
                      text={item.text}
                      artist={item.artist}
                      songTitle={item.songTitle}
                      transactionHash={item.transactionHash}
                      errorMessage={item.errorMessage}
                      onClose={() => {
                        removeToast(item.id);
                        item.onClose?.(item.id);
                      }}
                    />
                  </Box>
                </AnimatedBox>
              ))}
            </Box>
          ) : null}
        </Portal>
      )}
      {isStudioPage && (
        <Portal isMounted={!!toasts.length}>
          {toasts.length ? (
            <Box
              pt={['eleven', 'eighteen']}
              pr={['zero', 'eight']}
              alignItems="flex-end"
              position="absolute"
              top={[-1, 0]}
              right={0}
              width="100%"
              maxWidth="600px"
            >
              {transitions((style, {ref, ...item}) => (
                <AnimatedBox key={item.id} style={{...style, width: '100%'}}>
                  <Box ref={ref as any} pb="six">
                    <Toast
                      variant={item.variant}
                      title={item.title}
                      text={item.text}
                      artist={item.artist}
                      songTitle={item.songTitle}
                      transactionHash={item.transactionHash}
                      errorMessage={item.errorMessage}
                      onClose={() => {
                        removeToast(item.id);
                        item.onClose?.(item.id);
                      }}
                    />
                  </Box>
                </AnimatedBox>
              ))}
            </Box>
          ) : null}
        </Portal>
      )}
    </ToastContext.Provider>
  );
};

export const useToastContext = (): ToastContextValue => {
  const context = useContext(ToastContext);

  if (!context) {
    throw new Error('useToast must be used within an ToastContextProvider');
  }

  return context;
};
