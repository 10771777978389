import dynamic from 'next/dynamic';
import React, {
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';

import {WidgetProps} from './component/widget';
import {useAuthenticationContext} from '../authentication-context';

interface LifiWidgetValue {
  handleWidgetModal: () => void;
  isExchangeCompleted: boolean;
}

const LifiWidget = React.createContext<LifiWidgetValue | null>(null);

interface LifiWidgetProviderProps {
  children: ReactNode | React.ReactElement | React.ReactElement[];
  isWidgetVisible: boolean;
}

const LoadingIndicator = () => {
  return (
    <div
      style={{
        display: 'grid',
        placeItems: 'center',
      }}
    >
      <p>Loading...</p>
    </div>
  );
};

export const NextLiFiWidget = dynamic<WidgetProps>(
  () => import('./component/widget').then((module) => module.Widget) as any,
  {
    ssr: false,
    loading: () => <LoadingIndicator />,
  },
);

export const LifiWidgetProvider: React.FC<LifiWidgetProviderProps> = ({
  children,
  isWidgetVisible,
}) => {
  const [open, setOpen] = useState(0);
  const [isExchangeCompleted, setIsExchangeCompleted] = useState(false);

  const {updateFromLifi} = useAuthenticationContext();

  const handleWidgetModal = () => setOpen((prevState) => prevState + 1);

  const onExchangeComplete = async () => {
    await updateFromLifi();
    setOpen(0);
    setIsExchangeCompleted(true);
  };
  const value = useMemo(
    (): LifiWidgetValue => ({
      handleWidgetModal,
      isExchangeCompleted,
    }),
    [handleWidgetModal, isExchangeCompleted],
  );
  const widget = useCallback(() => {
    if (!isWidgetVisible) return;
    return (
      <NextLiFiWidget onExchangeComplete={onExchangeComplete} open={open} />
    );
  }, [open]);
  return (
    <LifiWidget.Provider value={value}>
      {children}
      {widget()}
    </LifiWidget.Provider>
  );
};

export const useLifiWidget = (): LifiWidgetValue => {
  const context = useContext(LifiWidget);

  if (!context) {
    throw new Error('useLifiWidget must be used within an LifiWidgetProvider');
  }

  return context;
};
