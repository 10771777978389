import React from 'react';
import Head from 'next/head';
import {
  Box,
  ContentContainerBox,
  HorizontalDivider,
  Typography,
} from '@ttx/design-system';
import {NextPageWithPageLayoutOptions} from '../components/page-layout/types';
import {BorderedSection} from '../components/bordered-section';

const MaintenancePage: NextPageWithPageLayoutOptions = () => {
  return (
    <Box
      width="100%"
      justifyContent="center"
      alignItems="center"
      backgroundColor="surfaceDark"
    >
      <Head>
        <title>Maintenance Page</title>
      </Head>
      <ContentContainerBox>
        <BorderedSection
          minHeight="60vh"
          paddingX="four"
          justifyContent="center"
          alignItems="center"
        >
          <Box
            w="100%"
            borderColor="outlines"
            borderWidth="one"
            borderStyle="solid"
            maxWidth="568px"
            marginY="thirty"
          >
            <Box
              paddingX="md"
              paddingY="s"
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography color="textDefault" textStyle="s">
                TokenTraxx is under maintenance
              </Typography>
            </Box>
            <HorizontalDivider />
            <Box paddingX="md" paddingY="md">
              <Typography textStyle="s" color="textDefault">
                TokenTraxx is currently down for planned maintenance. We&apos;ll
                be back in a couple of hours, thank you for your patience.
              </Typography>
            </Box>
          </Box>
        </BorderedSection>
      </ContentContainerBox>
    </Box>
  );
};

MaintenancePage.pageLayoutOptions = {
  showFooter: true,
  showRightSideDrawerButtonOverContent: false,
  showNavbar: false,
};

export default MaintenancePage;
