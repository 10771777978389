import React, {ReactNode} from 'react';
import {AnimatedBox, Box, Pressable} from '@ttx/design-system';

import {
  useRightSideBackgroundAnimation,
  useRightSideDrawerAnimation,
} from './useRightSideDrawerAnimation';
import {CONTENT_MAX_WIDTH} from '../../utils/consts';

export interface RightSideDrawerModalProps {
  isModalShowing: boolean;
  onClosePress: () => void;
  onDrawerCloseAnimationFinish: () => void;
  children: ReactNode;
}

const NAVBAR_HEIGHT = [44, 70];

export const RightSideDrawerModal = React.memo<RightSideDrawerModalProps>(
  ({isModalShowing, onClosePress, onDrawerCloseAnimationFinish, children}) => {
    const drawerAnimatedStyle = useRightSideDrawerAnimation({
      showDrawer: isModalShowing,
      onAnimationFinish: onDrawerCloseAnimationFinish,
    });

    const drawerBackgroundStyle = useRightSideBackgroundAnimation({
      showDrawer: isModalShowing,
    });

    return (
      <AnimatedBox
        position="absolute"
        top={NAVBAR_HEIGHT}
        left="-1px"
        bottom={0}
        right={0}
        alignItems="center"
        style={drawerBackgroundStyle}
      >
        <Box
          position="relative"
          alignItems="flex-end"
          width="100%"
          maxWidth={CONTENT_MAX_WIDTH}
          height="100%"
          overflow="hidden"
          borderColor="outlines"
          borderWidth="one"
          borderStyle="solid"
          borderTopWidth="zero"
          borderBottomWidth="zero"
        >
          <Pressable
            onPress={onClosePress}
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
            }}
          >
            <Box width="100%" height="100%" />
          </Pressable>
          <AnimatedBox
            style={drawerAnimatedStyle}
            tabIndex={0}
            aria-modal
            width="100%"
            maxWidth={['100%', 480]}
            height={[600, 480]}
            right={['0px', '34px']}
            paddingTop={['zero', 'four']}
          >
            {children}
          </AnimatedBox>
        </Box>
      </AnimatedBox>
    );
  },
);
