import mixpanel from 'mixpanel-browser';
import TagManager from 'react-gtm-module';

const ANALYTICS_CONSENT = 'tokentraxx-analytics-consent';

mixpanel.init(process.env.NEXT_PUBLIC_MIXPANEL_KEY || '', {
  debug: process.env.NODE_ENV === 'development',
  api_host: 'https://api-eu.mixpanel.com',
  opt_out_tracking_by_default: true,
});

// eslint-disable-next-line no-shadow
export enum ANALYTICS_STATE {
  UNKNOWN = 0,
  CONSENT = 1,
  DISALLOW = 2,
}

// const timeDiffInDays = (date1: number, date2: number) => {
//   const diffTime = Math.abs(date2 - date1);
//   const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
//   return diffDays;
// };

export const persistAnalytics: (state: ANALYTICS_STATE) => void = (
  state: ANALYTICS_STATE,
) => {
  localStorage.setItem(
    ANALYTICS_CONSENT,
    JSON.stringify({
      state,
      date: new Date().getTime(),
    }),
  );
};

export const runAnalytics: () => ANALYTICS_STATE = () => {
  const consent = localStorage.getItem(ANALYTICS_CONSENT);
  const hasOptedOut = mixpanel.has_opted_out_tracking();

  if (!consent) {
    try {
      if (!hasOptedOut) mixpanel.opt_out_tracking();
      // eslint-disable-next-line no-empty
    } catch (err) {}
    return ANALYTICS_STATE.UNKNOWN;
  }

  try {
    const data = JSON.parse(consent);

    // const nextDate = new Date();
    // if (timeDiffInDays(nextDate.getTime(), data.date) > 15) {
    //   persistAnalytics(ANALYTICS_STATE.UNKNOWN);
    //   return ANALYTICS_STATE.UNKNOWN;
    // }

    if (data.state === ANALYTICS_STATE.CONSENT) {
      try {
        TagManager.initialize({gtmId: 'GTM-PHKWFVW'});
        if (hasOptedOut) mixpanel.opt_in_tracking();
        // eslint-disable-next-line no-empty
      } catch (err) {}
      return ANALYTICS_STATE.CONSENT;
    }

    if (data.state === ANALYTICS_STATE.DISALLOW) {
      try {
        if (!hasOptedOut) mixpanel.opt_out_tracking();
        // eslint-disable-next-line no-empty
      } catch (err) {}
      return ANALYTICS_STATE.DISALLOW;
    }
  } catch (e) {
    persistAnalytics(ANALYTICS_STATE.UNKNOWN);

    return ANALYTICS_STATE.UNKNOWN;
  }
  return ANALYTICS_STATE.UNKNOWN;
};

export const mixpanelTrack: (
  eventName: string,
  props?: {[k: string]: any},
) => void = (eventName, props) => {
  try {
    mixpanel.track(eventName, {...props, env: process.env.NODE_ENV});
    // eslint-disable-next-line no-empty
  } catch (err) {}
};

export const mixpanelIdentifyUser: (
  walletAddress: string | null,
  walletProvider: string | null,
) => void = (walletAddress, walletProvider) => {
  if (!walletAddress || !walletProvider) return;
  try {
    mixpanel.identify(walletAddress);
    mixpanel.people.set_once({
      walletProvider,
    });
    // eslint-disable-next-line no-empty
  } catch (err) {}
};

export {default as mixpanel} from 'mixpanel-browser';
