import {createUtil, PropsFromUtils} from '../utils-utilities';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const background = <
  Media extends Record<any, string> = Record<any, string>,
>(
  media: Media,
) => ({
  background: createUtil(['background'], media),
  backgroundImage: createUtil(['backgroundImage'], media),
  backgroundPosition: createUtil(['backgroundPosition'], media),
});

export type BackgroundUtilsProps = PropsFromUtils<
  ReturnType<typeof background>
>;
