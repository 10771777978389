import React from 'react';
import {colors} from '../theme/tokens/colors';

export interface RadioOffIconProps {
  color?: keyof typeof colors;
  size?: number;
}

export const RadioOffIcon = React.memo<RadioOffIconProps>(
  ({color = 'outlines', size = 18}) => (
    <svg
      width={size}
      height={size}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.592 13.198C11.126 13.198 13.184 11.14 13.184 8.606C13.184 6.072 11.126 4 8.592 4C6.072 4 4 6.072 4 8.606C4 11.14 6.072 13.198 8.592 13.198ZM8.606 12.134C6.688 12.134 5.162 10.58 5.162 8.606C5.162 6.632 6.688 5.064 8.606 5.064C10.51 5.064 12.05 6.632 12.05 8.606C12.05 10.58 10.51 12.134 8.606 12.134Z"
        fill={colors[color] || color}
      />
    </svg>
  ),
);
