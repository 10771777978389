import React, {useContext, useMemo} from 'react';
import type {LinkProps} from 'next/link';

// export type LinkRendererConfig = {
//   // TODO: add accessibility props
//   href: string;
//   children: React.ReactNode;
//   query?: Record<string, string | string[]>;
//   prefetch?: boolean;
//   scroll?: boolean;
//   shallow?: boolean;
//   passHref?: boolean;
//   replace?: boolean;
//   target?: '_blank' | '_self';
// };

interface LinkRendererProps extends LinkProps {
  children: React.ReactNode;
  target?: '_blank' | '_self';
}

export type LinkRendererConfig = LinkRendererProps;

export type LinkRenderer = (config: LinkRendererConfig) => React.ReactNode;

interface LinkRendererContextValue {
  renderLink: LinkRenderer;
}

const LinkRendererContext =
  React.createContext<LinkRendererContextValue | null>(null);

interface LinkRendererProviderProps {
  renderLink: LinkRenderer;
  children: React.ReactNode;
}

export const LinkRendererProvider = React.memo(
  ({renderLink, children}: LinkRendererProviderProps) => (
    <LinkRendererContext.Provider value={{renderLink}}>
      {children}
    </LinkRendererContext.Provider>
  ),
);

export const useLinkRenderer = (): LinkRendererContextValue => {
  const context = useContext(LinkRendererContext);

  if (!context) throw new Error('Missing LinkRenderer Context');

  return context;
};

export type FrameworkLinkProps = LinkRendererConfig;

export const FrameworkLink = React.memo((config: FrameworkLinkProps) => {
  // by default legacy behaviour as `true` based on Next.js 13 version
  config = {
    ...config,
    legacyBehavior: true,
  };
  const {renderLink} = useLinkRenderer();

  const renderedLink = useMemo(() => renderLink(config), [config, renderLink]);

  return <>{renderedLink}</>;
});
