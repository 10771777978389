import {mergeObjectArrayToObject} from '../theme/stitches/variant-utilities';
import {theme} from '../theme/theme';

const headingSizes: (keyof typeof theme.fontSizes)[] = [
  'h1',
  'h2',
  'h3',
  'hero',
  'heroSmall',
];

type TextStyleVariants<
  FontSizeName = keyof typeof theme.fontSizes,
  BoldFontSizeName = `${keyof typeof theme.fontSizes}Bold`,
  ItalicFontSizeName = `${keyof typeof theme.fontSizes}Italic`,
  BoldItalicFontSizeName = `${keyof typeof theme.fontSizes}BoldItalic`,
> = {
  [variantName in (
    | FontSizeName
    | BoldFontSizeName
    | ItalicFontSizeName
    | BoldItalicFontSizeName
  ) &
    string]: {
    fontFamily: typeof theme.fonts[keyof typeof theme.fonts];
    fontSize: typeof theme.fontSizes[keyof typeof theme.fontSizes];
    fontWeight: keyof typeof theme.fontWeights;
    fontStyle: 'normal' | 'italic';
    letterSpacing: string;
  };
};

const withNamedFontSizeProperty = (key: string, fontSize: string) => {
  const themeValue: Record<string, string> | undefined = Array.isArray(
    theme[`${key}s` as keyof typeof theme],
  )
    ? undefined
    : theme[`${key}s` as keyof typeof theme];

  return themeValue ? {[key]: themeValue[fontSize] || '100%'} : undefined;
};

export const makeTextStyleVariants = (
  fontSizes: (keyof typeof theme.fontSizes)[],
): TextStyleVariants =>
  fontSizes
    .map((fontSize) => ({
      [fontSize]: {
        fontFamily: headingSizes.includes(fontSize)
          ? theme.fonts.abcHeading
          : theme.fonts.abc,
        fontSize: theme.fontSizes[fontSize],
        fontWeight: theme.fontWeights.regular,
        fontStyle: 'normal',
        lineHeight: '100%',
        ...withNamedFontSizeProperty('letterSpacing', fontSize),
        ...withNamedFontSizeProperty('lineHeight', fontSize),
      },
      [`${fontSize}Bold`]: {
        fontFamily: headingSizes.includes(fontSize)
          ? theme.fonts.abcHeading
          : theme.fonts.abc,
        fontSize: theme.fontSizes[fontSize],
        fontWeight: theme.fontWeights.bold,
        fontStyle: 'normal',
        lineHeight: '100%',
        ...withNamedFontSizeProperty('letterSpacing', fontSize),
        ...withNamedFontSizeProperty('lineHeight', fontSize),
      },
      // currently no italic or bold italic implemented for abc diatype font
      [`${fontSize}Italic`]: {
        fontFamily: theme.fonts.altone,
        fontSize: theme.fontSizes[fontSize],
        fontWeight: theme.fontWeights.regular,
        fontStyle: 'italic',
        lineHeight: '100%',
        ...withNamedFontSizeProperty('letterSpacing', fontSize),
        ...withNamedFontSizeProperty('lineHeight', fontSize),
      },
      [`${fontSize}BoldItalic`]: {
        fontFamily: theme.fonts.altone,
        fontSize: theme.fontSizes[fontSize],
        fontWeight: theme.fontWeights.bold,
        fontStyle: 'italic',
        lineHeight: '100%',
        ...withNamedFontSizeProperty('letterSpacing', fontSize),
        ...withNamedFontSizeProperty('lineHeight', fontSize),
      },
    }))
    .reduce(mergeObjectArrayToObject) as any;
