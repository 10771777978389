/* eslint-disable import/no-cycle */
import React from 'react';
import {VisibilityBox} from '../box/visibility-box';
import {DesktopNavbar, DesktopNavbarProps} from './navbar.desktop';
import {MobileNavbar, MobileNavbarProps} from './navbar.mobile';

type NavbarProps = DesktopNavbarProps & MobileNavbarProps;

export const Navbar: React.FC<NavbarProps> = (props) => (
  <>
    <VisibilityBox show={[true, false]}>
      <MobileNavbar {...props} />
    </VisibilityBox>
    <VisibilityBox show={[false, true]}>
      <DesktopNavbar {...props} />
    </VisibilityBox>
  </>
);
