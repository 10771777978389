import {createStitches} from '@stitches/react';
import {theme} from '../theme';
import {convertBreakpointsArrayToMediaObject} from './variant-utilities';
import {layout} from './utils/layout';
import {flexbox} from './utils/flexbox';
import {opacity} from './utils/opacity';
import {position} from './utils/position';
import {grid} from './utils/grid';
import {background} from './utils/background';

export const augmentedTheme = {
  ...theme,
  breakpoints: {},
};

export const media = convertBreakpointsArrayToMediaObject([
  ...theme.breakpoints,
]);

export const utils = {
  ...layout(augmentedTheme, media),
  ...flexbox(media),
  ...opacity(media),
  ...position(augmentedTheme, media),
  ...background(media),

  // Exclusively for `<GridBox />` and its derivatives.
  ...grid(media),
};

export const {
  styled,
  css,
  globalCss,
  keyframes,
  getCssText,
  theme: stitchesTheme,
  createTheme,
  config,
} = createStitches({
  theme: augmentedTheme,
  media,
  prefix: 'ttx',
  utils,
});
