import React from 'react';
import Head from 'next/head';

export const WalletConnectStyle = (): JSX.Element => (
  <Head>
    <style
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{
        __html: `
        .walletconnect-modal__base {
            max-width: 300px !important;
        }`,
      }}
    />
  </Head>
);
