import React from 'react';
import {Box} from '@ttx/design-system';

export const BorderedSection: React.FC<
  // CSS prop is not playing nice with TS, so ignoring as we dont need it.
  Omit<React.ComponentProps<typeof Box>, 'css'>
> = (props) => (
  <Box
    width="100%"
    paddingX={['zero', 'lg']}
    paddingY={['twentySix']}
    borderBottomStyle="solid"
    borderBottomWidth="one"
    borderBottomColor="outlines"
    alignItems="center"
    {...props}
  />
);
